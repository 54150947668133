import { HistoryFlagType, IMenu, IMenuItemInfo, IOrderDetail } from 'apis/types';

/**
 * Checks if the given menu items have lunch_flg and setmenu_flg flags in the order history.
 *
 * @param menuInfo - An object containing information about the available menu items.
 * @param orders - An array of order details containing the history of placed orders.
 *
 * @returns A HistoryFlagType object containing the lunch_flg and setmenu_flg flags.
 */
export function checkHistoryFlag(menuInfo: IMenu, orders: IOrderDetail[]): HistoryFlagType {
  const returnFlag: HistoryFlagType = {
    setmenu_flg: '0',
    lunch_flg: '0',
  };

  const updateFlags = (itemInfo: IMenuItemInfo) => {
    if (itemInfo.lunch_flg === '1') returnFlag.lunch_flg = '1';
    if (itemInfo.setmenu_flg === '1') returnFlag.setmenu_flg = '1';
  };

  const hasBothFlagsSet = () => returnFlag.lunch_flg === '1' && returnFlag.setmenu_flg === '1';

  orders.some((order) => {
    for (const subOrder of order.OrderDetailMenuInfo) {
      const itemInfo = menuInfo[subOrder.MenuCode];

      if (itemInfo) {
        updateFlags(itemInfo);
        if (hasBothFlagsSet()) {
          return true;
        }
      }
    }

    return false;
  });

  return returnFlag;
}
