import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { CartStateData, CartStateValue, PortionState } from 'models';
import { getOrderCost } from 'utils';

interface CartState {
  data: CartStateData;
}

const initialState: CartState = {
  data: {},
};

const getKeyValue = (dict: { [menuBookIDAndMenuItemCode: string]: CartStateValue }): [string, CartStateValue] => {
  const entry = Object.entries(dict)[0];
  const menuBookIDAndMenuItemCode = entry[0];
  const value = { ...entry[1] };
  return [menuBookIDAndMenuItemCode, value];
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    initDataCart: (state, action: PayloadAction<CartStateData>) => {
      state.data = action.payload;
    },
    resetCart: (state) => {
      state.data = {};
    },
    addItemToCart: (state, action: PayloadAction<CartStateData>) => {
      const [menuBookIDAndMenuItemCode, value] = getKeyValue(action.payload);

      let newDishValue;

      if (state.data[menuBookIDAndMenuItemCode]) {
        newDishValue = {
          ...state.data[menuBookIDAndMenuItemCode],
          state: [...state.data[menuBookIDAndMenuItemCode].state, ...value.state],
        };
      } else {
        newDishValue = value;
      }

      const newData = {
        ...state.data,
        [menuBookIDAndMenuItemCode]: newDishValue,
      };

      const filteredEmpty: CartStateData = {};

      for (const key in newData) {
        if (newData[key].state.length > 0) {
          filteredEmpty[key] = newData[key];
        }
      }

      state.data = filteredEmpty;
    },
    removeItemFromCart: (state, action: PayloadAction<string>) => {
      const removeCode = action.payload;

      const newCartState = Object.keys(state.data)
        .filter((key) => {
          const [, dishCode] = JSON.parse(key);
          return dishCode !== removeCode;
        })
        .reduce((result, currentKey) => {
          result[currentKey] = state.data[currentKey];
          return result;
        }, {} as CartStateData);

      state.data = newCartState;
    },
    replaceItemInCart: (state, action: PayloadAction<CartStateData>) => {
      const addedState = action.payload;

      const newCartState = { ...state.data, ...addedState };

      const filteredEmpty: CartStateData = {};

      for (const key in newCartState) {
        if (newCartState[key].state.length > 0) {
          filteredEmpty[key] = newCartState[key];
        }
      }

      state.data = filteredEmpty;
    },
    increase(state, action: PayloadAction<{ dishKey: string; dishState: PortionState }>) {
      const { dishKey, dishState } = action.payload;

      const currentDish = state.data[dishKey];

      if (!currentDish) {
        return;
      }

      const currentDishState = currentDish.state;

      const newDishState = {
        ...currentDish,
        state: [...currentDishState, dishState],
      };

      state.data = {
        ...state.data,
        [dishKey]: newDishState,
      };

      const filteredEmpty: CartStateData = {};

      for (const key in state.data) {
        if (state.data[key].state.length > 0) {
          filteredEmpty[key] = state.data[key];
        }
      }

      state.data = filteredEmpty;
    },
    decrease(state, action: PayloadAction<{ dishKey: string; dishState: PortionState }>) {
      const { dishKey, dishState } = action.payload;

      const currentDish = state.data[dishKey];

      if (!currentDish) {
        return;
      }
      const currentDishState = currentDish.state;

      const foundIndex = currentDishState.findIndex((portion) => JSON.stringify(portion) === JSON.stringify(dishState));

      if (foundIndex >= 0) {
        currentDishState.splice(foundIndex, 1);
      }

      const newDishState = {
        ...currentDish,
        state: currentDishState,
      };

      state.data = {
        ...state.data,
        [dishKey]: newDishState,
      };

      const filteredEmpty: CartStateData = {};

      for (const key in state.data) {
        if (state.data[key].state.length > 0) {
          filteredEmpty[key] = state.data[key];
        }
      }

      state.data = filteredEmpty;
    },
  },
});

export const cartActions = cartSlice.actions;
export const selectCartState = (state: RootState) => state.cart.data;

export const selectTotalOrder = createSelector(selectCartState, (cartState: CartStateData) => {
  let totalOrderItem = 0;
  for (const key in cartState) {
    totalOrderItem += cartState[key].state.length;
  }
  return totalOrderItem;
});

export const selectTotalAmount = createSelector(selectCartState, (cartState: CartStateData) => {
  let totalAmount = 0;

  Object.values(cartState).forEach((dishValue) => {
    dishValue.state.forEach((orderState) => {
      const dishInfo = dishValue.menuItemInfo;
      const orderCost = getOrderCost(orderState, dishInfo);
      totalAmount += orderCost;
    });
  });

  return totalAmount;
});

export const cartReducer = cartSlice.reducer;

export const selectedCartState = (state: RootState) => state.cart.data;
