import { Payload } from 'apis/types';

/**
 * Checks if the provided object is not empty.
 *
 * @param object - The object to be checked.
 * @returns A boolean value indicating whether the object is not empty.
 */
export const checkObjectNotEmpty = (object: Payload) => {
  return Object.keys(object).length > 0;
};
