import { useAppDispatch } from 'app/hooks';
import React, { useRef } from 'react';
import { menuInfoActions } from 'store/slices/menuInfoSlice';
import { stripHtml } from 'utils';
import { SESSION_CURRENT_GENRE_CODE, SessionStorage } from 'utils/sessionStorage';
import './scss/GenreButton.scss';

export interface GenreButtonProps {
  menubookId: string;
  genreId: string;
  genreName: string;
}

function GenreButton({ menubookId, genreId, genreName }: GenreButtonProps) {
  const appDispatch = useAppDispatch();
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    // category is not available, prevent click
    if (buttonRef?.current?.parentElement?.classList.contains('disabled')) {
      return;
    }

    // save categoryCode to SessionStorage and main store
    appDispatch(menuInfoActions.setCurrentGenreId({ selectedMenubookId: menubookId, genreId: genreId }));
    SessionStorage.setItem(SESSION_CURRENT_GENRE_CODE, genreId);

    // log({
    //   op_type: LOG_OP_TYPE_CATEGORY_SWITCHING,
    //   op_detail: {
    //     mode: menubookId,
    //     category: genreId,
    //   },
    // });
  };

  return (
    <div id={`genre-btn-${genreId}`} className={`genre-button`} onClick={handleClick} ref={buttonRef}>
      {stripHtml(genreName)}
    </div>
  );
}

export default React.memo(GenreButton);
