import { IServiceInfo, OrderAPIType } from 'apis/types';

/**
 * Checks the required items for a specific service based on the provided service information and order data.
 *
 * @param serviceInfo - An object containing the service menu codes.
 * @param dataCartOrder - An object containing the order information, including main and sub menu codes.
 * @returns An array of required items (menu codes) that are included in the service menu.
 */
export const checkServiceRequiredItems = (serviceInfo: IServiceInfo, dataCartOrder: OrderAPIType): string[] => {
  const serviceMenu = serviceInfo.service_menu;
  const requiredItems: string[] = [];

  dataCartOrder.order_info.forEach((order) => {
    // check main menu codes
    if (serviceMenu.includes(order.MenuCode)) {
      requiredItems.push(order.MenuCode);
    }

    // Check sub menu codes
    if (order.MenuInfo) {
      order.MenuInfo.forEach((subMenu) => {
        if (serviceMenu.includes(subMenu.MenuCode)) {
          requiredItems.push(subMenu.MenuCode);
        }
      });
    }
  });

  return requiredItems;
};
