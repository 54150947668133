interface SelectedOptionProps {
  optionName: string;
  optionQuantity: number;
  isChild?: boolean;
}

const SelectedOption = ({ optionName, optionQuantity, isChild = false }: SelectedOptionProps) => {
  return (
    <li className={`selected-option-item ${isChild ? 'selected-child-item' : ''}`}>
      {optionName} (<strong>{optionQuantity}</strong>)
    </li>
  );
};

export default SelectedOption;
