import { useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import { DishDispatchContext } from 'components/DishModal';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import QuantityButtonGroup from 'components/QuantityButtonGroup';
import { PortionState } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { selectCartState } from 'store/slices/cartSlice';
import { selectCustomerQuantity } from 'store/slices/customerQuantitySlice';
import { selectCouponInfo, selectMenu, selectOrderHistoryData } from 'store/slices/mainSlice';
import { selectMenuBook } from 'store/slices/menuInfoSlice';
import { checkIsExceedAllowQuantityOrder, countCouponUsed, getDishInfo, isDishInCouponListFullCode } from 'utils';
import { MAX_COUPON_PER_PERSON_PER_ITEM } from '../../constants';

interface PortionQuantitySelectorProps {
  menubookId: string;
  dishCode: string;
  addedState: PortionState;
  initialQuantity: number;
  quantity: number;
  type: 'add' | 'replace';
}

const PortionQuantitySelector = ({
  menubookId,
  dishCode,
  addedState,
  initialQuantity,
  quantity,
  type,
}: PortionQuantitySelectorProps) => {
  const { t } = useTranslation();
  const { showGlobalModal } = useGlobalModal();

  const menuList = useAppSelector(selectMenu);
  const orderHistoryData = useAppSelector(selectOrderHistoryData);
  const dishInfo = getDishInfo(menuList, dishCode);
  const dishDispatch = useContext(DishDispatchContext);
  const cartState = useAppSelector(selectCartState);
  const customerQuantity = useAppSelector(selectCustomerQuantity);
  const menubookList = useAppSelector(selectMenuBook);
  const couponInfoList = useAppSelector(selectCouponInfo);

  const showAlertModal = (message: string) => {
    playSound('error');
    showGlobalModal(() => <AlertModal message={message} />);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      dishDispatch({
        type: 'decrease',
      });
    }
  };

  const handleIncrease = () => {
    // todo: check if exceed allow quantity order
    const isCheckExceedAllowQuantityOrder = checkIsExceedAllowQuantityOrder(
      menubookId,
      menubookList,
      cartState,
      quantity + 1,
      customerQuantity
    );

    if (isCheckExceedAllowQuantityOrder) {
      showAlertModal(t('MenuItemDetail.exceed_quantity_warning'));

      return;
    }

    // todo: check exceed coupon
    if (isDishInCouponListFullCode(dishInfo, couponInfoList)) {
      const maxCouponQuantity = customerQuantity * MAX_COUPON_PER_PERSON_PER_ITEM;
      const couponUsed = countCouponUsed(menuList, couponInfoList, cartState, orderHistoryData);

      if (type === 'add') {
        if (couponUsed + quantity + 1 > maxCouponQuantity) {
          showAlertModal(t('App.max_coupon_num_exceeded'));

          return;
        }
      } else {
        if (couponUsed - initialQuantity + quantity + 1 > maxCouponQuantity) {
          showAlertModal(t('App.max_coupon_num_exceeded'));

          return;
        }
      }
    }

    dishDispatch({
      type: 'increase',
      payload: addedState,
    });
  };

  return (
    <QuantityButtonGroup
      onDecrease={handleDecrease}
      onIncrease={handleIncrease}
      quantity={quantity}
      min={1}
      disableMin
    />
  );
};

export default PortionQuantitySelector;
