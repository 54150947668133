export const translationJP = {
  App: {
    language: '言語',
    max_coupon_num_exceeded: 'クーポン利用枚数の上限を超えました。',
    max_coupon_exceed_limit: 'クーポンの使用枚数が上限を超ているためご注文いただけません。',
    agree: 'はい',
    no: 'いいえ',
    close_modal: '閉じる',
    lunch_menu_unavailable: 'ランチメニューの提供時間が終了しました。',
    invalid_item_exist: '無効な商品が含まれています',
    app_not_launch: 'オーダーアプリを起動できませんでした。',
    confirm_quit_app: 'スマホオーダーを離れますが<br/>よろしいですか？',
    confirm_delete_item: 'こちらの商品を<br/>削除してよろしいでしょうか？',
    warning_age_limit: '20歳未満、妊婦、お車等を運転される方へのアルコールのご提供はお断りいたします。',
    genre_freq_ordered_menu: 'よく注文するメニュー',
    genre_search_menu: '検索結果',
    max_order_limit_kbn_exceeded: '注文可能数の上限を超えました',
    order_limit_kbn_warning: 'こちらの商品はお１人様１品の注文となります',
    no_select_menu: '	未選択',
  },
  Top: {
    see_all_product_at_this_fair: 'このフェアのすべての商品を見る',
  },
  Header: {
    freq_ordered_menu: 'よく注文するメニュー',
    search_menu: 'メニュー検索',
    order_history: '注文履歴',
    use_counpon: 'クーポン',
    search: '検索',
  },
  MenuList: {
    call_staff: 'ご注文の際は、店員をお呼びください',
    lunch_dish_needed: 'ランチメニュー注文で、ご利用可能となります',
    set_dish_needed: '定食をご注文で、ご利用可能となります',
    lunch_time_only: 'ランチタイムのみご注文いただけます',
    lunch_time_excluded: 'ランチタイムは、ランチ価格のメニューをご注文ください',
    search_no_result: '検索結果はありません<br />キーワードをご確認ください',
    out_of_stock: '品切れ中です',
    end_of_time_frame: '{{timeframeName}}が終了しました。',
    daily_menu_chg_flg: '本日の日替わりメニューは、<br/>別曜日のメニューに変更にな<br/>っております',
  },
  MenuItemDetail: {
    add_to_cart: '注文リストに追加',
    delete_portion: 'を取り消す',
    portion_name: 'お好みメニュー選択',
    please_select_an_item: '{{quantity}}品選択してください',
    pay_menu_in_you_can_eat_time_confirm_message:
      '食べ放題メニュー以外のメニューが含まれるため、追加料金が発生します。<br/>よろしいですか？',
    exceed_quantity_warning:
      '一度に注文できる注文数の上限を超えました。<br />カート内の商品を注文後に再度選択してください。',
    select_more_items_message: '{{0}}を{{1}}お選びください。<br />',
    kind: '品',
    not_met_dispcond_5: '{{groupName}}をご注文で<br/>ご利用いただけます',
  },
  OrderHistory: {
    title: '注文履歴',
    load_fail: '注文履歴を取得時にエラーが発生しました',
    order_number: '注文数',
    order_name: '商品名',
    order_type: 'オーダー種別',
    quantity: '数量',
    cost: '値段',
    status: '状況',
    re_order: '再注文',
    total_price: '合計金額',
    cancel: '取り消し',
    confirm_cancel: '{name}をキャンセルしますか？',
    cancel_success: '取り消し済',
    cancel_fail: '3分が経過すると、注文はキャンセルできなくなります',
    currency: '￥',
    tax_included: '税込',
    cancel_within_3_m: 'この注文は3分以内にキャンセルできます',
    done_preparing: '調理完了',
    served: '提供済み',
    cooking: '調理中',
    treasurer: '会計する',
    account_message:
      'ご利用ありがとうございました。<br /><br />伝票をお持ちいただき、<br />レジにてお会計をお願いいたします。',
  },
  Cart: {
    title: 'カート',
    order: '注文する',
    go_back: 'メニューに戻る',
    turn_back: '戻る',
    quantity: '数量',
    order_success: '注文を承りました。',
    order_fail: '注文の送信に失敗しました。<br />しばらくしてから、再度お試しください。',
    out_of_stock: '下記の商品が、品切れになりました。<br />',
    unordered_data: '未送信の注文が{0}品有ります',
    order_menu: 'カートのメニューを注文する',
    exceed_coupon_limit: 'クーポン利用枚数の上限を超えました。<br />注文内容の変更をお願いします。',
  },
  AllYouCanEat: {
    minutes: '分',
    time_left: '残り',
    all_you_can_eat: '食べ放題',
    all_you_can_drink: '飲み放題',
    message_near_end_time: '{BBB}ラストオーダーまで<br />あと{CCC}分です。',
    message_one_of_them_over_time:
      '{AAA}の終了時間となりました。<br />{BBB}はラストオーダーまで<br />あと{CCC}分です。',
    message_over_time:
      '{AAA}オーダー終了時間となりました。またのご利用をお待ちしております。<br />また、お得な料金で延長を受け付けております。ご利用の際はお近くの従業員にお声かけください。',
    menu_not_available: 'ラストオーダー終了しました',
    warning_paid_menu: '【有料】別途追加料金がかかります。<br />よろしいですか？',
  },
  ThankPage: {
    thank_you_very_much: 'ありがとうございました。',
    we_look_forward_to_seeing_you_again: 'またのご来店をお待ちしております。',
  },
  MessageList: {
    credential_error: '処理に失敗しました。<br />(コード：{error_code})',
  },
  CouponForm: {
    headtitle: 'クーポン番号を入力してください',
    title: '使用可能枚数 ： 使用上限枚数',
    coupon_number: 'クーポン番号',
    description1: 'クーポン券のご利用は、１回のご来店でお１人様２枚までとなります。',
    description2: '各種割引券との併用はできません。',
    description3: '番号が記載されていないクーポンは従業員にご提示ください。',
    cancel: 'キャンセル',
    degree: '決定',
    warn_not_found_coupon_menu: '該当のクーポンが存在しません。<br />クーポンコードを確認の上、再度入力してください。',
    placeholder: 'クーポン番号を入力してください',
  },
  Pita: {
    minutes: '分',
    time_left: '残り',
    all_you_can_eat: 'ピタ放題',
    all_you_can_drink: '飲み放題',
    message_near_end_time: '{BBB}ラストオーダーまで<br />あと{CCC}分です。',
    message_one_of_them_over_time:
      '{AAA}の終了時間となりました。<br />{BBB}はラストオーダーまで<br />あと{CCC}分です。',
    message_over_time:
      '{AAA}オーダー終了時間となりました。またのご利用をお待ちしております。<br />また、お得な料金で延長を受け付けております。ご利用の際はお近くの従業員にお声かけください。',
    menu_not_available: 'ラストオーダー終了しました',
  },
};
