import { IMenuItemInfo } from 'apis/types';
import { PortionState } from 'models';
import { getOrderCost } from 'utils';

/**
 * Calculates the total cost of a dish based on the provided dish state and dish information.
 *
 * @param dishState - An array of PortionState objects representing the state of the dish order.
 * @param dishInfo - An IMenuItemInfo object containing information about the dish, including its base price and option information.
 *
 * @returns The total cost of the dish based on the provided dish state and dish information.
 */
export const getDishCost = (dishState: PortionState[], dishInfo: IMenuItemInfo): number => {
  let totalCost = 0;

  dishState.forEach((orderState) => {
    totalCost += getOrderCost(orderState, dishInfo);
  });

  return totalCost;
};
