import { useAppDispatch, useAppSelector } from 'app/hooks';
import DishPrice from 'components/DishPrice';
import PageHeader from 'components/PageHeader';
import HistoryMenuItem from 'pages/OrderHistoryPage/components/HistoryMenuList';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { mainActions, selectOrderHistoryData } from 'store/slices/mainSlice';
import './OrderHistory.scss';

const OrderHistoryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMounted = useRef<boolean>(true);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const totalAmount = Number(orderHistory.OrderHeader.TotalAmount) || 0;

  useEffect(() => {
    if (isMounted.current) {
      dispatch(mainActions.fetchOrderHistoryData());
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const body = document.body;

    body.classList.add('full-height');

    return () => {
      body.removeAttribute('style');
      body.classList.remove('full-height');
    };
  }, []);

  const handleGoBack = () => {
    playSound('page');
    navigate(-1);
  };

  return (
    <div id="cart">
      <PageHeader handleGoBack={handleGoBack} />
      <div id="order-history" className="cart-body">
        <HistoryMenuItem />
      </div>
      <div className="cart-footer">
        <div className="row g-0 cart-price">
          <div className="col-3 total-price-text">{t('OrderHistory.total_price')}</div>
          <div className="col total-price">
            <DishPrice price={totalAmount} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryPage;
