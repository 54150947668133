import { store } from 'app/store';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalModalProvider } from 'components/GlobalModal';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'translation/i18n';
import App from './App';
import './theme/styles/index.scss';

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <GlobalModalProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GlobalModalProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
