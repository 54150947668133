import { AllYouCanEatType, HistoryFlagType, IMenu, ITimeFrame, MenuBookGroupInfoType, ThInfoType } from 'apis/types';
import { CartStateData } from 'models';
import { attrLang, getErrorMessage } from 'utils';

export const checkInValidItemInCart = (
  cartState: CartStateData,
  menuList: IMenu,
  thMenuInfo: ThInfoType,
  timeFrame: ITimeFrame,
  historyFlag: HistoryFlagType,
  allYouCanEatState: AllYouCanEatType,
  currentMenubook: MenuBookGroupInfoType,
  outOfStockItems: string[],
  haveAdult: number,
  timeFrameLunch: string[]
) => {
  let invalidItemName = '';
  let errorMessage = '';

  Object.keys(cartState).some((dishKey) => {
    const [, dishCode] = JSON.parse(dishKey);
    const dishInfo = menuList[dishCode];
    const outOfStocked = outOfStockItems.includes(dishInfo.poscd);

    const message = getErrorMessage(
      thMenuInfo.code,
      timeFrameLunch.includes(timeFrame.time_frame_id),
      historyFlag,
      allYouCanEatState,
      dishInfo,
      currentMenubook,
      outOfStocked,
      haveAdult
    );

    if (message) {
      invalidItemName = attrLang(dishInfo, 'lang');
      errorMessage = message;
    }

    return !!message;
  });

  return { invalidItemName, errorMessage };
};
