import { SessionStorage, SESSION_CUSTOMER_ID_KEY } from 'utils';

/**
 * Retrieves the customer ID from the session storage.
 *
 * @returns The customer ID as a number. If no customer ID is found, returns -1.
 */
export const getCustomerId = (): number => {
  const cid = SessionStorage.getItem(SESSION_CUSTOMER_ID_KEY) || '-1';

  return parseInt(cid);
};
