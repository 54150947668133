import { getCustomerQuantity } from 'apis/Api';
import { ICustomerQuantity } from 'apis/types';
import { handleHttpError, handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { customerQuantityActions } from 'store/slices/customerQuantitySlice';
import { RES_CODE_SUCCESS } from '../../constants';

function* fetchCustomerQuantityData() {
  try {
    const response: ICustomerQuantity = yield call(getCustomerQuantity);
    if (response.code !== RES_CODE_SUCCESS) {
      yield handleResponseError(response);
    } else {
      yield put(customerQuantityActions.fetchCustomerQuantityDataSuccess(response));
    }
  } catch (error) {
    yield put(customerQuantityActions.fetchCustomerQuantityDataFailed());
    yield handleHttpError(error as AxiosError);
  }
}

export default function* customerQuantitySaga() {
  yield takeLatest(customerQuantityActions.fetchCustomerQuantityData.type, fetchCustomerQuantityData);
}
