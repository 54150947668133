import './ErrorPage.scss';

interface ErrorPageProps {
  message: string;
}

const ErrorPage = ({ message }: ErrorPageProps) => {
  return (
    <div className="error-page">
      <strong dangerouslySetInnerHTML={{ __html: message }}></strong>
    </div>
  );
};

export default ErrorPage;
