import { useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { DishModalContext } from 'context/DishModalContext';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { selectCartState } from 'store/slices/cartSlice';
import { selectCustomerQuantity } from 'store/slices/customerQuantitySlice';
import { selectCouponInfo, selectMenu, selectOrderHistoryData } from 'store/slices/mainSlice';
import { AraTicketIcon } from 'theme/icons';
import { countCouponUsed } from 'utils';
import { TAP_ANIMATION_TIME } from '../../../constants';

const CouponIcon = () => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { showCouponFormModal } = useContext(DishModalContext);
  const { showGlobalModal } = useGlobalModal();

  const menuDataInfo = useAppSelector(selectMenu);
  const cartState = useAppSelector(selectCartState);
  const listCoupon = useAppSelector(selectCouponInfo);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const customerQuantity = useAppSelector(selectCustomerQuantity);

  const handleClick = () => {
    // check button has disabled status, prevent click
    if (buttonRef.current) {
      if (buttonRef.current.disabled) {
        return;
      }
    }

    // change status of button to disabled
    if (buttonRef.current) buttonRef.current.disabled = true;

    setTimeout(() => {
      // check exceed maximum number of coupon
      const totalCouponUsed = countCouponUsed(menuDataInfo, listCoupon, cartState, orderHistory);
      const maxCouponQuantity = customerQuantity * 2;
      if (totalCouponUsed >= maxCouponQuantity) {
        playSound('error'); // Play an error sound
        // Show a global modal with a warning message
        showGlobalModal(() => (
          <AlertModal
            message={t('App.max_coupon_exceed_limit')}
            onClose={() => {
              if (buttonRef.current) buttonRef.current.disabled = false; // Enable the confirm button
            }}
          />
        ));
      } else {
        playSound('page');
        showCouponFormModal && showCouponFormModal();
        if (buttonRef.current && buttonRef.current.disabled) buttonRef.current.disabled = false;
      }
    }, TAP_ANIMATION_TIME / 2);
  };

  return (
    <button
      className="coupon-button ara-btn-link align-items-center d-inline-flex"
      ref={buttonRef}
      onClick={handleClick}
    >
      <span className="coupon-button__text">{t('Header.use_counpon')}</span>
      <AraTicketIcon />
    </button>
  );
};

export default CouponIcon;
