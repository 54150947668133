import { IMenu, ISubMenuGroup } from 'apis/types';
import { DishMenuGroupDetail, DishMenuGroupDetailValue, PortionState, PortionStateItem } from 'models';
import { getDishInfo } from 'utils/getDishInfo';
import { SubmenuGroupInfoType } from '../apis/types';

/**
 * Initializes the state for a dish based on the provided item information, menu list, and sub-menu group.
 *
 * @param menuList - The menu list containing dish information.
 * @param subMenuGroupData - The sub-menu group containing basic information and sub-menu details.
 * @param submenuGroupInfo - The sub-menu group information.
 * @param addDefaultValue - A boolean flag indicating whether to add default values to the state. Defaults to `true`.
 * @returns An array of initial state objects, where each object represents a portion state for a specific sub-menu group.
 */
export const initDishState = (
  menuList: IMenu,
  subMenuGroupData: ISubMenuGroup,
  submenuGroupInfo: SubmenuGroupInfoType[],
  addDefaultValue = true
): PortionState[] => {
  const initState: PortionState = {};
  const subMenuGroupCodesValid = submenuGroupInfo
    .filter((group) => group.submenu.length > 0)
    .map((group) => group.submenugroup_cd);

  subMenuGroupCodesValid.forEach((subMenuGroupCode) => {
    const subMenuGroupItem = subMenuGroupData[subMenuGroupCode];
    if (subMenuGroupItem) {
      const noselectFlg = subMenuGroupItem.noselectflg;
      // const kousenum = Number(subMenuGroupItem.kousenum) || 1;
      const defaultSettingFlg = subMenuGroupItem.default_setting_flg;

      const submenuGroup = submenuGroupInfo?.find((group) => group.submenugroup_cd === subMenuGroupCode);
      const submenuValid = submenuGroup?.submenu;
      const submenuData = subMenuGroupItem?.submenu;
      const submenuCurrentPoscodes = submenuValid?.map((item) => item.poscd);
      const submenu = submenuData.filter((item) => submenuCurrentPoscodes?.includes(item.poscode));
      const minDefaultPriority = Math.min(...submenu.map((item) => Number(item.default_priority)));

      const selectedDishCode: string[] = [];
      const detailList: DishMenuGroupDetail = {};

      submenu.forEach((item) => {
        const poscode = item.poscode;
        const submenuDataDetail = submenuValid?.find((item) => item.poscd === poscode);
        const optionInfo = getDishInfo(menuList, poscode);
        const defaultPriority = Number(item.default_priority);
        const isChecked = addDefaultValue
          ? noselectFlg === '1' || (defaultSettingFlg === '1' && defaultPriority === minDefaultPriority)
          : false;
        const detailItem: DishMenuGroupDetailValue = {
          optionInfo: optionInfo,
          checked: isChecked,
          quantity: 1,
        };

        detailList[poscode] = detailItem;

        if (isChecked) {
          selectedDishCode.push(poscode);
        }
        if (submenuDataDetail && submenuDataDetail.submenugroup && submenuDataDetail.submenugroup.length > 0) {
          const listChildOptionGroups: PortionState = {};

          const childSubmenuGroupInfo = submenuDataDetail.submenugroup;
          const childSubmenuGroupCodesValid = childSubmenuGroupInfo
            .filter((group) => group.submenu.length > 0)
            .map((group) => group.submenugroup_cd);

          childSubmenuGroupCodesValid.forEach((childSubmenuGroupCode) => {
            const childSubmenuGroupItem = subMenuGroupData[childSubmenuGroupCode];

            if (childSubmenuGroupItem) {
              const childNoSelectFlg = childSubmenuGroupItem.noselectflg;
              // const childKousenum = Number(childSubmenuGroupItem.kousenum) || 1;
              const childDefaultSettingFlg = childSubmenuGroupItem.default_setting_flg;

              const childSubmenuGroup = childSubmenuGroupInfo?.find(
                (group) => group.submenugroup_cd === childSubmenuGroupCode
              );

              const childSubmenuValid = childSubmenuGroup?.submenu;
              const childSubmenuCurrentPoscodes = childSubmenuValid?.map((item) => item.poscd);
              const childSubmenuData = childSubmenuGroupItem?.submenu.filter((item) =>
                childSubmenuCurrentPoscodes?.includes(item.poscode)
              );

              const childMinDefaultPriority = Math.min(
                ...childSubmenuData.map((item) => Number(item.default_priority))
              );

              const childSelectedDishCode: string[] = [];
              const childDetailList: DishMenuGroupDetail = {};

              childSubmenuData.forEach((childSubmenuItem) => {
                const childPoscode = childSubmenuItem.poscode;
                const childOptionInfo = getDishInfo(menuList, childPoscode);
                const childDefaultPriority = Number(childSubmenuItem.default_priority);
                const childIsChecked = addDefaultValue
                  ? childNoSelectFlg === '1' ||
                    (childDefaultSettingFlg === '1' && childDefaultPriority === childMinDefaultPriority)
                  : false;

                const childDetailItem: DishMenuGroupDetailValue = {
                  optionInfo: childOptionInfo,
                  checked: childIsChecked,
                  quantity: 1,
                };

                childDetailList[childPoscode] = childDetailItem;

                if (childIsChecked) {
                  childSelectedDishCode.push(childPoscode);
                }
              });

              const childOptionGroup: PortionStateItem = {
                basic: { ...childSubmenuGroupItem, submenu: childSubmenuData },
                detail: childDetailList,
                selected: childSelectedDishCode,
              };

              listChildOptionGroups[`${childSubmenuGroupCode}-0`] = childOptionGroup;
            }
          });

          detailItem.childInfor = listChildOptionGroups;
        }
      });

      const optionGroup: PortionStateItem = {
        basic: subMenuGroupItem,
        detail: detailList,
        selected: selectedDishCode,
      };

      initState[`${subMenuGroupCode}-0`] = optionGroup;
    }
  });

  return [initState];
};
