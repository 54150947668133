/* eslint-disable */
import { useAppSelector } from 'app/hooks';
import CouponFormModal from 'components/CouponFormModal';
import Header from 'components/Header';
import MenuDetailModal from 'components/MenuDetailModal';
import MenuSwipeList from 'components/MenuSwipeList';
import { DishModalContext } from 'context/DishModalContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { selectMenuInfo } from 'store/slices/menuInfoSlice';
import { SESSION_MAIN_PAGE_LOADED, SESSION_MENU_CONTENT_BODY_SCROLLTOP, SessionStorage } from 'utils';
import './MainPage.scss';

function useSessionManagement() {
  useEffect(() => {
    const handleBack = (e: PopStateEvent) => {
      e.preventDefault();
      history.pushState(null, '', location.href);
    };

    if (SessionStorage.getItem(SESSION_MAIN_PAGE_LOADED) === null) {
      history.pushState(null, '', location.href);
      SessionStorage.setItem(SESSION_MAIN_PAGE_LOADED, 'true');
    }

    window.addEventListener('popstate', handleBack);
    window.addEventListener('beforeunload', () => {
      SessionStorage.removeItem(SESSION_MAIN_PAGE_LOADED);
      SessionStorage.removeItem(SESSION_MENU_CONTENT_BODY_SCROLLTOP);
    });

    return () => {
      window.removeEventListener('popstate', handleBack);
      SessionStorage.removeItem(SESSION_MAIN_PAGE_LOADED);
    };
  }, []);
}

function useDishModal() {
  const [dishCode, setDishCode] = useState<string>('');
  const [couponValue, setCouponValue] = useState<{ dishCode: string; modeCode: string }>({
    dishCode: '',
    modeCode: '',
  });
  const [openCouponFormModal, setOpenCouponFormModal] = useState(false);
  const [openDishModal, setOpenDishModal] = useState(false);
  const [openCouponDishModal, setOpenCouponDishModal] = useState(false);

  const showDishModal = useCallback((newDishCode: string) => {
    setDishCode(newDishCode);

    const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
    if (pageWrapper) {
      document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
      pageWrapper.style.overflowY = 'hidden';
    }
    setOpenDishModal(true);
  }, []);

  const showCouponDishModal = useCallback(
    (dishCode: string, modeCode: string) => {
      setCouponValue({ dishCode, modeCode });
      const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
        pageWrapper.style.overflowY = 'hidden';
      }
      setOpenCouponDishModal(true);
    },
    [couponValue]
  );

  const showCouponFormModal = useCallback(() => {
    const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;

    if (pageWrapper) {
      document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
      pageWrapper.style.overflowY = 'hidden';
    }

    setOpenCouponFormModal(true);
  }, []);

  const closeCouponFormModal = useCallback(() => setOpenCouponFormModal(false), []);
  const closeDishModal = useCallback(() => setOpenDishModal(false), []);
  const closeCouponDishModal = useCallback(() => setOpenCouponDishModal(false), []);

  return {
    dishCode,
    couponValue,
    openCouponFormModal,
    openDishModal,
    openCouponDishModal,
    showDishModal,
    showCouponDishModal,
    showCouponFormModal,
    closeCouponFormModal,
    closeDishModal,
    closeCouponDishModal,
  };
}

const MainPage = () => {
  const menuInfo = useAppSelector(selectMenuInfo);
  const cancelButton = useRef<HTMLButtonElement>(null);

  useSessionManagement();

  const {
    dishCode,
    couponValue,
    openCouponFormModal,
    openDishModal,
    openCouponDishModal,
    showDishModal,
    showCouponDishModal,
    showCouponFormModal,
    closeCouponFormModal,
    closeDishModal,
    closeCouponDishModal,
  } = useDishModal();

  useEffect(() => {
    const handleCancel = () => {
      history.go(-1);
      window.location.replace(location.href);
      SessionStorage.removeItem(SESSION_MAIN_PAGE_LOADED);
      return false;
    };

    cancelButton.current?.addEventListener('click', () => setTimeout(handleCancel, 0));
    return () => cancelButton.current?.removeEventListener('click', () => setTimeout(handleCancel, 0));
  }, [menuInfo]);

  return (
    <DishModalContext.Provider value={{ showDishModal, showCouponDishModal, showCouponFormModal }}>
      <Header />
      <div className="main-content">
        <MenuSwipeList />
      </div>
      {openCouponFormModal && <CouponFormModal open={openCouponFormModal} onClose={closeCouponFormModal} />}
      {openDishModal && <MenuDetailModal dishCode={dishCode} onClose={closeDishModal} />}
      {openCouponDishModal && <MenuDetailModal dishCode={couponValue.dishCode} onClose={closeCouponDishModal} />}
    </DishModalContext.Provider>
  );
};

export default MainPage;
