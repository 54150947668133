import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IServerTime, TimeDataType } from 'apis/types';
import { RootState } from 'app/store';
import { SESSION_CURRENT_TIME, SessionStorage } from 'utils';

export interface TimeState {
  loading: boolean;
  data: TimeDataType;
}
const initialState: TimeState = {
  loading: true,
  data: { tm_hh_mm: '' },
};

const timeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    fetchTimeData(state) {
      state.loading = true;
    },
    fetchTimeDataSuccess(state, action: PayloadAction<IServerTime>) {
      state.data = { tm_hh_mm: action.payload.data.tm_hh_mm };
      SessionStorage.setItem(SESSION_CURRENT_TIME, state.data.tm_hh_mm);
      state.loading = false;
    },
    fetchTimeDataFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const timeActions = timeSlice.actions;
// Selectors
export const selectTimeLoading = (state: RootState) => state.time.loading;
export const selectTime = (state: RootState) => state.time.data;
// Reducer
export const timeReducer = timeSlice.reducer;
