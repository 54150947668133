import { Payload } from 'apis/types';
import { AxiosError } from 'axios';
import { all, put } from 'redux-saga/effects';
import customerQuantitySaga from 'store/sagas/customerQuantitySaga';
import mainSaga from 'store/sagas/mainSaga';
import timeSaga from 'store/sagas/timeSaga';
import { errorNotificationActions } from 'store/slices/errorNotificationSlice';

export function* handleHttpError(e: AxiosError) {
  yield put(errorNotificationActions.setError(e));
}

export function* handleResponseError(data: Payload) {
  yield put(errorNotificationActions.setResponse(data));
}

export default function* rootSaga() {
  yield all([mainSaga(), timeSaga(), customerQuantitySaga()]);
}
