import { MenuBookGroupInfoType } from 'apis/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NumberFormat from 'react-number-format';
import { convertPriceInTaxToNoTax } from 'utils';
import './scss/Thumbnail.scss';

interface ThumbnailProps {
  currentMenuBook?: MenuBookGroupInfoType;
  thumbType?: string;
  categorySlideIndex?: number;
  imgSrc: string;
  active?: boolean;
  name: string;
  price?: number;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  disable?: boolean;
  imageHeight?: string;
  lazy?: boolean;
}

function Thumbnail({
  currentMenuBook,
  thumbType = 'menu',
  categorySlideIndex,
  imgSrc,
  active,
  name,
  price,
  handleClick,
  disable = false,
  lazy = false,
}: ThumbnailProps) {
  const { t } = useTranslation();
  const activeClass = active === undefined || active === true ? 'active' : '';
  const disableClass = disable ? 'disabled' : '';

  const privateHandleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleClick && handleClick(e);
  };

  let isShowPrice = true;
  currentMenuBook?.course && currentMenuBook.course !== null
    ? (isShowPrice = price !== undefined && price > 0)
    : (isShowPrice = price !== undefined);

  return (
    <div className={`thumbnail ${activeClass} ${disableClass}`}>
      <button
        className={`${thumbType === 'category' ? 'category-thumbnail-' + categorySlideIndex : ''} `}
        onClick={privateHandleClick}
      >
        <div className="thumbnail-wrapper">
          {lazy ? (
            <img
              className="swiper-lazy"
              data-src={imgSrc}
              src="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='480' height='320'><rect style='fill:rgb(245,245,245);' width='100%' height='100%' /></svg>"
            />
          ) : imgSrc && imgSrc.length > 0 ? (
            <img src={imgSrc} />
          ) : (
            <img src="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='480' height='320'><rect style='fill:rgb(245,245,245);' width='100%' height='100%' /></svg>" />
          )}

          <div className="thumbnail-body">
            <div className="card-text name" dangerouslySetInnerHTML={{ __html: name }} />
            {isShowPrice && (
              <div className="card-price">
                <span className="price-root">
                  {t('OrderHistory.currency')}
                  <NumberFormat
                    className="price-value"
                    value={price ? convertPriceInTaxToNoTax(price) : 0}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                </span>
                <span className="price-tax">
                  ({t('OrderHistory.tax_included')}&nbsp;
                  {t('OrderHistory.currency')}
                  <NumberFormat value={price} displayType={'text'} thousandSeparator={true} />)
                </span>
              </div>
            )}
          </div>
        </div>
      </button>
    </div>
  );
}

export default Thumbnail;
