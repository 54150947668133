import { AllYouCanEatType, MenuBookGroupInfoType } from 'apis/types';
import { checkMenuBookCanShow, getTimeFrameId } from 'utils';
import { COURSECD_DEFAULT } from '../constants';
import { SESSION_CURRENT_COURSE_CD, SESSION_CURRENT_TIME, SESSION_TENPO_INFO } from './sessionStorage';

export function getInitMenubookId(menubookGroup: MenuBookGroupInfoType[], allYouCanEatState: AllYouCanEatType): string {
  const currentTimeFrame = sessionStorage.getItem(SESSION_CURRENT_TIME) ?? '';
  const tenpoInfo = JSON.parse(sessionStorage.getItem(SESSION_TENPO_INFO) ?? '');
  const courseCd = sessionStorage.getItem(SESSION_CURRENT_COURSE_CD) ?? '';
  const currentTimeFrameID = getTimeFrameId(currentTimeFrame, tenpoInfo[0].time_frame)?.time_frame_id;

  if (courseCd === COURSECD_DEFAULT.toString()) {
    if (menubookGroup[0]?.course && menubookGroup[0]?.course !== null) {
      return (
        menubookGroup.find((item) => checkMenuBookCanShow(currentTimeFrameID, item) && !item.course)?.menubook_id ??
        menubookGroup[0].menubook_id
      );
    }

    return (
      menubookGroup.find((item) => checkMenuBookCanShow(currentTimeFrameID, item) && item.course === undefined)
        ?.menubook_id ?? menubookGroup[0].menubook_id
    );
  }

  return menubookGroup[0]?.course &&
    menubookGroup[0].course !== null &&
    allYouCanEatState.isShowDrinkRemainTime &&
    allYouCanEatState.isShowEatRemainTime
    ? menubookGroup[0].menubook_id
    : menubookGroup.find((item) => checkMenuBookCanShow(currentTimeFrameID, item) && item.course === undefined)
        ?.menubook_id ?? menubookGroup[0].menubook_id;
}
