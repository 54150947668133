import { ICouponInfo, IMenuItemInfo } from 'apis/types';

/**
 * Checks if a given dish is included in the coupon's menu list.
 *
 * @param dishInfo - The information of the dish to be checked.
 * @param couponInfo - The information of the coupon containing the menu list.
 * @returns A boolean value indicating whether the dish is included in the coupon's menu list.
 */
export const isDishInCouponListFullCode = (dishInfo: IMenuItemInfo, couponInfo: ICouponInfo): boolean => {
  if (!dishInfo) {
    return false;
  }

  const { poscd } = dishInfo;
  const couponMenuList = couponInfo.coupon_menu;

  return couponMenuList.includes(poscd);
};

export const isDishInCouponListTrimmedCode = (dishInfo: IMenuItemInfo, couponInfo: ICouponInfo): boolean => {
  if (!dishInfo) {
    return false;
  }

  const { poscd } = dishInfo;
  const couponMenuList = couponInfo.coupon_menu;

  const normalizedPoscd = poscd.replace(/^0+/, '');
  const normalizedCouponMenuList = couponMenuList.map((code) => code.replace(/^0+/, ''));

  return normalizedCouponMenuList.includes(normalizedPoscd);
};
