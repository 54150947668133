import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { convertPriceInTaxToNoTax } from 'utils';
import './DishPrice.scss';

interface DishPriceProps {
  price: number;
}

const DishPrice = ({ price }: DishPriceProps) => {
  const { t } = useTranslation();

  const priceWithoutTax = convertPriceInTaxToNoTax(price);
  const currencySymbol = t('OrderHistory.currency');
  const taxIncludedText = t('OrderHistory.tax_included');

  return (
    <div className="dish-price">
      <span className="price-root">
        {currencySymbol}
        <NumberFormat className="price-root-value" value={priceWithoutTax} displayType="text" thousandSeparator />
      </span>
      <span className="price-tax">
        ({taxIncludedText}&nbsp;
        {currencySymbol}
        <NumberFormat value={price} displayType="text" thousandSeparator />)
      </span>
    </div>
  );
};

export default DishPrice;
