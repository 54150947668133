import { ICouponInfo, IMenu, IOrderHistoryResult } from 'apis/types';
import { CartStateData } from 'models';
import { isDishInCouponListFullCode } from 'utils';

/**
 * Counts the total number of coupons used in the cart and order history.
 *
 * @param menuList - The menu list containing all available dishes.
 * @param couponInfoList - The list of coupons available for use.
 * @param cartState - The current state of the cart, containing the dishes and their quantities.
 * @param orderHistory - The order history, containing the details of past orders.
 *
 * @returns The total number of coupons used in the cart and order history.
 */
export const countCouponUsed = (
  menuList: IMenu,
  couponInfoList: ICouponInfo,
  cartState: CartStateData,
  orderHistory: IOrderHistoryResult
): number => {
  const totalCouponUsedInCart = countCouponUsedInCart(menuList, cartState, couponInfoList);
  const totalCouponUsedInOrderHistory = countCouponUsedInOrderHistory(menuList, orderHistory, couponInfoList);

  return totalCouponUsedInCart + totalCouponUsedInOrderHistory;
};

export const countCouponUsedInCart = (
  menuList: IMenu,
  cartState: CartStateData,
  couponInfoList: ICouponInfo
): number => {
  let total = 0;

  Object.entries(cartState).forEach(([dishKey, dishState]) => {
    const [, dishCode] = JSON.parse(dishKey);
    const dishInfo = menuList[dishCode];

    if (isDishInCouponListFullCode(dishInfo, couponInfoList)) {
      const dishQuantity = dishState.state.length;
      total += dishQuantity;
    }
  });

  return total;
};

export const countCouponUsedInOrderHistory = (
  menuList: IMenu,
  orderHistory: IOrderHistoryResult,
  couponInfoList: ICouponInfo
): number => {
  let total = 0;

  const orderHistoryDetail = orderHistory.OrderDetail;

  orderHistoryDetail.forEach((orderDetail) => {
    const orderDetailMenuInfo = orderDetail.OrderDetailMenuInfo;

    orderDetailMenuInfo.forEach((dishOrder) => {
      const dishCode = dishOrder.MenuCode;
      const dishInfo = menuList[dishCode];

      if (dishInfo && isDishInCouponListFullCode(dishInfo, couponInfoList)) {
        const dishQuantity = parseInt(dishOrder.CountInfo.Count);
        total += dishQuantity;
      }
    });
  });

  return total;
};
