import { useAppSelector } from 'app/hooks';
import { selectedMenubookId, selectGenre } from 'store/slices/menuInfoSlice';
import { Swiper as SwiperCore } from 'swiper';
import GenreThumbnail from './components/GenreThumbnail';
import './scss/Genre.scss';
export interface GenreProps {
  mainSwiper?: SwiperCore;
  currentGenreIdAvailables: string[];
  currentGenreIds: string[];
}

export default function Genre({ mainSwiper, currentGenreIdAvailables, currentGenreIds }: GenreProps) {
  // const currentGenreIds: string[] = useMemo(() => {

  //   return currentMode.category.filter((categoryCode: string) => {
  //     let isShow = true;
  //     if (currentModeCode === GRAND_MENU_MODEID || currentModeCode === LUNCH_MENU_MODEID) {
  //       isShow = checkCategoryAvailable(menuInfo, categoryCode);
  //     }

  //     return isShow;
  //   });
  // }, [currentModeCode]);
  const genreData = useAppSelector(selectGenre);
  const currentMenubookId = useAppSelector(selectedMenubookId);

  const ListOfCategories = currentGenreIds.map((genreId, index) => {
    const genreInfo = Object.values(genreData).find((item) => item.genre_id === genreId);
    const availableIndex = currentGenreIdAvailables.findIndex(function (code) {
      return code === genreId;
    });

    return genreInfo ? (
      <GenreThumbnail
        key={`GenreThumbnail-${currentMenubookId}-${genreId}-${index}`}
        mainSwiper={mainSwiper}
        slideIndex={availableIndex}
        genreId={genreId}
        genreInfo={genreInfo}
        menubookId={currentMenubookId}
      />
    ) : null;
  });

  return <div id="genre-thumbnail-block">{ListOfCategories}</div>;
}
