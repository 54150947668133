import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  AllYouCanEatType,
  EatingWayDataType,
  GenreDataType,
  IMenu,
  MenuBookDataType,
  MenuInfoDataType,
  MenuInfoResponse,
  SubgenreDataType,
  ThInfoType,
} from 'apis/types';
import { RootState } from 'app/store';
import {
  SESSION_CURRENT_GENRE_CODE,
  SESSION_CURRENT_MENUBOOK_ID,
  SESSION_SELECTED_MENUBOOK_ID,
  SessionStorage,
  composeFaireData,
  getInitMenubookId,
  getMenuAllYouCanEat,
} from 'utils';
import { GENRE_TOP_MENU_ID } from '../../constants';

export interface MenuInfoState {
  loading: boolean;
  menuInfo: MenuInfoDataType;
  eatingWay: EatingWayDataType;
  menubook: MenuBookDataType;
  genre: GenreDataType;
  subGenre: SubgenreDataType;

  // app state
  currentMenubookId: string;
  selectedMenubookId: string;
  currentGenreId: string;
  faireGenrecd: { genreId: string; subgenreId: string }[];
  searchPOSCD: string[] | null;
  currentCourseCd?: string;
  menubookList: string[];
  faireCd: string;
}
export interface MenuInfoPayLoad {
  data: MenuInfoResponse;
  thInfo?: ThInfoType;
  menuData: IMenu;
  genreId?: string;
  menubookId: string | null;
}
const initialState: MenuInfoState = {
  loading: true,
  menuInfo: {
    eating_way_id: '',
    menubook: [],
  },
  eatingWay: {},
  menubook: {},
  genre: {},
  subGenre: {},
  currentMenubookId: '',
  selectedMenubookId: '',
  currentGenreId: '',
  faireGenrecd: [],
  menubookList: [],
  searchPOSCD: null,
  currentCourseCd: '',
  faireCd: '',
};

const menuInfoSlice = createSlice({
  name: 'menuInfo',
  initialState,
  reducers: {
    initMenu(state) {
      state.loading = true;
    },
    initMenuInfoData(state, action: PayloadAction<MenuInfoPayLoad>) {
      state.menuInfo = action.payload.data.data.menu_info;
      state.eatingWay = action.payload.data.data.eating_way;
      state.menubook = action.payload.data.data.menubook;
      state.genre = action.payload.data.data.genre;
      state.subGenre = action.payload.data.data.subgenre;
      const allYouCanEatState: AllYouCanEatType = {
        isShowEatRemainTime: false,
        isShowDrinkRemainTime: false,
      };
      allYouCanEatState.isShowEatRemainTime =
        action.payload.thInfo?.data.th_flg === '1' && (action.payload.thInfo?.data.th_limit ?? 0) > 0;
      allYouCanEatState.isShowDrinkRemainTime =
        action.payload.thInfo?.data.nh_flg === '1' && (action.payload.thInfo?.data.nh_limit ?? 0) > 0;
      const initialMenuBookId: string = getInitMenubookId(state.menuInfo.menubook, allYouCanEatState);
      const firstGenreId: string = action.payload.genreId ?? GENRE_TOP_MENU_ID;
      state.currentMenubookId = initialMenuBookId;
      state.selectedMenubookId = action.payload.menubookId ?? initialMenuBookId;
      state.currentGenreId = firstGenreId;
      // load currentModeCode, currentGenreId from sessionStorage
      const currentMenubookId = sessionStorage.getItem(SESSION_CURRENT_MENUBOOK_ID);
      const selectedMenubookId = sessionStorage.getItem(SESSION_SELECTED_MENUBOOK_ID);
      const currentGenreId = sessionStorage.getItem(SESSION_CURRENT_GENRE_CODE) ?? firstGenreId;
      if (currentMenubookId && selectedMenubookId && currentGenreId) {
        // restore state currentModeCode, selectedModeCode and currentGenreId
        if (initialMenuBookId === currentMenubookId) {
          state.selectedMenubookId = selectedMenubookId;
          state.currentGenreId = currentGenreId;
        }
      }
      sessionStorage.setItem(SESSION_CURRENT_MENUBOOK_ID, state.currentMenubookId);
      sessionStorage.setItem(SESSION_SELECTED_MENUBOOK_ID, state.selectedMenubookId);
      sessionStorage.setItem(SESSION_CURRENT_GENRE_CODE, state.currentGenreId);
      const currentMenubookData = state.menuInfo.menubook.find((item) => item.menubook_id === initialMenuBookId);

      const currentGenreData =
        currentMenubookData?.course && currentMenubookData.course != null
          ? getMenuAllYouCanEat(currentMenubookData)
          : currentMenubookData?.genre;
      if (currentGenreData) {
        state.faireGenrecd = composeFaireData(currentGenreData, state.subGenre);
      } else {
        state.faireGenrecd = [];
      }
      state.loading = false;
    },
    setCurrentMenubookId(state, action: PayloadAction<{ menubookId: string; genreId: string }>) {
      state.currentMenubookId = action.payload.menubookId;
      state.selectedMenubookId = action.payload.menubookId;
      state.currentGenreId = action.payload.genreId;
      state.searchPOSCD = null;
      SessionStorage.setItem(SESSION_CURRENT_MENUBOOK_ID, state.currentMenubookId);
      SessionStorage.setItem(SESSION_SELECTED_MENUBOOK_ID, state.selectedMenubookId);
      SessionStorage.setItem(SESSION_CURRENT_GENRE_CODE, state.currentGenreId);
    },
    setCurrentGenreId(state, action: PayloadAction<{ selectedMenubookId: string; genreId: string }>) {
      state.selectedMenubookId = action.payload.selectedMenubookId;
      state.currentGenreId = action.payload.genreId;
      state.searchPOSCD = null;

      SessionStorage.setItem(SESSION_SELECTED_MENUBOOK_ID, state.selectedMenubookId);
      SessionStorage.setItem(SESSION_CURRENT_GENRE_CODE, state.currentGenreId);
    },
    setFaireData(
      state,
      action: PayloadAction<{ menuInfo: MenuInfoDataType; selectedMenubookId: string; genreId: string }>
    ) {
      const currentMenubookData = action.payload.menuInfo.menubook.find(
        (item) => item.menubook_id === action.payload.selectedMenubookId
      );

      let currentGenreData: any;
      if (currentMenubookData && action.payload.genreId != '') {
        currentGenreData =
          currentMenubookData?.course && currentMenubookData.course != null
            ? getMenuAllYouCanEat(currentMenubookData)
            : currentMenubookData.genre;
      }

      if (currentGenreData) {
        state.faireGenrecd = composeFaireData(currentGenreData, state.subGenre);
      } else {
        state.faireGenrecd = [];
      }
    },
    initMenuInfoDataFailed(state) {
      state.loading = true;
    },
    updateCampaign(state, action: PayloadAction<{ modeCode: string; categorycd: string; subGenreId: string }>) {
      state.currentMenubookId = action.payload.modeCode;
      state.currentGenreId = action.payload.categorycd;
      state.faireCd = action.payload.subGenreId;
      state.searchPOSCD = null;
    },
    updateCampaignGenrecd(state, action: PayloadAction<string>) {
      state.faireCd = action.payload;
    },
  },
});

export const menuInfoActions = menuInfoSlice.actions;

export const selectMenuInfoLoading = (state: RootState) => state.menuInfo.loading;
export const selectMenuInfo = (state: RootState) => state.menuInfo.menuInfo;
export const selectEatingWay = (state: RootState) => state.menuInfo.eatingWay;
export const selectMenuBook = (state: RootState) => state.menuInfo.menubook;
export const selectGenre = (state: RootState) => state.menuInfo.genre;
export const selectSubGenre = (state: RootState) => state.menuInfo.subGenre;

export const selectCurrentMenubookId = (state: RootState) => state.menuInfo.currentMenubookId;
export const selectedMenubookId = (state: RootState) => state.menuInfo.selectedMenubookId;
export const selectCurrentGenreId = (state: RootState) => state.menuInfo.currentGenreId;
export const selectFaire = (state: RootState) => state.menuInfo.faireGenrecd;
export const selectSearchPOSCD = (state: RootState) => state.menuInfo.searchPOSCD;
export const selectCurrentListMenubooks = (state: RootState) => state.menuInfo.menubookList;
export const selectFaireCd = (state: RootState) => state.menuInfo.faireCd;
export const selectPoscdInMenuInfo = createSelector(selectMenuInfo, (menuInfo: MenuInfoDataType) => {
  const dataResult: { menubookId: string; poscd: string[] }[] = [];
  menuInfo.menubook.forEach((item) => {
    const posCdData: string[] = [];
    const genreData = item.course ? getMenuAllYouCanEat(item) : item.genre;
    genreData?.forEach((genre) => {
      genre.subgenre.forEach((subgenre) => {
        subgenre.menu?.forEach((menu) => {
          posCdData.push(menu.poscd);
          menu.submenugroup?.forEach((subMenu) => {
            subMenu.submenu.forEach((subMenuItem) => {
              posCdData.push(subMenuItem.poscd);
            });
          });
        });
      });
    });
    dataResult.push({ menubookId: item.menubook_id, poscd: posCdData });
  });
  return dataResult;
});

export const menuInfoReducer = menuInfoSlice.reducer;
