import { playSound } from 'sound/Sound';
import { AraMinusIcon, AraPlusIcon } from 'theme/icons';
import './QuantityButtonGroup.scss';

interface QuantityButtonGroupProps {
  onDecrease: () => void;
  onIncrease: () => void;
  quantity: number;
  min?: number;
  disableMin?: boolean;
}

const QuantityButtonGroup = ({
  onDecrease,
  onIncrease,
  quantity,
  min,
  disableMin = false,
}: QuantityButtonGroupProps) => {
  const isMinDisabled = min && quantity === min && disableMin;

  const handleDecrease = () => {
    if (!isMinDisabled) {
      onDecrease();
      playSound('minus');
    }
  };

  const handleIncrease = () => {
    onIncrease();
    playSound('plus');
  };

  return (
    <div className="btn-group quantity-control" role="group" aria-label="Basic outlined example">
      <a className={`btn-minus ${min && quantity === min && disableMin ? 'disabled' : ''}`} onClick={handleDecrease}>
        <AraMinusIcon />
      </a>
      <span className="">{quantity}</span>
      <a className="btn-plus" onClick={handleIncrease}>
        <AraPlusIcon />
      </a>
    </div>
  );
};

export default QuantityButtonGroup;
