import React, { RefObject, useEffect, useRef, useState } from 'react';
import { AraAngleDownIcon } from 'theme/icons';
import { attrLang, getDishInfo } from 'utils';
import { t } from 'i18next';
import { useAppSelector } from 'app/hooks';
import { selectMenu, selectSubMenuGroup } from 'store/slices/mainSlice';
import { Collapse } from 'bootstrap';
import { PortionStateItem } from 'models';
import './OptionChildMenuGroup.scss';
import SelectedOption from '../SelectedOption';
import OptionChildMenuItem from '../OptionChildMenuItem/OptionChildMenuItem';

interface OptionChildMenuGroupProps {
  dishCode: string;
  orderIdx: number;
  groupCode: string;
  groupState: PortionStateItem;
  isChild: boolean;
  parentCode: string;
  parentQuantity: number;
  childGroupCodeAndIndex: string;
}

export const OptionChildMenuGroup = ({
  dishCode,
  orderIdx,
  groupCode,
  groupState,
  isChild,
  parentCode,
  parentQuantity,
  childGroupCodeAndIndex,
}: OptionChildMenuGroupProps) => {
  const defaultRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collapseClass, setColapseClass] = useState<string>('');
  const [isShowing, setIsShowing] = useState<boolean>(false);

  const menuList = useAppSelector(selectMenu);
  const submenuData = useAppSelector(selectSubMenuGroup);
  const nameSubmenuGroup = attrLang(submenuData[groupState.basic.cd], 'lang');

  const handleToggle = () => {
    toggleCollapse(defaultRef);
    toggleCollapse(selectedRef);
    setIsShowing((prev) => !prev);
  };

  const { basic: groupBasic, detail: groupDetail } = groupState;

  useEffect(() => {
    updateCollapseState(selectedRef, defaultRef);
  }, [collapseClass]);

  const toggleCollapse = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current) {
      const controller = Collapse.getOrCreateInstance(ref.current);
      controller.toggle();
    }
  };

  const updateCollapseState = (selectedRef: RefObject<HTMLDivElement>, defaultRef: RefObject<HTMLDivElement>) => {
    if (
      (groupState.selected.length > 0 && groupState.basic.selection_condition === '必須選択') ||
      groupState.basic.selection_condition === '任意選択'
    ) {
      selectedRef.current?.classList.add('show');
      defaultRef.current?.classList.remove('show');
      setIsShowing(true);
    } else {
      selectedRef.current?.classList.remove('show');

      if (!defaultRef.current?.classList.contains('show')) {
        defaultRef.current?.classList.add('show');
      }
    }
  };

  const ListOfSelectedOption: JSX.Element[] = [];
  Object.values(groupState.detail).forEach((option) => {
    const optionName = attrLang(option.optionInfo, 'lang');
    option.checked &&
      ListOfSelectedOption.push(
        <SelectedOption
          key={option.optionInfo.poscd}
          optionName={optionName}
          optionQuantity={option.quantity / parentQuantity}
        />
      );
  });

  const ListOfOption = Object.entries(groupState.detail).map(([optionCode]) => {
    const optionInfo = getDishInfo(menuList, optionCode);

    return (
      <OptionChildMenuItem
        key={optionCode}
        dishCode={dishCode}
        groupCode={groupCode}
        orderIdx={orderIdx}
        groupInfo={groupBasic}
        optionInfo={optionInfo}
        itemState={groupDetail[optionCode]}
        isChild={isChild}
        parentCode={parentCode}
        parentQuantity={parentQuantity}
        childGroupCodeAndIndex={childGroupCodeAndIndex}
      />
    );
  });

  return (
    <div
      className="child-option-group"
      id={`option-group-${dishCode}-${orderIdx}-${childGroupCodeAndIndex?.slice(0, -2)}`}
    >
      {ListOfOption.length > 0 && (
        <div className={`collapse-child-button ${collapseClass}`} onClick={handleToggle}>
          <div className="order-name">
            <span>{nameSubmenuGroup}</span>
            <span className="order-name-nav">
              <AraAngleDownIcon className={`arrow-down ${isShowing ? 'show' : ''}`} />
            </span>
          </div>
        </div>
      )}
      <div className="collapse selected-list" ref={selectedRef}>
        <div className="list-child-group-wrapper" style={{ backgroundColor: 'white' }}>
          {ListOfSelectedOption.length > 0 ? (
            ListOfSelectedOption
          ) : (
            <p style={{ padding: '10px 8px 10px 16px', marginBottom: 0, backgroundColor: 'white' }}>
              {t('App.no_select_menu')}
            </p>
          )}
        </div>
      </div>
      <div className="collapse show default-list" ref={defaultRef}>
        <div className="list-child-group-wrapper">
          <div className="selected-options" style={{ paddingTop: '0rem', paddingBottom: '0rem' }}>
            <ul className="list-option-group">{ListOfOption}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};
