import { checkUserCheckout } from 'apis/Api';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import useInterval from 'hooks/useInterval';
import { useState } from 'react';
import { dispatchErrorResponse } from 'utils';
import { CHECK_SLIP_STATUS_INTERVAL, RES_CODE_SUCCESS } from '../constants';

const useCheckSlipStatus = () => {
  const { showGlobalModal } = useGlobalModal();
  const [slipStatus, setSlipStatus] = useState<number | null>(null);

  const handleCheckSlipStatus = async () => {
    try {
      const response = await checkUserCheckout();

      if (response.code !== RES_CODE_SUCCESS) {
        dispatchErrorResponse(showGlobalModal, response);
      } else {
        const newSlipStatus = Number(response.slip_sts);

        if (slipStatus !== newSlipStatus) {
          setSlipStatus(newSlipStatus);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useInterval(
    () => {
      handleCheckSlipStatus();
    },
    CHECK_SLIP_STATUS_INTERVAL,
    true
  );

  return slipStatus;
};

export default useCheckSlipStatus;
