/* eslint-disable */
import { useTranslation } from 'react-i18next';

import { GenreGroupMenuBookInfoType, MenuBookGroupInfoType, MenuGroupInfoType } from 'apis/types';
import { useAppSelector } from 'app/hooks';
import MenuItemThumbnail from 'components/MenuItemThumbnail/MenuItemThumbnail';
import { selectAllYouCanEatState, selectThMenuInfo } from 'store/slices/allYouCanEatSlice';
import {
  selectDispcondGroup,
  selectDispcondMenu,
  selectHaveAdult,
  selectHistoryFlag,
  selectMenu,
  selectOrderHistoryData,
  selectOutOfStockItems,
  selectTenpoInfo,
  selectTimeFrameLunch,
} from 'store/slices/mainSlice';
import { selectSubGenre } from 'store/slices/menuInfoSlice';
import { selectTime } from 'store/slices/timeSlice';
import { getErrorMessage, getMenuAllYouCanEat, getTimeFrameId, SESSION_CURRENT_TIME } from 'utils';
import { attrLang } from 'utils/attrLang';

export interface SubGenreMenuMenuProps {
  menubookId: string;
  genreId: string;
  subgenreId: string;
  genreSub?: MenuGroupInfoType[];
  currentMenubook?: MenuBookGroupInfoType;
}

function SubGenreMenu({ menubookId, genreId, subgenreId, genreSub, currentMenubook }: SubGenreMenuMenuProps) {
  const { t } = useTranslation();

  const ListOfMenuItems: JSX.Element[] = [];
  const subgenreData = useAppSelector(selectSubGenre);

  const menu = useAppSelector(selectMenu);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const outOfStockItems = useAppSelector(selectOutOfStockItems);
  const haveAdult = useAppSelector(selectHaveAdult);
  const historyFlag = useAppSelector(selectHistoryFlag);
  const timeServer = useAppSelector(selectTime);
  const tenpoInfo = useAppSelector(selectTenpoInfo);
  const orderHistoryData = useAppSelector(selectOrderHistoryData);
  const dispcondMenu = useAppSelector(selectDispcondMenu);
  const dispcondGroup = useAppSelector(selectDispcondGroup);
  const timeFrameLunch = useAppSelector(selectTimeFrameLunch);
  const timeFrame = getTimeFrameId(
    sessionStorage.getItem(SESSION_CURRENT_TIME) ?? timeServer.tm_hh_mm,
    tenpoInfo?.time_frame
  );
  const currentSubGenre = subgenreData[subgenreId];
  const subGenreName = attrLang(currentSubGenre, 'lang');
  let genreData: GenreGroupMenuBookInfoType[] = [];
  if (currentMenubook) {
    genreData =
      currentMenubook?.course && currentMenubook.course !== null
        ? getMenuAllYouCanEat(currentMenubook)
        : currentMenubook.genre ?? [];
  }

  genreData = genreData?.filter((item) => item.genre_id === genreId);
  if (genreSub && currentMenubook) {
    genreSub.forEach((sub) => {
      const menuItemInfo = menu[sub.poscd];
      if (menuItemInfo && genreData) {
        const outOfStocked = outOfStockItems.includes(sub.poscd);

        const messageOverlay = getErrorMessage(
          thMenuInfo.code,
          timeFrameLunch.includes(timeFrame.time_frame_id),
          historyFlag,
          allYouCanEatState,
          menuItemInfo,
          currentMenubook,
          outOfStocked,
          parseInt(haveAdult),
          dispcondMenu,
          dispcondGroup,
          orderHistoryData.OrderDetail
        );
        //  ||
        // getErrorMessageRelatedToOrderShabushabuAndSukiyaki(
        //   menuItemInfo,
        //   orderHistoryData,
        //   shabushabuMenu,
        //   sukiyakiMenu,
        //   dashiMenu
        // );

        // issue #107
        const isShowMenu =
          messageOverlay !== t('MenuList.lunch_time_only') && messageOverlay !== t('MenuList.lunch_time_excluded');
        if (isShowMenu && menuItemInfo) {
          ListOfMenuItems.push(
            <MenuItemThumbnail
              key={sub.poscd}
              itemInfo={menuItemInfo}
              menubookId={menubookId}
              genreId={genreId}
              messageOverlay={messageOverlay}
              outOfStocked={outOfStocked}
              currentMenuBook={currentMenubook}
            />
          );
        }
      }
    });
  }

  // useEffect(() => {
  //   if (menuList.length == 0 && currentGenre.code === GENRE_SEARCH_MENU_ID) {
  //     playSound('error');
  //     showGlobalModal(() => <AlertModal message={t('MenuList.search_no_result')} />);
  //   }
  // }, [genreSub]);

  // if (currentGenre && currentGenre.code === GENRE_SEARCH_MENU_ID) {
  //   return (
  //     <div className="subgenre-menu-block">
  //       {genreName && genreName.length > 0 && <div className="genre-name sticky">{genreName}</div>}
  //       {menuList.length == 0 && (
  //         <div className="thumbnail-block no-result">
  //           <p
  //             dangerouslySetInnerHTML={{
  //               __html: t('MenuList.search_no_result'),
  //             }}
  //           />
  //         </div>
  //       )}
  //       <div className="thumbnail-block">{ListOfMenuItems}</div>
  //     </div>
  //   );
  // }

  return ListOfMenuItems.length > 0 ? (
    <div id={`subgenre-menu-${subgenreId}`} className="subgenre-menu-block">
      {subGenreName && subGenreName.length > 0 && (
        <div
          className="subgenre-name"
          data-subgenrename={subGenreName}
          dangerouslySetInnerHTML={{ __html: subGenreName }}
        ></div>
      )}
      <div className="thumbnail-block">{ListOfMenuItems}</div>
    </div>
  ) : null;
}

export default SubGenreMenu;
