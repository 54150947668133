import { IMenubookCourseInfo, IMenubookCourseIntroduction } from 'apis/types';
import { imgFullPath } from 'utils';
import { ALL_YOU_CAN_EAT_PROPERTY, NO_SELECTION_PROPERTY, NO_SELECTION_PROPERTY_NUM } from '../constants';

export const getAllImagesIntroduction = (
  timeFrameId: string,
  menubookCourseIntroductionItem: IMenubookCourseIntroduction,
  menubookCourse: IMenubookCourseInfo[]
): string[] => {
  const imgList: string[] = [];
  const menubookItem = Object.values(menubookCourseIntroductionItem).filter((item) => {
    let isAllYouCanEat = false;
    if (
      item.property &&
      item.property.toString() !== NO_SELECTION_PROPERTY &&
      item.property.toString() !== ALL_YOU_CAN_EAT_PROPERTY
    ) {
      isAllYouCanEat = item.property !== NO_SELECTION_PROPERTY_NUM;
    } else {
      isAllYouCanEat = item.property !== NO_SELECTION_PROPERTY;
    }
    return menubookCourse.some((course) => course.menubook_id === item.menubook_id) && isAllYouCanEat;
  });

  if (menubookItem.length > 0) {
    menubookItem.forEach((menubook) => {
      if (menubook.course) {
        Object.values(menubook.course).forEach((course) => {
          if (
            menubookCourse.some(
              (item) =>
                item.menubook_id === menubook.menubook_id &&
                item.course_id === course.course_id &&
                item.time_frame_id.includes(timeFrameId)
            )
          ) {
            const imgLink = course.mobile_order_img;
            if (imgLink) {
              imgList.push(imgFullPath(imgLink));
            }
          }
        });
      }
    });
  }
  return imgList;
};
