import { useAppSelector } from 'app/hooks';
import OptionItem from 'components/DishModal/components/OptionItem';
import { PortionStateItem } from 'models';
import { useTranslation } from 'react-i18next';
import { selectMenu } from 'store/slices/mainSlice';
import { attrLang, getDishInfo } from 'utils';
import { REQUIRED_SELECT_SUBMENUGROUP } from '../../../../constants';

interface OptionGroupProps {
  dishCode: string;
  orderIdx: number;
  groupCode: string;
  groupState: PortionStateItem;
}

const OptionGroup = ({ dishCode, orderIdx, groupCode, groupState }: OptionGroupProps) => {
  const { t } = useTranslation();

  const menuList = useAppSelector(selectMenu);

  const { basic: groupBasic, detail: groupDetail } = groupState;

  const ListOfGroupDetail = Object.keys(groupDetail).map((optionCode) => {
    const optionInfo = getDishInfo(menuList, optionCode);
    if (!optionInfo) {
      return null;
    }

    return (
      <OptionItem
        key={optionCode}
        dishCode={dishCode}
        groupCode={groupCode}
        orderIdx={orderIdx}
        groupInfo={groupBasic}
        optionInfo={optionInfo}
        itemState={groupDetail[optionCode]}
        parentCode={groupState.selected[0]}
      />
    );
  });

  const kouseumText = t('MenuItemDetail.please_select_an_item', { quantity: groupBasic.kousenum });

  return (
    <div id={`option-group-${dishCode}-${orderIdx}-${groupBasic.cd}`} className="menu-option-group">
      <div className="option-group-header">
        <span dangerouslySetInnerHTML={{ __html: attrLang(groupBasic, 'lang') }} />
        {Number(groupBasic.kousenum) > 1 &&
          groupBasic.selection_condition === REQUIRED_SELECT_SUBMENUGROUP &&
          ` (${kouseumText})`}
      </div>
      <ul className="list-option-group">{ListOfGroupDetail}</ul>
    </div>
  );
};

export default OptionGroup;
