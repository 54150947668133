import { AllYouCanEatType, GenreGroupMenuBookInfoType, IMenu } from 'apis/types';

export function checkAllYouCanEatMenuOver(
  genreData: GenreGroupMenuBookInfoType,
  allYouCanEatState: AllYouCanEatType,
  menuData: IMenu
): boolean {
  //all you can eat menu
  if (genreData) {
    if (!allYouCanEatState.isShowEatRemainTime && !allYouCanEatState.isShowDrinkRemainTime) {
      return true;
    }
    if (!allYouCanEatState.isShowEatRemainTime) {
      for (const sub of genreData.subgenre) {
        if (
          sub.menu?.some((menu) => {
            return allYouCanEatState.isShowDrinkRemainTime && menuData[menu.poscd]?.th_bunruicd === '02';
          })
        ) {
          return false;
        }
      }
      return true;
    }
    if (!allYouCanEatState.isShowDrinkRemainTime) {
      for (const sub of genreData.subgenre) {
        if (
          sub.menu?.some((menu) => {
            return allYouCanEatState.isShowEatRemainTime && menuData[menu.poscd]?.th_bunruicd === '01';
          })
        ) {
          return false;
        }
      }

      return true;
    }
  }

  return false;
}
