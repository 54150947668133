import { callStaff, sendOrder, updateCartInfo } from 'apis/Api';
import { CartInfoType, OrderAPIRes, OrderAPIType } from 'apis/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import DishPrice from 'components/DishPrice';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import MenuBarOrderSuccessModal from 'components/MenuBarOrderSuccessModal';
import PageHeader from 'components/PageHeader';
import DishGroup from 'pages/CartPage/components/DishGroup';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { selectAllYouCanEatState, selectThMenuInfo } from 'store/slices/allYouCanEatSlice';
import { cartActions, selectCartState, selectTotalAmount } from 'store/slices/cartSlice';
import { customerQuantityActions } from 'store/slices/customerQuantitySlice';
import {
  mainActions,
  selectBarInfo,
  selectBarMenu,
  selectHaveAdult,
  selectHistoryFlag,
  selectMenu,
  selectOutOfStockItems,
  selectServiceInfo,
  selectTenpoInfo,
  selectTimeFrameLunch,
} from 'store/slices/mainSlice';
import { selectedMenubookId, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { selectTime } from 'store/slices/timeSlice';
import {
  attrLang,
  checkInValidItemInCart,
  checkServiceRequiredItems,
  composeMenubookList,
  convertCartStateToCartOrder,
  convertPortionStateToPortionInfo,
  delayExecution,
  getBarInfoCodeFromOrderData,
  getDishInfo,
  getTimeFrameId,
  groupOrderBySimilarity,
  handleErrorResponse,
  SESSION_CURRENT_TIME,
  stripHtml,
} from 'utils';
import { RES_CODE_EXCEED_COUPON_LIMIT, RES_CODE_OUT_OF_STOCK_ERROR, RES_CODE_SUCCESS } from '../../constants';
import './CartPage.scss';

const CartPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showGlobalModal } = useGlobalModal();
  const dispatch = useAppDispatch();

  const buttonBackRef = useRef<HTMLButtonElement>(null);
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const totalAmount = useAppSelector(selectTotalAmount);
  const cartState = useAppSelector(selectCartState);
  const menuList = useAppSelector(selectMenu);
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const tenpoInfo = useAppSelector(selectTenpoInfo);
  const historyFlag = useAppSelector(selectHistoryFlag);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const outOfStockItems = useAppSelector(selectOutOfStockItems);
  const haveAdult = useAppSelector(selectHaveAdult);
  const timeServer = useAppSelector(selectTime);
  // const eatingWayMain = useAppSelector(selectEatingWaySelect);
  // const eatingWayId = useAppSelector(selectEatingWayId);
  const selectMenubookId = useAppSelector(selectedMenubookId);
  const menuInfo = useAppSelector(selectMenuInfo);
  const serviceInfo = useAppSelector(selectServiceInfo);
  const barMenu = useAppSelector(selectBarMenu);
  const listBarInfo = useAppSelector(selectBarInfo);
  const timeFrameLunch = useAppSelector(selectTimeFrameLunch);

  const timeFrame = getTimeFrameId(
    sessionStorage.getItem(SESSION_CURRENT_TIME) ?? timeServer.tm_hh_mm,
    tenpoInfo?.time_frame
  );

  const menuGroupAvailable = composeMenubookList(timeFrame.time_frame_id, menuInfo.menubook);
  const currentMenubook = menuGroupAvailable.find((item) => item.menubook_id === selectMenubookId);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];

    if (!body.classList.contains('full-height')) {
      body.classList.add('full-height');
      window.scrollTo(0, 0);
    }

    return () => {
      if (body.classList.contains('full-height')) {
        body.classList.remove('full-height');
      }
    };
  }, []);

  useEffect(() => {
    // close modal when no item in cart
    if (Object.entries(cartState).length === 0) {
      delayExecution(() => {
        if (!document.querySelector('.alert-modal')) {
          navigate(-1);
        }
      });
    } else {
      // update cartInfo in backend
      const orderList: CartInfoType[] = [];

      for (const [dishKey, dishValue] of Object.entries(cartState)) {
        const uniquePortionQuantities = groupOrderBySimilarity(dishValue.state);

        for (const [portionStateString, portionQuantity] of Object.entries(uniquePortionQuantities)) {
          const portionState = JSON.parse(portionStateString);
          const portionInfo = convertPortionStateToPortionInfo(dishKey, portionState, portionQuantity);
          orderList.push(portionInfo);
        }
      }

      updateCartInfo(orderList);
    }
  }, [cartState]);

  const handleGoBack = () => {
    if (buttonBackRef.current) {
      if (buttonBackRef.current.disabled) {
        return;
      }

      buttonBackRef.current.disabled = true;
    }

    playSound('page');
    navigate(-1);
  };

  const handleOutOfStock = (listOutOfStockItems: string[], dataCartOrder: OrderAPIType) => {
    const itemNames = listOutOfStockItems
      .map((itemCode) => {
        const dishInfo = getDishInfo(menuList, itemCode);
        return stripHtml(attrLang(dishInfo, 'lang'));
      })
      .filter((itemName) => itemName !== '');

    dispatch(mainActions.setOutOfStockItems(listOutOfStockItems));

    playSound('error');
    showGlobalModal(() => (
      <AlertModal message={`${t('Cart.out_of_stock')} ${itemNames.length > 0 ? itemNames.join(', ') : ''}`} />
    ));

    dispatch(cartActions.resetCart());

    const serviceRequiredItems = checkServiceRequiredItems(serviceInfo, dataCartOrder);
    const availableServiceRequiredItems = serviceRequiredItems.filter(
      (poscd: string) => !listOutOfStockItems.includes(poscd)
    );

    if (availableServiceRequiredItems.length > 0) {
      callStaff(availableServiceRequiredItems);
    }
  };

  const handleExceedCouponLimit = () => {
    dispatch(customerQuantityActions.fetchCustomerQuantityData());

    playSound('error');
    showGlobalModal(() => <AlertModal message={t('Cart.exceed_coupon_limit')} />);
  };

  const handleErrorResponseMessage = (response: OrderAPIRes) => {
    handleErrorResponse(showGlobalModal, response, response.result.message);
  };

  const handleSuccessfulOrder = (dataCartOrder: OrderAPIType) => {
    const serviceRequiredItems = checkServiceRequiredItems(serviceInfo, dataCartOrder);

    if (serviceRequiredItems.length > 0) {
      callStaff(serviceRequiredItems);
    }

    const barCode = getBarInfoCodeFromOrderData(dataCartOrder, barMenu, listBarInfo);

    if (barCode) {
      const barInfoItem = listBarInfo[Number(barCode)];

      showGlobalModal(() => (
        <MenuBarOrderSuccessModal
          barInfoItem={barInfoItem}
          onClose={() => {
            navigate(-1);
          }}
        />
      ));
    } else {
      showGlobalModal(() => (
        <AlertModal
          message={t('Cart.order_success')}
          onClose={() => {
            navigate(-1);
          }}
        />
      ));
    }

    if (buttonSubmitRef.current) {
      buttonSubmitRef.current.disabled = false;
    }

    dispatch(cartActions.resetCart());
  };

  const handleOrder = () => {
    if (buttonSubmitRef.current) {
      if (buttonSubmitRef.current.disabled) {
        return;
      }

      buttonSubmitRef.current.disabled = true;
    }

    delayExecution(async () => {
      playSound('order');

      if (currentMenubook) {
        const { invalidItemName, errorMessage } = checkInValidItemInCart(
          cartState,
          menuList,
          thMenuInfo,
          timeFrame,
          historyFlag,
          allYouCanEatState,
          currentMenubook,
          outOfStockItems,
          parseInt(haveAdult),
          timeFrameLunch
        );

        if (invalidItemName !== '') {
          showGlobalModal(() => (
            <AlertModal message={`${t('App.invalid_item_exist')}: ${errorMessage} (${invalidItemName})`} />
          ));

          if (buttonSubmitRef.current) {
            buttonSubmitRef.current.disabled = false;
          }

          return;
        }
      }

      const dataCartOrder = convertCartStateToCartOrder(cartState, menuList);
      console.log(dataCartOrder);
      try {
        const response = await sendOrder(dataCartOrder);

        if (response.code !== RES_CODE_SUCCESS) {
          if (buttonSubmitRef.current) {
            buttonSubmitRef.current.disabled = false;
          }

          if (
            response.code.slice(2, 4) === RES_CODE_OUT_OF_STOCK_ERROR &&
            response.result &&
            response.result.additionInfo
          ) {
            handleOutOfStock(response.result.additionInfo, dataCartOrder);
          } else if (response.code === RES_CODE_EXCEED_COUPON_LIMIT) {
            handleExceedCouponLimit();
          } else {
            handleErrorResponseMessage(response);
          }
        } else {
          handleSuccessfulOrder(dataCartOrder);
        }
      } catch (err) {
        playSound('error');
        showGlobalModal(() => <AlertModal message={t('Cart.order_fail')} />);
        console.log('err', err);
      }
    });
  };

  const ListOfGroup = Object.entries(cartState).map(([dishKey, dishValue]) => {
    return <DishGroup key={dishKey} dishKey={dishKey} dishValue={dishValue} />;
  });

  return (
    <div id="cart">
      <PageHeader handleGoBack={handleGoBack} />
      <div id="dishes" className="cart-body">
        {ListOfGroup}
      </div>
      <div className="cart-footer">
        <div className="row g-0 cart-price">
          <div className="col-3 total-price-text">{t('OrderHistory.total_price')}</div>
          <div className="col total-price">
            <DishPrice price={totalAmount} />
          </div>
        </div>
        <div className="row g-0">
          <div className="col button-cart-back">
            <button className="ara-btn ara-btn-outline single" ref={buttonBackRef} onClick={handleGoBack}>
              <div>{t('Cart.go_back')}</div>
            </button>
          </div>
          <div className="col button-cart-order">
            <button className="ara-btn ara-btn-primary single" ref={buttonSubmitRef} onClick={handleOrder}>
              <div>{t('Cart.order')}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
