import DishPrice from 'components/DishPrice';
import { CartDishModalContext } from 'context/CartDishModalContext';
import { PortionState } from 'models';
import DishQuantitySelector from 'pages/CartPage/components/DishQuantitySelector';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSelector } from 'react-redux';
import { playSound } from 'sound/Sound';
import { selectMenu } from 'store/slices/mainSlice';
import {
  attrLang,
  getDishInfo,
  getOrderCost,
  imgFullPath,
  mergeCompactStateEntries,
  stripHtml,
  summarizeOrderState,
} from 'utils';

interface DishProps {
  editTable: boolean;
  dishKey: string;
  state: PortionState;
  quantity: number;
  updateSelectedDishState: (newPortionState: PortionState) => void;
}

const Dish = ({ editTable, dishKey, state, quantity, updateSelectedDishState }: DishProps) => {
  const { t } = useTranslation();
  const { showDishModal } = useContext(CartDishModalContext);

  const [, dishCode] = JSON.parse(dishKey);
  const menuList = useSelector(selectMenu);
  const compactState = summarizeOrderState(state);
  const dishInfo = getDishInfo(menuList, dishCode);
  const dishName = stripHtml(attrLang(dishInfo, 'lang'));
  const dishImgSrc = imgFullPath(dishInfo.img);
  const dishPrice = getOrderCost(state, dishInfo);

  const handleShowModal = () => {
    updateSelectedDishState(state);
    showDishModal?.();

    playSound('page');
  };

  const clickableProps = editTable ? { onClick: handleShowModal } : {};

  const optionList: JSX.Element[] = [];
  const compactStateEntries = Object.entries(compactState);

  const mergedCompactStateEntries = mergeCompactStateEntries(compactStateEntries);

  mergedCompactStateEntries.forEach((optionValue, optionCodeVersion) => {
    const optionInfo = optionValue.optionInfo;
    const optionName = attrLang(optionInfo, 'lang');
    const optionQuantity = optionValue.quantity * quantity;
    const childInfor = optionValue.childInfor;

    optionList.push(<li key={optionCodeVersion}>{`${optionName} (${optionQuantity})`}</li>);
    if (childInfor) {
      Object.entries(childInfor).forEach(([childGroupCodeVersion, childGroupState]) => {
        const childGroupInfo = childGroupState.optionInfo;
        const childGroupName = attrLang(childGroupInfo, 'lang');
        const childQuantity = childGroupState.quantity * quantity;

        optionList.push(
          <li key={childGroupCodeVersion} style={{ paddingLeft: '8px' }}>{`${childGroupName} (${childQuantity})`}</li>
        );
      });
    }
  });

  return (
    <div className="card dish-card">
      <div className="row g-0">
        <div className="col-6">
          <div className="img-cover" {...clickableProps}>
            <LazyLoadImage
              wrapperClassName="d-block"
              className="img-fluid rounded-start"
              alt="..."
              effect="opacity"
              src={dishImgSrc}
            />
          </div>
        </div>
        <div className="col-6 d-flex flex-column justify-content-between">
          <div className="card-content">
            <div {...clickableProps}>
              <h3>{dishName}</h3>
              {Object.keys(compactState).length > 0 && <ul>{optionList}</ul>}
              <DishPrice price={dishPrice} />
            </div>
          </div>
          <div className="card-quantity d-flex">
            <div className="align-self-center text-quantity">{t('Cart.quantity')}</div>
            <div>
              <DishQuantitySelector dishInfo={dishInfo} dishKey={dishKey} addedState={state} quantity={quantity} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dish;
