import { useAppSelector } from 'app/hooks';
import HistoryMenuItem from 'pages/OrderHistoryPage/components/HistoryMenuItem';
import { selectMenu, selectOrderHistoryData } from 'store/slices/mainSlice';
import { getDishCostFromOrderHistory } from 'utils';

const HistoryMenuList = () => {
  const orderHistoryData = useAppSelector(selectOrderHistoryData);
  const menuList = useAppSelector(selectMenu);

  return (
    <>
      {orderHistoryData.OrderDetail?.map((historyOrder, idx) => {
        const orderType = historyOrder.OrderDetailHeader.ClientInfo.Type;

        return historyOrder.OrderDetailMenuInfo?.map((dishOrderState, jdx) => {
          const orderCost = getDishCostFromOrderHistory(dishOrderState, menuList);

          return (
            <HistoryMenuItem
              key={`${idx}-${jdx}`}
              orderType={orderType}
              dishOrderState={dishOrderState}
              orderCost={orderCost}
            />
          );
        });
      })}
    </>
  );
};

export default HistoryMenuList;
