import { DishMenuGroupDetail, DishMenuGroupDetailValue, PortionState, PortionStateItem } from '../../../models/cart';

type ActionChangeQuantityType = 'increase' | 'decrease';

export type ActionType =
  | { type: 'init'; payload: PortionState[] }
  | { type: 'delete-order'; payload: number }
  | { type: 'increase'; payload: PortionState }
  | { type: 'decrease' }
  | {
      type: 'check';
      payload: {
        orderIdx: number;
        groupCode: string;
        optionCode: string;
        checked: boolean;
        isChild: true;
        parentCode: string;
        childGroupCodeAndIndex: string;
        childMenuChecked?: string;
      };
    }
  | {
      type: 'check';
      payload: {
        orderIdx: number;
        groupCode: string;
        optionCode: string;
        checked: boolean;
        isChild: false;
      };
    }
  | {
      type: 'change-quantity';
      payload: {
        orderIdx: number;
        groupCode: string;
        optionCode: string;
        actionType: ActionChangeQuantityType;
        isChild: true;
        parentCode: string;
        childGroupCodeAndIndex: string;
        childMenuChecked?: string;
        parentQuantity: number;
      };
    }
  | {
      type: 'change-quantity';
      payload: {
        orderIdx: number;
        groupCode: string;
        optionCode: string;
        actionType: ActionChangeQuantityType;
        isChild: false;
      };
    };

const dishReducer = (state: PortionState[], action: ActionType): PortionState[] => {
  let newState: PortionState[] = [];

  switch (action.type) {
    case 'init': {
      return [...action.payload];
    }
    case 'delete-order': {
      return state.filter((_, idx) => idx !== action.payload);
    }
    case 'increase': {
      return [...state, action.payload];
    }
    case 'decrease': {
      return state.length > 0 ? state.slice(0, -1) : state;
    }
    case 'check':
    case 'change-quantity': {
      const { orderIdx, groupCode, optionCode: currentItemCode, isChild } = action.payload;
      const currentDishState = state[orderIdx];
      const currentGroupState = currentDishState[groupCode];
      const currentGroupBasic = currentGroupState.basic;
      const currentGroupDetail = currentGroupState.detail;
      const kousenum = parseInt(currentGroupBasic.kousenum);
      const currentOptionState = currentGroupDetail[currentItemCode];
      const newGroupDetail = { ...currentGroupDetail };
      const currentSelectedArray = currentGroupState.selected;
      const selectedStack = new Set(currentSelectedArray);

      if (isChild) {
        const { parentCode, childGroupCodeAndIndex } = action.payload;
        const childInfor = currentGroupDetail[parentCode].childInfor; //currentDishState

        if (childInfor) {
          const childGroupState = childInfor[childGroupCodeAndIndex]; //currentGroupState
          const currentChildGroupBasic = childGroupState.basic; //currentGroupBasic
          const currentChildGroupDetail = childGroupState.detail; //currentGroupDetail
          const currentChildSelectedArray = childGroupState.selected;
          const kousenum = parseInt(currentChildGroupBasic.kousenum);
          const childSelectedStack = new Set(currentChildSelectedArray);
          const newChildGroupDetail = { ...childGroupState.detail }; //newGroupDetail

          if (action.type === 'check') {
            const { checked } = action.payload;
            // const newChildGroupState = updateInforState(childGroupState, currentItemCode, checked);

            childSelectedStack.clear();
            if (checked) {
              childSelectedStack.add(currentItemCode);
            }

            Object.entries(currentChildGroupDetail).forEach(([optionCode, optionState]) => {
              newChildGroupDetail[optionCode] = handleSelectOne(
                optionCode,
                currentItemCode,
                optionState,
                checked,
                true,
                currentGroupDetail[parentCode]
              );
            });
          } else if (action.type === 'change-quantity') {
            const { actionType, parentQuantity } = action.payload;

            newChildGroupDetail[currentItemCode] = changeQuantityOne(
              currentChildGroupDetail[currentItemCode],
              actionType,
              kousenum,
              true,
              parentQuantity
            );
          }

          const newChildGroupState = {
            ...childGroupState,
            detail: newGroupDetail,
            selected: Array.from(childSelectedStack),
          };
          const newChildInfor = {
            ...childInfor,
            [childGroupCodeAndIndex]: {
              ...newChildGroupState,
              detail: newChildGroupDetail,
            },
          };
          const newGroupDetailState = {
            ...currentGroupDetail,
            [parentCode]: {
              ...currentGroupDetail[parentCode],
              childInfor: newChildInfor,
            },
          };
          const newGroupState = {
            ...currentGroupState,
            detail: newGroupDetailState,
          };

          const newDishState = {
            ...currentDishState,
            [groupCode]: newGroupState,
          };

          newState = state.map((stateItem, idx) => (idx === orderIdx ? newDishState : stateItem));
        }
      } else {
        if (action.type === 'check') {
          const { checked } = action.payload;
          selectedStack.clear();

          if (checked) {
            selectedStack.add(currentItemCode);
          }

          Object.entries(currentGroupDetail).forEach(([optionCode, optionState]) => {
            newGroupDetail[optionCode] = handleSelectOne(optionCode, currentItemCode, optionState, checked, false);
          });
        } else if (action.type === 'change-quantity') {
          const { actionType } = action.payload;

          newGroupDetail[currentItemCode] = changeQuantityOne(currentOptionState, actionType, kousenum, false);
        }

        const newGroupState = {
          ...currentGroupState,
          detail: newGroupDetail,
          selected: Array.from(selectedStack),
        };

        const newDishState = {
          ...currentDishState,
          [groupCode]: newGroupState,
        };

        newState = state.map((stateItem, idx) => (idx === orderIdx ? newDishState : stateItem));
      }

      return newState;
    }
    default:
      newState = { ...state };
      return newState;
  }
};

const changeQuantityOne = (
  currentOptionState: DishMenuGroupDetailValue,
  actionType: ActionChangeQuantityType,
  kousenum: number,
  isChild: boolean,
  parentQuantity?: number
) => {
  if (isChild && parentQuantity) {
    const childQuantity = currentOptionState.quantity;

    const newOptionValue =
      actionType === 'increase'
        ? Math.min(parentQuantity * kousenum, parentQuantity * (childQuantity / parentQuantity + 1))
        : Math.max(parentQuantity, parentQuantity * (childQuantity / parentQuantity - 1));

    return {
      ...currentOptionState,
      quantity: newOptionValue,
    };
  } else {
    const optionQuantity = currentOptionState.quantity;
    const newOptionValue =
      actionType === 'increase' ? Math.min(kousenum, optionQuantity + 1) : Math.max(1, optionQuantity - 1);

    let newOptionState = { ...currentOptionState };

    const childInfo = { ...newOptionState.childInfor };

    if (childInfo) {
      const parentQuantity = newOptionState.quantity;

      Object.entries(childInfo).forEach(([childCodeVersion, childValue]) => {
        const childDetail = { ...childValue.detail };

        Object.entries(childDetail).forEach(([itemCode, itemValue]) => {
          if (itemValue.checked) {
            const childOptionQuantity = itemValue.quantity;

            const newChildOptionValue =
              actionType === 'increase'
                ? Math.min(
                    kousenum * (childOptionQuantity / parentQuantity),
                    (childOptionQuantity / parentQuantity) * (parentQuantity + 1)
                  )
                : Math.max(1, (childOptionQuantity / parentQuantity) * (parentQuantity - 1));

            childDetail[itemCode] = {
              ...itemValue,
              quantity: newChildOptionValue,
            };
          }
        });

        childInfo[childCodeVersion] = {
          ...childValue,
          detail: childDetail,
        };
      });

      newOptionState = {
        ...newOptionState,
        childInfor: childInfo,
      };
    }

    return {
      ...newOptionState,
      quantity: newOptionValue,
    };
  }
};

export default dishReducer;

const updateInforState = (groupState: PortionStateItem, optionCode: string, checked: boolean, isChild: boolean) => {
  const groupDetail = groupState.detail;

  const newGroupDetail = updateGroupDetail(groupDetail, optionCode, checked, isChild);

  const newGroupState = {
    ...groupState,
    detail: newGroupDetail,
    selected: optionCode ? [optionCode] : [],
  };

  return newGroupState;
};

const updateGroupDetail = (
  groupDetail: DishMenuGroupDetail,
  optionCode: string,
  checked: boolean,
  isChild: boolean
) => {
  const newGroupDetail: DishMenuGroupDetail = {};

  for (const [subMenuCode, subMenuState] of Object.entries(groupDetail)) {
    const newOptionState = handleSelectOne(subMenuCode, optionCode, subMenuState, checked, isChild);
    newGroupDetail[subMenuCode] = newOptionState;
  }

  return newGroupDetail;
};

const handleSelectOne = (
  optionCode: string,
  currentItemCode: string,
  optionState: DishMenuGroupDetailValue,
  checked: boolean,
  isChild: boolean,
  parentOptionState?: DishMenuGroupDetailValue
) => {
  const newOptionValue = currentItemCode === optionCode ? checked : false;
  let newOptionState: DishMenuGroupDetailValue = { ...optionState, quantity: 1, checked: newOptionValue };
  if (isChild && parentOptionState) {
    const parentQuantity = parentOptionState.quantity;
    if (newOptionValue) {
      newOptionState = {
        ...newOptionState,
        quantity: optionState.quantity * parentQuantity,
      };
    }
  }

  const childInfo = optionState.childInfor;
  if (childInfo) {
    for (const [childSubMenuCode, childSubMenuState] of Object.entries(childInfo)) {
      const childDefaultSettingFlg = childSubMenuState.basic.default_setting_flg;
      const childSubMenu = childSubMenuState.basic.submenu;
      const childMinDefaultPriority = Math.min(...childSubMenu.map((item) => Number(item.default_priority)));
      const childMinDefaultPriorityItem = childSubMenu.find(
        (item) => item.default_priority === childMinDefaultPriority.toString()
      );
      const childOptionCode = childMinDefaultPriorityItem?.poscode || '';

      const childNewOptionState = updateInforState(
        childSubMenuState,
        childDefaultSettingFlg === '1' ? childOptionCode : '',
        childDefaultSettingFlg === '1',
        isChild
      );

      newOptionState.childInfor = {
        ...newOptionState.childInfor,
        [childSubMenuCode]: childNewOptionState,
      };
    }
  }

  return {
    ...newOptionState,
    checked: newOptionValue,
  };
};
