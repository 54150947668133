import { MenuBookDataType } from 'apis/types';
import { CartStateData } from 'models';
import {
  ALL_YOU_CAN_EAT_PROPERTY,
  MAX_QUANTITY_ORDER_BUFFET_ITEM_IN_CART,
  MAX_QUANTITY_ORDER_ITEM_IN_CART,
  NO_SELECTION_PROPERTY,
  NO_SELECTION_PROPERTY_NUM,
} from '../constants';

export const checkIsExceedAllowQuantityOrder = (
  menubookId: string,
  menuBookList: MenuBookDataType,
  cartState: CartStateData,
  quantity: number,
  customerQuantity: number
): boolean => {
  const totalItemInCart = countTotalItemInCart(cartState);

  if (totalItemInCart + quantity > MAX_QUANTITY_ORDER_ITEM_IN_CART) {
    return true;
  }

  if (isMenuBookBuffet(menubookId, menuBookList)) {
    const totalItemBuffetInCart = countTotalItemBuffetInCart(cartState, menuBookList);
    const maximumOrderQuantityBuffetMenu = getMaximumOrderQuantityBuffetMenu(customerQuantity);

    if (totalItemBuffetInCart + quantity > maximumOrderQuantityBuffetMenu) {
      return true;
    }
  }

  return false;
};

const getMaximumOrderQuantityBuffetMenu = (customerQuantity: number): number => {
  return customerQuantity * 2 >= MAX_QUANTITY_ORDER_BUFFET_ITEM_IN_CART
    ? customerQuantity * 2
    : MAX_QUANTITY_ORDER_BUFFET_ITEM_IN_CART;
};

const countTotalItemInCart = (cartState: CartStateData): number => {
  let total = 0;

  Object.values(cartState).forEach((dishState) => {
    total += dishState.state.length;
  });

  return total;
};

const countTotalItemBuffetInCart = (cartState: CartStateData, menuBookList: MenuBookDataType): number => {
  let total = 0;

  Object.entries(cartState).forEach(([dishKey, dishState]) => {
    const [menubookId] = JSON.parse(dishKey);

    if (isMenuBookBuffet(menubookId, menuBookList)) {
      total += dishState.state.length;
    }
  });

  return total;
};

const isMenuBookBuffet = (menubookId: string, menuBookList: MenuBookDataType): boolean => {
  const menuBook = Object.values(menuBookList).find((menu) => menu.menubook_id === menubookId);
  if (
    menuBook?.property &&
    menuBook?.property.toString() !== NO_SELECTION_PROPERTY &&
    menuBook?.property.toString() !== ALL_YOU_CAN_EAT_PROPERTY
  ) {
    return menuBook?.property !== NO_SELECTION_PROPERTY_NUM;
  } else {
    return menuBook?.property !== NO_SELECTION_PROPERTY;
  }
};
