import { Buffer } from 'buffer';
import { AES, enc, lib } from 'crypto-ts';
import { WordArray } from 'crypto-ts/src/lib/WordArray';
import * as sha256 from 'fast-sha256';
import { DECRYPT_PASSWORD } from '../constants';

export function decrypt(cypherText: string): string {
  let result = '';

  try {
    const pass = DECRYPT_PASSWORD;
    const data = Buffer.from(cypherText, 'base64');
    const salt = data.buffer.slice(0, 16);
    const ct = data.buffer.slice(16);
    const rounds = 3;
    const data00 = Buffer.concat([Buffer.from(pass), new Uint8Array(salt)]);
    const hash = [];
    hash[0] = new sha256.Hash().update(data00).digest();
    let resultBinary = hash[0];

    for (let i = 1; i < rounds; i++) {
      const tmp = Buffer.concat([Buffer.from(hash[i - 1]), new Uint8Array(data00)]);
      hash[i] = new sha256.Hash().update(tmp).digest();
      const mergeHashArr = new Uint8Array(resultBinary.byteLength + hash[i].byteLength);
      mergeHashArr.set(new Uint8Array(resultBinary), 0);
      mergeHashArr.set(new Uint8Array(hash[i]), resultBinary.byteLength);
      resultBinary = mergeHashArr;
    }
    const keyBinary = resultBinary.slice(0, 32);
    const ivBinany = resultBinary.slice(32, 48);
    const key = convertUint8ArrayToWordArray(keyBinary);
    const iv = convertUint8ArrayToWordArray(ivBinany);

    result = AES.decrypt(Buffer.from(ct).toString('base64'), key, {
      iv: iv,
    }).toString(enc.Utf8);
  } catch (e: unknown) {
    console.error(`Error decrypt: ${e}`);
  }
  return result;
}

const convertUint8ArrayToWordArray = (u8Array: Uint8Array): WordArray => {
  const words = [],
    len = u8Array.length;

  let i = 0;

  while (i < len) {
    words.push((u8Array[i++] << 24) | (u8Array[i++] << 16) | (u8Array[i++] << 8) | u8Array[i++]);
  }

  return new lib.WordArray(words, words.length * 4);
};
