export const translationUS = {
  App: {
    language: 'Language',
    max_coupon_num_exceeded: 'The maximum number of coupons that can be used has been exceeded.',
    max_coupon_exceed_limit:
      'You cannot place an order because the number of coupons you can use exceeds the upper limit.',
    agree: 'Yes',
    no: 'No',
    close_modal: 'Close',
    lunch_menu_unavailable: 'Lunch menu is no longer available.',
    invalid_item_exist: 'Invalid item exist in cart',
    app_not_launch: "The app couldn't be started",
    confirm_quit_app: 'I am leaving my smartphone order Is it OK?',
    confirm_delete_item: 'Are you sure you want to delete this item?',
    warning_age_limit:
      'Alcohol is not provided to those under the age of 20, pregnant women, and those who drive a car',
    genre_freq_ordered_menu: 'Frequently Ordered Menu',
    genre_search_menu: 'Search Result',
    max_order_limit_kbn_exceeded: '注文可能数の上限を超えました',
    order_limit_kbn_warning: 'こちらの商品はお１人様１品の注文となります',
    no_select_menu: '	未選択',
  },
  Top: {
    see_all_product_at_this_fair: 'See all products at this campaign',
  },
  Header: {
    freq_ordered_menu: 'Frequently ordered dishes',
    search_menu: 'Search dishes',
    order_history: 'Order history',
    use_counpon: 'Coupon',
    search: 'Search',
  },
  MenuList: {
    call_staff: 'Please call staff to order this',
    lunch_dish_needed: 'You need to order lunch dish first',
    set_dish_needed: 'You need to order set dish first',
    lunch_time_only: 'You can only order this during 10h-17h',
    lunch_time_excluded: 'You cannot order this during 10h-17h',
    search_no_result: 'No results<br />Please check the keywords',
    out_of_stock: 'out of stock',
    end_of_time_frame: '{{timeframeName}}が終了しました。',
    daily_menu_chg_flg: '本日の日替わりメニューは、<br/>別曜日のメニューに変更にな<br/>っております',
  },
  MenuItemDetail: {
    add_to_cart: 'Add to cart',
    delete_portion: 'Delete',
    portion_name: 'Portion',
    please_select_an_item: 'Please select {{quantity}}',
    pay_menu_in_you_can_eat_time_confirm_message:
      'Since menus other than the all-you-can-eat menu are included, additional charges will be incurred.<br />Is it OK?',
    exceed_quantity_warning:
      'You have exceeded the maximum number of orders you can order at one time.<br />Please select again after ordering the items in your cart.',
    select_more_items_message: 'Please choose {1}{0}',
    kind: ' kinds of ',
    not_met_dispcond_5: '{{groupName}}をご注文で<br/>ご利用いただけます',
  },
  OrderHistory: {
    title: 'Order History',
    load_fail: 'Error happened when trying to load order history',
    order_number: 'Order Number',
    order_name: 'Order Name',
    order_type: 'Type',
    quantity: 'Quantity',
    cost: 'Cost',
    status: 'Status',
    re_order: 'Re-order',
    total_price: 'Total Price',
    cancel: 'Cancel',
    confirm_cancel: 'Do you want to cancel the {name}?',
    cancel_success: 'Cancelled successfully',
    cancel_fail: '3 minutes has passed, the order is no longer cancellable',
    currency: '￥',
    tax_included: 'incl.',
    cancel_within_3_m: 'You can cancel this order within 3 minutes',
    cooking: 'Cooking',
    done_preparing: 'Done preparing',
    served: 'Served',
    treasurer: 'Treasurer',
    account_message: 'Thank you for using.<br /><br />Please bring your slip<br />Please pay at the cash register.',
  },
  Cart: {
    title: 'Cart',
    order: 'Order',
    go_back: 'Back to menu',
    turn_back: 'Back',
    quantity: 'Quantity',
    order_success: 'Ordered successfully',
    order_fail: 'Failed to send the order. <br />Please try again later.',
    out_of_stock: 'The following items are out of stock.<br />',
    unordered_data: 'There are unordered data',
    order_menu: 'Order the menu in the cart',
    exceed_coupon_limit:
      'The maximum number of coupons that can be used has been exceeded.<br />Please change the order details.',
  },
  AllYouCanEat: {
    minutes: 'min',
    time_left: 'Time left',
    all_you_can_eat: 'All you can eat',
    all_you_can_drink: 'All you can drink',
    message_near_end_time: '{BBB} is {CCC} minutes to last order.',
    message_one_of_them_over_time: 'it is the end time of {AAA}.<br />{BBB} is {CCC} minutes to last order.',
    message_over_time:
      '{AAA}the order end time has come.<br />We look forward to seeing you again.<br />In addition, we are accepting extensions at a reasonable price.<br />Pleae speak to an employee near you when using the service.',
    menu_not_available: 'Menu is no longer available.',
    warning_paid_menu: '[Charged] Additional charges apply. <br />Are you sure?',
  },
  ThankPage: {
    thank_you_very_much: 'Thank you very much.',
    we_look_forward_to_seeing_you_again: 'We look forward to seeing you again.',
  },
  MessageList: {
    credential_error: 'Processing failed.<br />(Error code: {error_code})',
  },
  CouponForm: {
    headtitle: 'Please enter the coupon number',
    title: 'Number of usage / maximum number of group usage',
    coupon_number: 'Coupon number',
    description1: 'You can use the coupon with one visit.<br/>Up to 2 tickets per person.',
    description2: 'Cannot be used with customer discount coupons.',
    description3: 'Please present the coupon without the number to the employee.',
    cancel: 'Cancel',
    degree: 'OK',
    warn_not_found_coupon_menu:
      'The corresponding coupon does not exist.<br />Please check the coupon code and enter it again.',
    placeholder: 'Please enter the coupon number',
  },
  Pita: {
    minutes: 'min',
    time_left: 'Time left',
    all_you_can_eat: 'All you can eat',
    all_you_can_drink: 'All you can drink',
    message_near_end_time: '{BBB} is {CCC} minutes to last order.',
    message_one_of_them_over_time: 'it is the end time of {AAA}.<br />{BBB} is {CCC} minutes to last order.',
    message_over_time:
      '{AAA}the order end time has come.<br />We look forward to seeing you again.<br />In addition, we are accepting extensions at a reasonable price.<br />Pleae speak to an employee near you when using the service.',
    menu_not_available: 'Menu is no longer available.',
  },
};
