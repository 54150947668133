export function resetGenreNameSticky() {
  // reset status of genre-name
  const subGenreNameSticky = document.getElementById('subgenre-name-sticky');
  subGenreNameSticky?.classList.add('d-none');

  const subGenreNameEntereds = document.querySelectorAll('.subgenre-name.entered');
  subGenreNameEntereds.forEach((subGenreNameEntered) => {
    subGenreNameEntered.classList.remove('entered');
    (subGenreNameEntered as HTMLDivElement).innerHTML =
      (subGenreNameEntered as HTMLDivElement).dataset.subgenrename || '';
  });
}
