import { useAppSelector } from 'app/hooks';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { selectTotalOrder } from 'store/slices/cartSlice';
import { AraCartIcon } from 'theme/icons';
import { delayExecution } from 'utils';
import './CartButton.scss';

const CartButton = () => {
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const totalOrderItem = useAppSelector(selectTotalOrder);

  const handleClick = () => {
    if (totalOrderItem > 0) {
      if (buttonRef.current) {
        if (buttonRef.current.disabled) {
          return;
        }

        buttonRef.current.disabled = true;
      }

      delayExecution(() => {
        playSound('page');
        //! updateMainPageScrollTop();
        navigate('/cart', { replace: true });
      });
    }
  };

  return (
    <div className="cart-button">
      <button ref={buttonRef} onClick={handleClick}>
        {totalOrderItem > 0 && <a className="cart-button-link"></a>}
        <div className="cart-button-wrapper">
          <div className="cart-button-icon">
            <AraCartIcon />
          </div>
          <div className="cart-button-text">{totalOrderItem}</div>
        </div>
      </button>
    </div>
  );
};

export default CartButton;
