import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { TAP_ANIMATION_TIME } from '../../constants';
import './AlertModal.scss';

export interface AlertModalProps {
  title?: string;
  maxWidth?: string;
  message: string;
  onClose?: () => void;
  soundClose?: string;
}

function AlertModal({ title, message, onClose, soundClose = 'close', maxWidth = '30rem' }: AlertModalProps) {
  const { t } = useTranslation();
  const { closeGlobalModal } = useGlobalModal();

  useEffect(() => {
    // action on remove from DOM
    return () => {
      onClose?.();
    };
  }, []);

  const handleClose = () => {
    setTimeout(() => {
      soundClose && playSound(soundClose);
      onClose && onClose();
      closeGlobalModal();
    }, TAP_ANIMATION_TIME / 2);
  };

  return (
    <div className="modal alert-modal" data-bs-backdrop="static" tabIndex={-1} style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: `${maxWidth}` }}>
        <div className="modal-content">
          {title && <div className="modal-header" dangerouslySetInnerHTML={{ __html: title }}></div>}
          <div className="modal-body">
            <div className="modal-message" dangerouslySetInnerHTML={{ __html: message }}></div>
          </div>
          <div className="dialog-footer d-flex justify-content-center">
            <button
              className="ara-btn ara-btn-outline ara-btn-large"
              style={{ minWidth: '10.5rem' }}
              onClick={handleClose}
            >
              <div>{t('App.close_modal')}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

AlertModal.displayName = 'AlertModal';
export default AlertModal;
