import { GenreGroupMenuBookInfoType, SubgenreDataType } from 'apis/types';

export function composeFaireData(
  genre: GenreGroupMenuBookInfoType[],
  subGenre: SubgenreDataType
): { genreId: string; subgenreId: string }[] {
  const faireList: { genreId: string; subgenreId: string }[] = [];

  if (genre) {
    genre?.forEach((genreData) => {
      genreData.subgenre.forEach((subGen) => {
        const subGenreData = subGenre[subGen.subgenre_id];
        const faireData = subGenreData?.faire;
        if (faireData && faireData.faire_flg === '1') {
          faireList.push({ genreId: genreData.genre_id, subgenreId: subGen.subgenre_id });
        }
      });
    });
  }
  return faireList;
}
