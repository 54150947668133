import { GroupOrderBySimilarityReturnedType, PortionState } from 'models';

/**
 * Groups an array of orders by their similarity and returns a count of each unique order.
 *
 * @param orders - An array of orders to be grouped.
 * @returns An object where keys are the stringified versions of the orders and values are the counts of each unique order.
 *
 * @remarks
 * This function uses JSON.stringify to compare orders. It assumes that the orders are serializable.
 *
 * @example
 * ```typescript
 * const orders: PortionState[] = [
 *   { id: 1, name: 'Apple', quantity: 5 },
 *   { id: 2, name: 'Banana', quantity: 3 },
 *   { id: 1, name: 'Apple', quantity: 5 },
 * ];
 *
 * const groupedOrders = groupOrderBySimilarity(orders);
 * console.log(groupedOrders);
 * // Output: { '{"id":1,"name":"Apple","quantity":5}': 2, '{"id":2,"name":"Banana","quantity":3}': 1 }
 * ```
 */
export function groupOrderBySimilarity(orders: PortionState[]): GroupOrderBySimilarityReturnedType {
  const stringOrders = orders.map((order) => JSON.stringify(order));

  const stateQuantityPair: GroupOrderBySimilarityReturnedType = {};

  stringOrders.forEach((order) => {
    if (!(order in stateQuantityPair)) stateQuantityPair[order] = 0;
    stateQuantityPair[order] = stateQuantityPair[order] + 1;
  });

  return stateQuantityPair;
}
