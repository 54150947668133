import { IOrderDetailMenuInfo } from 'apis/types';
import { useTranslation } from 'react-i18next';

interface DishStatusProps {
  dishOrderState: IOrderDetailMenuInfo;
}

const DishStatus = ({ dishOrderState }: DishStatusProps) => {
  const { t } = useTranslation();

  let dishStatus;
  let className = '';

  switch (dishOrderState.Status) {
    case '0':
      dishStatus = t('OrderHistory.cooking');
      className = 'cooking';
      break;
    case '1':
      dishStatus = t('OrderHistory.done_preparing');
      className = 'done-preparing';
      break;
    default:
      dishStatus = t('OrderHistory.served');
      className = 'served';
  }

  return <div className={`order-status order-status-${className}`}>{dishStatus}</div>;
};

export default DishStatus;
