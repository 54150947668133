import { TAP_ANIMATION_TIME } from '../constants';

export function animationRippleClickEvent(this: any, e: MouseEvent) {
  if (e.pageX === 0 && e.pageY === 0) {
    return;
  }

  const pageScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const targetElem = document.elementFromPoint(e.pageX, e.pageY - pageScrollTop);
  const button = targetElem?.closest('.btn-plus, .btn-minus, .ara-radio, .ara-checkbox, .area-turn-back');
  if (button) {
    return;
  }

  const rippleWidth = 80;
  const rippleHeight = 80;

  const positionX = e.pageX - rippleWidth / 2;
  const positionY = e.pageY - pageScrollTop - rippleHeight / 2;

  // Add the element
  const ripple = document.createElement('span');
  ripple.classList.add('ripple');
  const bodyElem = document.getElementsByTagName('BODY')[0];
  bodyElem.append(ripple);

  ripple.style.width = `${rippleWidth}px`;
  ripple.style.height = `${rippleHeight}px`;
  ripple.style.top = `${positionY}px`;
  ripple.style.left = `${positionX}px`;

  ripple.classList.add('rippleAnimation');

  setTimeout(() => {
    bodyElem.removeChild(ripple);
  }, TAP_ANIMATION_TIME);
}
