import { Payload } from 'apis/types';
import i18n from 'i18next';
import { DEFAULT_LANGUAGE } from '../constants';

/**
 * Retrieves the value of a given attribute in the specified language from the provided object.
 *
 * @param obj - The object containing the attribute to retrieve.
 * @param attr - The attribute name to retrieve its value.
 * @returns The value of the attribute in the specified language, or an empty string if the attribute or language is not found.
 *
 * Example:
 * ```typescript
 * const payload: Payload = {
 *   'en': {
 *     title: 'Welcome to our website',
 *     description: 'This is a sample website for demonstration purposes.'
 *   },
 *   'us': {
 *     title: 'Welcome to our website',
 *     description: 'This is a sample website for demonstration purposes.'
 *   },
 *   'fr': {
 *     title: 'Bienvenue sur notre site web',
 *     description: 'Ceci est un site web de démonstration.'
 *   }
 * };
 * const titleInFrench = attrLang(payload, 'title');
 * console.log(titleInFrench);  // Output: 'Bienvenue sur notre site web'
 * ```
 */
export function attrLang(obj: Payload | undefined, attr: string): string {
  if (!obj || !obj[attr]) return '';

  let contentInLang = '';

  contentInLang = obj[attr][i18n.language] ?? obj[attr]['us'] ?? obj[attr][DEFAULT_LANGUAGE];

  return contentInLang ?? '';
}
