import { IMenu, OrderAPIMenuInfoType, OrderAPIOrderInfoType, OrderAPIType } from 'apis/types';
import { CartStateData, DishMenuGroupDetailValue, PortionState } from 'models';
import { getCustomerId, groupOrderBySimilarity } from 'utils';

/**
 * Converts the given cart state data into a format suitable for creating a cart order.
 *
 * @param cartState - The state of the cart, containing the selected dishes and their quantities.
 * @param menuList - The list of available dishes and their details.
 * @returns A cart order object containing the customer ID and the order information.
 */
export const convertCartStateToCartOrder = (cartState: CartStateData, menuList: IMenu): OrderAPIType => {
  const orderInfo: OrderAPIOrderInfoType[] = [];
  let orderIndex = 1;

  Object.entries(cartState).forEach(([dishKey, dishValue]) => {
    const [menuBookId, dishCode] = JSON.parse(dishKey);
    const dishInfo = menuList[dishCode];

    const singleFlg = dishInfo?.single_flg === '1';

    const { state: dishState } = dishValue;

    const uniqueOrders = groupOrderBySimilarity(dishState);

    Object.entries(uniqueOrders).forEach(([orderStateString, itemQuantity]) => {
      const orderState = JSON.parse(orderStateString);

      const createAndPushDishDict = (count: number) => {
        const dishDict = {
          MenuID: orderIndex,
          ModeCode: menuBookId,
          MenuCode: dishCode,
          CountInfo: {
            Count: count,
          },
        };

        const [menuInfo, newOrderIndex] = createMenuInfo(orderState, orderIndex, count);
        orderIndex = newOrderIndex;

        Object.assign(dishDict, { MenuInfo: menuInfo });
        orderInfo.push(dishDict);
      };

      if (singleFlg) {
        for (let i = 0; i < itemQuantity; i++) {
          createAndPushDishDict(1);
        }
      } else {
        createAndPushDishDict(itemQuantity);
      }
    });
  });

  const cartOrder = {
    customer_id: getCustomerId().toString(),
    order_info: orderInfo,
  };

  return cartOrder;
};

const createMenuInfo = (
  orderState: PortionState,
  orderIndex: number,
  itemQuantity: number
): [OrderAPIMenuInfoType[], number] => {
  const menuInfoMap = new Map<string, OrderAPIMenuInfoType>();
  let newOrderIndex = orderIndex + 1;

  Object.entries(orderState).forEach(([groupCodeVersion, groupState]) => {
    const [groupCode] = groupCodeVersion.split('-');
    const groupDetail = groupState.detail;

    Object.entries(groupDetail).forEach(([optionCode, optionState]) => {
      if (optionState.checked) {
        const optionKey = `${groupCode}-${optionCode}`;
        const existingOption = menuInfoMap.get(optionKey);

        if (existingOption) {
          updateExistingOption(menuInfoMap, optionState, optionKey, itemQuantity, newOrderIndex);
        } else {
          const [menuInfoValue, orderIndex] = createOptionInfo(
            optionCode,
            optionState,
            itemQuantity,
            newOrderIndex,
            groupCode
          );

          menuInfoMap.set(optionKey, menuInfoValue);
          newOrderIndex = orderIndex + 1;
        }
      }
    });
  });

  const menuInfo = Array.from(menuInfoMap.values());
  return [menuInfo, newOrderIndex];
};

const createOptionInfo = (
  optionCode: string,
  optionState: DishMenuGroupDetailValue,
  itemQuantity: number,
  orderIndex: number,
  groupCode: string
): [OrderAPIMenuInfoType, number] => {
  const menuInfoValue: OrderAPIMenuInfoType = {
    MenuID: orderIndex,
    MenuCode: optionCode,
    Segment: 'サブメニュー',
    GroupNo: groupCode,
    CountInfo: {
      Count: optionState.quantity * itemQuantity,
    },
  };

  let newOrderIndex = orderIndex;

  const childInfor = optionState.childInfor;

  if (childInfor) {
    const listChildMenuInfo: OrderAPIMenuInfoType[] = [];

    Object.values(childInfor).forEach((childItem) => {
      const groupNo = childItem.basic.cd;

      Object.entries(childItem.detail).forEach(([childOptionCode, childOptionState]) => {
        if (childOptionState.checked) {
          newOrderIndex++;

          const childMenuInfoValue: OrderAPIMenuInfoType = {
            MenuID: newOrderIndex,
            MenuCode: childOptionCode,
            Segment: 'サブメニュー',
            GroupNo: groupNo,
            CountInfo: {
              Count: childOptionState.quantity * itemQuantity,
            },
          };

          listChildMenuInfo.push(childMenuInfoValue);
        }
      });
    });

    menuInfoValue.MenuInfo = listChildMenuInfo;
  }

  return [menuInfoValue, newOrderIndex];
};

const updateExistingOption = (
  menuInfoMap: Map<string, OrderAPIMenuInfoType>,
  optionState: DishMenuGroupDetailValue,
  optionKey: string,
  itemQuantity: number,
  newOrderIndex: number
) => {
  const existingOption = menuInfoMap.get(optionKey);

  if (existingOption) {
    existingOption.CountInfo.Count += optionState.quantity * itemQuantity;
    const childInfor = optionState.childInfor;
    const childMenuInfo = existingOption.MenuInfo;

    if (childInfor && childMenuInfo && childMenuInfo.length > 0) {
      const listChildInfo = Object.entries(childInfor).map(([key, value]) => ({
        groupCodeVersion: key,
        gno: value.basic.cd,
        code: value.selected[0],
      }));

      listChildInfo.forEach((childInfo, index) => {
        const groupGno = childInfo.gno;
        const childCode = childInfo.code;
        const groupCodeVersion = childInfo.groupCodeVersion;

        const isChildOptionExist = childMenuInfo.some((item) => {
          return item.MenuCode === childCode;
        });

        if (isChildOptionExist) {
          const existingChildOption = childMenuInfo[index];

          if (existingChildOption && existingChildOption.CountInfo) {
            existingChildOption.CountInfo.Count += childInfor[groupCodeVersion].detail[childCode].quantity;
          }
        } else {
          const childOptionInfo: OrderAPIMenuInfoType = {
            MenuID: newOrderIndex,
            MenuCode: childCode,
            Segment: 'サブメニュー',
            GroupNo: groupGno,
            CountInfo: {
              Count: childInfor[groupCodeVersion].detail[childCode].quantity,
            },
          };

          childMenuInfo.push(childOptionInfo);
          newOrderIndex++;
        }
      });
    }
  }
};
