import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void, delay: number, isImmediately = false) => {
  const saveCallback = useRef<() => void>();

  useEffect(() => {
    saveCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      if (saveCallback.current) {
        saveCallback.current();
      }
    };

    if (isImmediately) {
      tick();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => {
        clearInterval(id);
      };
    }
  }, [delay, isImmediately]);
};

export default useInterval;
