import { AllYouCanEatType, GenreGroupMenuBookInfoType, IMenu, ITenpoInfo, MenuBookGroupInfoType } from 'apis/types';
import { checkAllYouCanEatMenuOver, checkGenreAvailable, getMenuAllYouCanEat } from 'utils';

export function getFirstGenreIdByMenubookId(
  menubook: MenuBookGroupInfoType,
  menuData: IMenu,
  allYouCanEatState: AllYouCanEatType,
  tenpoInfo?: ITenpoInfo,
  timeFrameLunch?: string[]
): string {
  const currentMenubook =
    menubook?.course && menubook?.course !== null ? getMenuAllYouCanEat(menubook) : menubook.genre;

  if (currentMenubook) {
    let genreGroup = [];
    // filter genre has genres
    genreGroup = currentMenubook.filter((genre: GenreGroupMenuBookInfoType) => {
      let isShow = true;
      if (!menubook.course) {
        isShow = checkGenreAvailable(menubook, genre.genre_id, menuData, tenpoInfo, timeFrameLunch);
      }
      return isShow;
    });
    const genreNotAvailable: string[] = [];
    if (menubook?.course && menubook?.course !== null) {
      genreGroup.forEach((item) => {
        if (checkAllYouCanEatMenuOver(item, allYouCanEatState, menuData)) {
          genreNotAvailable.push(item.genre_id);
        }
      });
    }

    const genreIdAvailables = genreGroup.filter((item) => !genreNotAvailable.includes(item.genre_id));
    return genreIdAvailables[0]?.genre_id || '';
  }

  return '';
}
