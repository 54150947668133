import { getThRemainTm } from 'apis/Api';
import { ThRemainDataType } from 'apis/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import RemainButton from 'components/RemainButton/RemainButton';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import {
  allYouCanEatActions,
  selectAllYouCanEatState,
  selectIsShowRemainAllYouCanEat,
  selectThMenuData,
} from 'store/slices/allYouCanEatSlice';
import { menuInfoActions, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { AraAllDrinkIcon, AraAllEatIcon } from 'theme/icons';
import { getInitMenubookId } from 'utils';
import { GENRE_TOP_MENU_ID, RES_CODE_SUCCESS, TH_INTERVAL_TIME_CHECK_REMAIN_TIME } from '../../constants';
import './scss/AllYouCanEatBoard.scss';

function AllYouCanEatBoard() {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const menuInfo = useAppSelector(selectMenuInfo);
  const isShowRemainAllYouCanEat = useAppSelector(selectIsShowRemainAllYouCanEat);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const thCourseMenu = useAppSelector(selectThMenuData);

  const { showGlobalModal, closeGlobalModal } = useGlobalModal();
  const timeInterval = useRef<NodeJS.Timer | null>(null);

  const [remainTimeValue, setRemainTimeValue] = useState({
    thRemainTime: thCourseMenu?.th_limit,
    nhRemainTime: thCourseMenu?.nh_limit,
  });

  const getMessageNearOverTimeByMilestones = (remainTimeData: ThRemainDataType, milestones: number[]): string => {
    const messages: string[] = [];
    // eat
    if (thCourseMenu?.th_flg === '1' && milestones.includes(remainTimeData.th_remain_tm)) {
      let eatMessage = t('AllYouCanEat.message_near_end_time');
      eatMessage = eatMessage.replace(/{BBB}/g, t('AllYouCanEat.all_you_can_eat'));
      eatMessage = eatMessage.replace(/{CCC}/g, remainTimeData.th_remain_tm + '');
      messages.push(eatMessage);
    }

    // drink
    if (thCourseMenu?.nh_flg === '1' && milestones.includes(remainTimeData.nh_remain_tm)) {
      let drinkMessage = t('AllYouCanEat.message_near_end_time');
      drinkMessage = drinkMessage.replace(/{BBB}/g, t('AllYouCanEat.all_you_can_drink'));
      drinkMessage = drinkMessage.replace(/{CCC}/g, remainTimeData.nh_remain_tm + '');
      messages.push(drinkMessage);
    }

    if (messages.length > 0) {
      return messages.join('<br />');
    }

    return '';
  };

  const checkRemainTime = () => {
    //todo: check remain time was end
    getThRemainTm().then(async (response) => {
      if (response.code !== RES_CODE_SUCCESS) {
        return;
      }

      const data = response.data;
      setRemainTimeValue(() => ({
        thRemainTime: data.th_remain_tm,
        nhRemainTime: data.nh_remain_tm,
      }));

      const isShowEatRemainTime =
        thCourseMenu?.th_flg === '0' ||
        (thCourseMenu?.th_flg === '1' && (data.th_remain_tm === 0 || data.th_sts === '0'))
          ? false
          : true;
      const isShowDrinkRemainTime =
        thCourseMenu?.nh_flg === '0' ||
        (thCourseMenu?.nh_flg === '1' && (data.nh_remain_tm === 0 || data.nh_sts === '0'))
          ? false
          : true;
      const newAllYouCanEatState = {
        isShowEatRemainTime: isShowEatRemainTime,
        isShowDrinkRemainTime: isShowDrinkRemainTime,
      };
      appDispatch(allYouCanEatActions.updateStatus(newAllYouCanEatState));

      // show dialog at 5, 10 minutes
      const messageFirstNear = getMessageNearOverTimeByMilestones(data, [5, 10]);
      if (messageFirstNear != '') {
        closeGlobalModal();
        playSound('confirm');
        showGlobalModal(() => <AlertModal message={messageFirstNear} />);
      }

      if (!isShowEatRemainTime && isShowDrinkRemainTime) {
        //if eat was over, drink still remain
        if ((thCourseMenu?.th_limit ?? 0) > 0) {
          //update th_limit = 0 of thCourseMenu
          let newThCourseMenu = thCourseMenu;
          if (newThCourseMenu) {
            newThCourseMenu = {
              ...thCourseMenu,
              th_limit: 0,
            };

            appDispatch(allYouCanEatActions.setThInfo(newThCourseMenu));
          }

          let message = t('AllYouCanEat.message_one_of_them_over_time');
          message = message.replace(/{AAA}/g, t('AllYouCanEat.all_you_can_eat'));
          message = message.replace(/{BBB}/g, t('AllYouCanEat.all_you_can_drink'));
          message = message.replace(/{CCC}/g, data.nh_remain_tm + '');

          closeGlobalModal();
          playSound('confirm');
          //show message
          showGlobalModal(() => <AlertModal message={message} />);
        }
      } else if (isShowEatRemainTime && !isShowDrinkRemainTime) {
        //if drink was over, eat still remain
        if ((thCourseMenu?.nh_limit ?? 0) > 0) {
          //update nh_limit = 0 of thCourseMenu
          let newThCourseMenu = thCourseMenu;
          if (newThCourseMenu) {
            newThCourseMenu = {
              ...thCourseMenu,
              nh_limit: 0,
            };
            appDispatch(allYouCanEatActions.setThInfo(newThCourseMenu));
          }

          let message = t('AllYouCanEat.message_one_of_them_over_time');
          message = message.replace(/{AAA}/g, t('AllYouCanEat.all_you_can_drink'));
          message = message.replace(/{BBB}/g, t('AllYouCanEat.all_you_can_eat'));
          message = message.replace(/{CCC}/g, data.th_remain_tm + '');
          closeGlobalModal();
          playSound('confirm');
          //show message
          showGlobalModal(() => <AlertModal message={message} />);
        }
      } else if (!isShowEatRemainTime && !isShowDrinkRemainTime) {
        // eat and drink were over
        let message = t('AllYouCanEat.message_over_time');
        if ((thCourseMenu?.th_limit ?? 0) > 0 && (thCourseMenu?.nh_limit ?? 0) > 0) {
          message = message.replace(/{AAA}/g, '');
        } else {
          if (thCourseMenu?.th_limit === 0) {
            message = message.replace(/{AAA}/g, t('AllYouCanEat.all_you_can_drink'));
          }

          if (thCourseMenu?.nh_limit === 0) {
            message = message.replace(/{AAA}/g, t('AllYouCanEat.all_you_can_eat'));
          }
        }
        playSound('confirm');
        closeGlobalModal();
        //show message
        showGlobalModal(() => (
          <AlertModal
            message={message}
            onClose={() => {
              if (menuInfo.menubook.length > 0) {
                const initialMenubookId = getInitMenubookId(menuInfo.menubook, newAllYouCanEatState);
                // save modeCode and categoryCode to SessionStorage and main store
                // sessionStorage.setItem(SESSION_MENUBOOK_BUTTON_CLICKED, 'true');
                appDispatch(
                  menuInfoActions.setCurrentGenreId({
                    selectedMenubookId: initialMenubookId,
                    genreId: GENRE_TOP_MENU_ID,
                  })
                );
              }
            }}
          />
        ));

        //update th_limit = 0, nh_limit = 0 of thCourseMenu
        let newThCourseMenu = thCourseMenu;
        if (newThCourseMenu) {
          newThCourseMenu = {
            ...thCourseMenu,
            th_limit: 0,
            nh_limit: 0,
          };
          appDispatch(allYouCanEatActions.setThInfo(newThCourseMenu));
        }
      }
    });
  };

  useEffect(() => {
    timeInterval.current = setInterval(() => {
      isShowRemainAllYouCanEat && checkRemainTime();
    }, TH_INTERVAL_TIME_CHECK_REMAIN_TIME);

    return () => {
      clearInterval(timeInterval.current as NodeJS.Timer);
    };
  }, []);

  useEffect(() => {
    isShowRemainAllYouCanEat && checkRemainTime();
    return () => {
      setRemainTimeValue({
        thRemainTime: thCourseMenu?.th_limit,
        nhRemainTime: thCourseMenu?.nh_limit,
      });
    };
  }, []);

  return (
    <div className="remain-button-list">
      {allYouCanEatState.isShowEatRemainTime && (remainTimeValue.thRemainTime ?? 0) > 0 && (
        <div className="remain-button-item">
          <RemainButton
            text={t('AllYouCanEat.all_you_can_eat')}
            value={remainTimeValue.thRemainTime}
            icon={<AraAllEatIcon size={36} />}
          />
        </div>
      )}

      {allYouCanEatState.isShowDrinkRemainTime && (remainTimeValue.nhRemainTime ?? 0) > 0 && (
        <div className="remain-button-item">
          <RemainButton
            text={t('AllYouCanEat.all_you_can_drink')}
            value={remainTimeValue.nhRemainTime}
            icon={<AraAllDrinkIcon size={36} />}
          />
        </div>
      )}
    </div>
  );
}

export default AllYouCanEatBoard;
