/**
 * Retrieves the value of a query parameter from the current URL's query string.
 *
 * @param {string} variable - The name of the query parameter to retrieve.
 * @returns {string | false} The value of the specified query parameter, or `false` if the parameter is not found.
 */
export function getQueryParam(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}
