import { useTranslation } from 'react-i18next';
import './scss/RemainButton.scss';

export type RemainButtonProps = {
  text?: string;
  value?: number | string;
  icon: JSX.Element;
};

function RemainButton({ text = '', value = 0, icon }: RemainButtonProps) {
  const { t } = useTranslation();

  return (
    <div className="remain-button">
      <div className="remain-button-wrapper">
        <div className="remain-button-icon">{icon}</div>
        <div className="remain-button-text d-flex flex-column justify-content-center">
          <div className="remain-button-sub-text">{text}</div>
          <div>
            {t('AllYouCanEat.time_left')}
            <span className="remain-button-value">{value}</span>
            {t('AllYouCanEat.minutes')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemainButton;
