import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  HistoryFlagType,
  IBarInfo,
  IBarMenu,
  ICouponInfo,
  ICustomerInfo,
  IDispCondGroup,
  IDispCondMenu,
  IEatingWayItem,
  IEatingWaySelect,
  IMenu,
  IMovieInfo,
  IOrder,
  IOrderHistory,
  IOrderHistoryResult,
  IQRInfo,
  IReceptionInfo,
  IServiceInfo,
  IStartData,
  ISubMenuGroup,
  ITenpoInfo,
} from 'apis/types';
import { RootState } from 'app/store';
import { checkObjectNotEmpty, SESSION_EATINGWAY_ID, SESSION_TENPO_INFO, SessionStorage } from 'utils';
import { checkHistoryFlag } from 'utils/checkHistoryFlag';

interface MainState {
  loading: boolean;
  setupEatingWayLoading: boolean;
  tenpoInfo: ITenpoInfo[];
  eatingWaySelect: IEatingWaySelect;
  barInfo: IBarInfo;
  barMenu: IBarMenu;
  serviceInfo: IServiceInfo;
  couponInfo: ICouponInfo;
  qrInfo: IQRInfo;
  orderList: IOrder[];
  receptionInfo: IReceptionInfo;
  customerInfo: ICustomerInfo;
  outOfStockItems: string[]; // shinagire
  menu: IMenu;
  subMenuGroup: ISubMenuGroup;
  movieInfo: IMovieInfo[];

  dispcondGroup: IDispCondGroup;
  dispcondMenu: IDispCondMenu;

  shabushabuMenu: string[];
  sukiyakiMenu: string[];
  dashiMenu: string[];
  historyFlag: HistoryFlagType;

  isOrderHistoryLoading: boolean;
  orderHistory: IOrderHistoryResult;
  eatingWayId: IEatingWayItem;
  isShowCartModal: boolean;
  eatingWayDefault: string;
}

const initialState: MainState = {
  loading: true,
  setupEatingWayLoading: true,
  tenpoInfo: [],
  eatingWaySelect: {
    layout: {},
    eating_way: {},
    menubook_course_introduction: {},
  },
  barInfo: {},
  barMenu: {},
  serviceInfo: {
    service_menu: [],
  },
  couponInfo: {
    coupon_menu: [],
  },
  qrInfo: {
    tableno: '',
    denpyouno: '',
    qr: '',
    introduction: {},
  },
  orderList: [],
  receptionInfo: {
    haveadult: '',
    start_tm_hh_mm: '',
  },
  customerInfo: {
    freq_menu: [],
    fav_menu: [],
    order_list: [],
    no_order_list: [],
  },
  outOfStockItems: [],
  menu: {},
  subMenuGroup: {},
  movieInfo: [],
  dispcondGroup: {},
  dispcondMenu: {},
  shabushabuMenu: [],
  sukiyakiMenu: [],
  dashiMenu: [],
  historyFlag: {
    setmenu_flg: '0',
    lunch_flg: '0',
  },
  isOrderHistoryLoading: false,
  orderHistory: {
    OrderHeader: {
      GuestNum: '',
      TotalAmount: '',
    },
    OrderDetail: [],
  },
  eatingWayId: {
    eating_way_id: '',
    name: '',
    property: '選択なし',
    menubook_course: [],
  },
  isShowCartModal: false,
  eatingWayDefault: '',
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setSetupEatingWayDone(state) {
      state.setupEatingWayLoading = false;
    },
    fetchStartData(state) {
      state.loading = true;
      state.setupEatingWayLoading = true;
    },
    fetchStartDataSuccess(state, action: PayloadAction<IStartData>) {
      state.loading = false;

      state.tenpoInfo = action.payload.data.tenpo_info;
      SessionStorage.setItem(SESSION_TENPO_INFO, JSON.stringify(state.tenpoInfo));
      state.eatingWaySelect = action.payload.data.eating_way_select;
      state.barInfo = action.payload.data.bar_info;
      state.barMenu = action.payload.data.bar_menu;
      state.serviceInfo = action.payload.data.service_info;
      state.couponInfo = action.payload.data.coupon_info;
      state.qrInfo = action.payload.data.qr_info;
      state.receptionInfo = action.payload.data.reception_info;
      state.customerInfo = action.payload.data.customer_info;
      state.outOfStockItems = action.payload.data.shinagire;
      state.menu = action.payload.data.menu;
      state.subMenuGroup = action.payload.data.submenugroup;
      state.movieInfo = action.payload.data.movie_info;
      state.dispcondGroup = action.payload.data.dispcond_group;
      state.dispcondMenu = action.payload.data.dispcond_menu;
      state.shabushabuMenu = action.payload.data.shabushabu_menu;
      state.sukiyakiMenu = action.payload.data.sukiyaki_menu;
      state.dashiMenu = action.payload.data.dashi_menu;
      state.orderList = action.payload.data.order_list;
      if (sessionStorage.getItem(SESSION_EATINGWAY_ID)) {
        const currentEating = JSON.parse(sessionStorage.getItem(SESSION_EATINGWAY_ID) ?? '');
        state.eatingWayId = currentEating;
      }
    },
    setEatingWayId(state, action: PayloadAction<IEatingWayItem>) {
      state.eatingWayId = action.payload;
      sessionStorage.setItem(SESSION_EATINGWAY_ID, JSON.stringify(state.eatingWayId));
    },
    fetchStartDataFailure(state) {
      state.loading = false;
    },
    fetchOrderHistoryData(state) {
      state.isOrderHistoryLoading = true;
    },
    fetchOrderHistoryDataSuccess(state, action: PayloadAction<IOrderHistory>) {
      state.isOrderHistoryLoading = false;
      state.orderHistory = action.payload.result;
      if (checkObjectNotEmpty(state.menu) && checkObjectNotEmpty(state.orderHistory.OrderDetail)) {
        state.historyFlag = checkHistoryFlag(state.menu, state.orderHistory.OrderDetail);
      }
    },
    fetchOrderHistoryDataFailed(state) {
      state.isOrderHistoryLoading = false;
    },
    setShowCartModal(state, action: PayloadAction<boolean>) {
      state.isShowCartModal = action.payload;
    },
    setOutOfStockItems(state, action: PayloadAction<string[]>) {
      state.outOfStockItems = Array.from(new Set([...state.outOfStockItems, ...action.payload]));
    },
    setEatingwayDefault(state, action: PayloadAction<string>) {
      state.eatingWayDefault = action.payload;
    },
  },
});

export const mainReducer = mainSlice.reducer;
export const mainActions = mainSlice.actions;

export const selectMainLoading = (state: RootState) => state.main.loading;
export const selectTenpoInfo = (state: RootState) => state.main.tenpoInfo[0];
export const selectEatingWaySelect = (state: RootState) => state.main.eatingWaySelect;
export const selectBarInfo = (state: RootState) => state.main.barInfo;
export const selectSetupEatingWayLoading = (state: RootState) => state.main.setupEatingWayLoading;
export const selectBarMenu = (state: RootState) => state.main.barMenu;
export const selectServiceInfo = (state: RootState) => state.main.serviceInfo;
export const selectCouponInfo = (state: RootState) => state.main.couponInfo;
export const selectQRInfo = (state: RootState) => state.main.qrInfo;
export const selectOrderList = (state: RootState) => state.main.orderList;
export const selectReceptionInfo = (state: RootState) => state.main.receptionInfo;
export const selectHaveAdult = (state: RootState) => state.main.receptionInfo.haveadult;
export const selectCustomerInfo = (state: RootState) => state.main.customerInfo;
export const selectOutOfStockItems = (state: RootState) => state.main.outOfStockItems;
export const selectMenu = (state: RootState) => state.main.menu;
export const selectSubMenuGroup = (state: RootState) => state.main.subMenuGroup;
export const selectMovieInfo = (state: RootState) => state.main.movieInfo;
export const selectDispcondGroup = (state: RootState) => state.main.dispcondGroup;
export const selectDispcondMenu = (state: RootState) => state.main.dispcondMenu;
export const selectShabushabuMenu = (state: RootState) => state.main.shabushabuMenu;
export const selectSukiyakiMenu = (state: RootState) => state.main.sukiyakiMenu;
export const selectDashiMenu = (state: RootState) => state.main.dashiMenu;

export const selectHistoryFlag = (state: RootState) => state.main.historyFlag;
export const selectOrderHistoryData = (state: RootState) => state.main.orderHistory;
export const selectEatingWayId = (state: RootState) => state.main.eatingWayId;
export const selectIsShowCartModal = (state: RootState) => state.main.isShowCartModal;
export const selectTimeFrameLunch = createSelector(selectTenpoInfo, (tenpoInfo: ITenpoInfo) => {
  const dataResult: string[] = [];
  if (tenpoInfo) {
    tenpoInfo.time_frame.forEach((info) => {
      if (info.property === '1' || info.property === '0') {
        if (info.property === '1') {
          dataResult.push(info.time_frame_id);
        }
      } else {
        if (info.property !== '') {
          dataResult.push(info.time_frame_id);
        }
      }
    });
  }
  return dataResult;
});
