import { getCustomerId } from 'utils';
import { RES_CODE_UNAUTHORIZED, TH_INFO_EMPTY_INFO_CODE } from '../constants';
import axiosClient from './axiosClient';
import {
  CartInfoType,
  ICallStaff,
  ICustomerQuantity,
  IOrderHistory,
  IServerTime,
  ISlipStatus,
  IStartData,
  MenuInfoResponse,
  OrderAPIRes,
  OrderAPIType,
  Payload,
  ThInfoType,
  ThRemainType,
  UpdateOrderListRes,
} from './types';

export const imgDB = process.env.REACT_APP_S3_ENDPOINT;
const THANKS_PAGE_URL = '/thanks';

export const topMenuGenre: Payload = {
  '0': {
    categorycd: '0',
    lang: {
      jp: 'TOP',
      us: 'TOP',
      cn: 'TOP',
      kr: 'TOP',
      vn: 'TOP',
    },
    img: { jp: null },
  },
};

export const getStartData = async (): Promise<IStartData> => {
  const response = await axiosClient({
    method: 'post',
    url: 'start.php',
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
};

export const getCustomerQuantity = async (): Promise<ICustomerQuantity> => {
  const response = await axiosClient({
    method: 'post',
    url: 'get_customer_num.php',
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
};

export const callStaff = async (serviceRequiredItems: string[]): Promise<ICallStaff> => {
  const response = await axiosClient({
    method: 'post',
    url: 'call_staff.php',
    data: JSON.stringify({
      customer_id: getCustomerId(),
      callkbn: '05',
      poscds: serviceRequiredItems,
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
};

export const getServerTime = async (): Promise<IServerTime> => {
  const response = await axiosClient({
    method: 'post',
    url: 'get_time.php',
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
};

export const checkUserCheckout = async (): Promise<ISlipStatus> => {
  const response = await axiosClient({
    method: 'post',
    url: 'chk_slip_status.php',
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
};

export async function getOrderHistory(): Promise<IOrderHistory> {
  const response = await axiosClient({
    method: 'post',
    url: `get_order_history.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
}

export async function updateCartInfo(orderList: CartInfoType[]): Promise<UpdateOrderListRes> {
  const response = await axiosClient({
    method: 'post',
    url: `upd_orderlist.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
      order_list: orderList,
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
}

export async function getThInfo(): Promise<ThInfoType> {
  const response = await axiosClient({
    method: 'post',
    url: `get_th_info.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
    return {
      code: '',
      msg: '',
      data: {
        course_cd: '',
        order_limit_kbn: '',
        th_flg: '',
        th_limit: 0,
        nh_flg: '',
        nh_limit: 0,
      },
    };
  }

  if (response.data.code === TH_INFO_EMPTY_INFO_CODE) {
    response.data.data = {};
  }

  return response.data;
}

export async function getThRemainTm(): Promise<ThRemainType> {
  const response = await axiosClient({
    method: 'post',
    url: `get_th_remain_tm.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
    }),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
}

export async function sendOrder(orderInfo: OrderAPIType): Promise<OrderAPIRes> {
  const response = await axiosClient({
    method: 'post',
    url: `order.php`,
    data: JSON.stringify(orderInfo),
  });

  if (response.data.code === RES_CODE_UNAUTHORIZED) {
    window.location.href = THANKS_PAGE_URL;
  }

  return response.data;
}

export async function getMenuInfo(eatingWay: number, courseCd: number): Promise<MenuInfoResponse> {
  const response = await axiosClient({
    method: 'post',
    url: `get_menu_info.php`,
    data: JSON.stringify({
      customer_id: getCustomerId(),
      eating_way_id: eatingWay,
      course_cd: courseCd,
    }),
  });

  return response.data;
}
