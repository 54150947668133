import { MenuBookGroupInfoType } from 'apis/types';
import { checkMenuBookCanShow } from './checkMenuBookCanShow';

export function composeMenubookList(
  currentTimeFrame: string,
  menubooks: MenuBookGroupInfoType[]
): MenuBookGroupInfoType[] {
  const menubooksGroup: MenuBookGroupInfoType[] = [];

  menubooks.forEach((item) => {
    if (item.time_frame_id) {
      item.time_frame_id.some((timeFrame) => timeFrame === currentTimeFrame) && menubooksGroup.push(item);
    } else {
      checkMenuBookCanShow(currentTimeFrame, item) && menubooksGroup.push(item);
    }
  });

  return menubooksGroup;
}
