import { createContext } from 'react';

export interface DishModalContextProps {
  showDishModal?: (dishCode: string) => void;
  showCouponDishModal?: (dishCode: string, menubookId: string) => void;
  showCouponFormModal?: () => void;
}

export const DishModalContext = createContext<DishModalContextProps>({});
DishModalContext.displayName = 'DishModalContext';
