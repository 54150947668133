// import {
//   log,
//   LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION,
//   LOG_OP_TYPE_TOP_MENU_SCREEN_THUMBNAIL_IMAGE_SELECTION,
// } from 'log/Log';
import { useTranslation } from 'react-i18next';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { playSound } from 'sound/Sound';
import { Swiper, SwiperSlide } from 'swiper/react';
import './scss/Top.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/pagination';
// import required modules
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Autoplay, Lazy, Navigation, Pagination, Swiper as SwiperCore } from 'swiper';

import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { menuInfoActions, selectedMenubookId, selectFaire, selectSubGenre } from 'store/slices/menuInfoSlice';
import { imgFullPath, scrollAnimationTo } from 'utils';
import { attrLang } from 'utils/attrLang';
import { CAMPAIGN_BANNER_TRANSITION_TIME, TAP_ANIMATION_TIME } from '../../constants';
import { DetailSetProps } from './types';

export interface TopProps {
  currentGenreIds: string[];
  mainSwiper?: SwiperCore;
}
useGlobalModal;
export default function Top({ currentGenreIds, mainSwiper }: TopProps) {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();

  const currentMenubookId = useAppSelector(selectedMenubookId);
  const subGenreData = useAppSelector(selectSubGenre);
  const faireData = useAppSelector(selectFaire);

  // const campaignCodes: string[] = modeInfo.campaign;
  const [currentFaireCode, setCurrentFaireCode] = useState(faireData[0]);
  // set campaign code when change mode
  useEffect(() => {
    setCurrentFaireCode(faireData[0]);
  }, [faireData]);

  // const campaignData = sourceMenuInfo?.campaign;

  // const genreData = menuInfo.genre;
  let currentFaire;
  let currentGenreName = '';
  if (faireData.length > 0) {
    currentFaire = subGenreData[currentFaireCode?.subgenreId];
    currentGenreName = attrLang(subGenreData[currentFaire?.subgenre_id], 'lang');
  }

  // useEffect(() => {
  //   log({
  //     op_type: LOG_OP_TYPE_TOP_MENU_SCREEN_THUMBNAIL_IMAGE_SELECTION,
  //     op_detail: {
  //       mode: currentModeCode,
  //       campaign: currentCampaignCode,
  //     },
  //   });
  // }, [currentCampaignCode, currentModeCode]);

  const handleViewAll = () => {
    // appDispatch(menuInfoActions.setCurrentCategoryCode(currentCampaign?.categorycd));
    playSound('page');
    // log({
    //   op_type: LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION,
    //   op_detail: {
    //     mode: currentModeCode,
    //     campaign: currentCampaignCode,
    //   },
    // });
  };

  const ListOfCampaignThumbnails: JSX.Element[] = [];
  faireData.forEach((faire) => {
    const campaignInfo = subGenreData[faire.subgenreId];
    const imgSrc: string = imgFullPath(campaignInfo.faire?.img_sp);
    if (imgSrc !== '') {
      const handleClick = async () => {
        setTimeout(() => {
          playSound('page');
          setCurrentFaireCode(faire);

          setTimeout(() => {
            if (faire.genreId !== '') {
              const genreMenuSection = document.getElementById(`subgenre-menu-${faire.subgenreId}`) as HTMLDivElement;

              if (genreMenuSection) {
                mainSwiper?.slideTo(currentGenreIds.indexOf(faire.genreId), 0, false);
                const scrolltopElem = genreMenuSection.offsetTop - genreMenuSection.scrollTop;
                scrollAnimationTo(
                  document.querySelector('.page-wrapper') as HTMLElement,
                  scrolltopElem > 0 ? scrolltopElem + 36 : 0,
                  600
                );
              } else {
                return;
              }

              appDispatch(menuInfoActions.updateCampaignGenrecd(''));
            }
          }, 300);

          // log({
          //   op_type: LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION,
          //   op_detail: {
          //     mode: currentModeCode,
          //     campaign: currentCampaign?.categorycd,
          //     genrecd: currentCampaign?.genrecd,
          //   },
          // });
          appDispatch(
            menuInfoActions.updateCampaign({
              modeCode: currentMenubookId,
              categorycd: faire.genreId,
              subGenreId: faire.subgenreId,
            })
          );
          appDispatch(
            menuInfoActions.setCurrentGenreId({ selectedMenubookId: currentMenubookId, genreId: faire.genreId })
          );
        }, TAP_ANIMATION_TIME / 2);
      };
      ListOfCampaignThumbnails.push(
        <SwiperSlide key={`campaign-thumb-${faire.subgenreId}`} className="campaign-thumb" onClick={handleClick}>
          <img
            data-src={imgSrc}
            src="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='600' height='400'><rect style='fill:rgb(255,255,255);' width='100%' height='100%' /></svg>"
            className="swiper-lazy campaign-thumb-image"
          />
          <div className="swiper-lazy-preloader"></div>
        </SwiperSlide>
      );
    }
  });

  return (
    <div id="top-menu">
      {ListOfCampaignThumbnails.length > 0 && (
        <Swiper
          autoHeight={false}
          preloadImages={true}
          loop={ListOfCampaignThumbnails.length !== 1}
          lazy={true}
          pagination={{
            clickable: false,
          }}
          slidesPerView={1}
          spaceBetween={8}
          navigation={false}
          autoplay={{ delay: CAMPAIGN_BANNER_TRANSITION_TIME }}
          modules={[Lazy, Pagination, Navigation, Autoplay]}
          className="campaign-swiper"
          nested
        >
          {ListOfCampaignThumbnails}
        </Swiper>
      )}
      {currentFaireCode && currentFaire && (
        <div id="detail-campaign-block" className="d-none">
          <DetailCampaign campaignInfo={currentFaire} name={currentGenreName} />
          {currentFaireCode && (
            <div className="see-all-wrapper">
              <button id="see-all" type="button" className="btn btn-dark rounded-0" onClick={handleViewAll}>
                {t('Top.see_all_product_at_this_fair')}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function DetailCampaign({ campaignInfo, name }: DetailSetProps) {
  const img_src = imgFullPath(campaignInfo?.img);

  if (img_src !== '') {
    return (
      <div>
        {name != '' && <div id="campaign-name">{name}</div>}
        <div id="campaign-image-block">
          <LazyLoadImage wrapperClassName="d-block" alt="campaign_detail_img" effect="opacity" src={img_src} />
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
