import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import rootSaga from 'app/rootSaga';
import createSagaMiddleware from 'redux-saga';
import { allYouCanEatReducer } from 'store/slices/allYouCanEatSlice';
import { cartReducer } from 'store/slices/cartSlice';
import { customerQuantityReducer } from 'store/slices/customerQuantitySlice';
import { errorNotificationReducer } from 'store/slices/errorNotificationSlice';
import { mainReducer } from 'store/slices/mainSlice';
import { menuInfoReducer } from 'store/slices/menuInfoSlice';
import { timeReducer } from 'store/slices/timeSlice';

const rootReducer = combineReducers({
  main: mainReducer,
  errorNotification: errorNotificationReducer,
  customerQuantity: customerQuantityReducer,
  allYouCanEat: allYouCanEatReducer,
  cart: cartReducer,
  time: timeReducer,
  menuInfo: menuInfoReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false, immutableCheck: true }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
