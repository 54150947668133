import { DishMenuGroupDetailValue, ICompactOrderState, PortionState } from 'models';

/**
 * Summarizes the given order state into a compact format.
 *
 * @param state - The order state to be summarized.
 * @returns A compact representation of the order state.
 */
export const summarizeOrderState = (state: PortionState): ICompactOrderState => {
  const orderContent: ICompactOrderState = {};

  const processOptionState = (
    groupCodeVersion_optionCode: string,
    optionState: DishMenuGroupDetailValue,
    childContent?: ICompactOrderState
  ) => {
    const currentContent = childContent || orderContent;

    currentContent[groupCodeVersion_optionCode] = {
      optionInfo: optionState.optionInfo,
      quantity: optionState.quantity,
      ...(optionState.childInfor ? { childInfor: {} } : {}),
    };

    const childInfor = optionState.childInfor;

    if (childInfor) {
      const childContent = currentContent[groupCodeVersion_optionCode]?.childInfor;

      if (childContent) {
        Object.entries(childInfor).forEach(([childGroupCodeVersion, childGroupState]) => {
          const childGroupDetail = childGroupState.detail;

          Object.entries(childGroupDetail).forEach(([childOptionCode, childOptionState]) => {
            if (childOptionState.checked) {
              processOptionState(`${childGroupCodeVersion}_${childOptionCode}`, childOptionState, childContent);
            }
          });
        });
      }
    }
  };

  Object.entries(state).forEach(([groupCodeVersion, groupState]) => {
    const groupDetail = groupState.detail;

    Object.entries(groupDetail).forEach(([optionCode, optionState]) => {
      if (optionState.checked) {
        processOptionState(`${groupCodeVersion}_${optionCode}`, optionState);
      }
    });
  });

  return orderContent;
};
