import { TAP_ANIMATION_TIME } from '../constants';

/**
 * Delays the execution of a callback function by a specified delay.
 *
 * @param callback - The function to be executed after the delay.
 * @param delay - The time in milliseconds to delay the execution of the callback. Defaults to half of the TAP_ANIMATION_TIME / 2.
 */
export const delayExecution = (callback: () => void, delay: number = TAP_ANIMATION_TIME / 2) => {
  setTimeout(callback, delay);
};
