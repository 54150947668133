import { topMenuGenre } from 'apis/Api';
import { useAppSelector } from 'app/hooks';
import CartButton from 'components/CartButton';
import Genre from 'components/Genre/Genre';
import GenreButton from 'components/GenreButton/GenreButton';
import HistoryButton from 'components/HistoryButton';
import MenubookBar from 'components/MenubookBar/MenubookBar';
import MenuList from 'components/MenuList/MenuList';
import StickyContainer from 'components/StickyContainer/StickyContainer';
import Top from 'components/Top/Top';
import useUpdateTimeFrame from 'hooks/useUpdateTimeFrame';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { playSound } from 'sound/Sound';
import { selectAllYouCanEatState } from 'store/slices/allYouCanEatSlice';
import { selectMenu, selectTenpoInfo, selectTimeFrameLunch } from 'store/slices/mainSlice';
import {
  menuInfoActions,
  selectCurrentGenreId,
  selectedMenubookId,
  selectFaireCd,
  selectGenre,
  selectMenuInfo,
} from 'store/slices/menuInfoSlice';
import SwiperCore, { Lazy, Thumbs, Virtual } from 'swiper';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import i18n from 'translation/i18n';
import {
  checkAllYouCanEatMenuOver,
  checkGenreAvailable,
  getMenuAllYouCanEat,
  resetGenreNameSticky,
  scrollAnimationTo,
  SESSION_MAIN_PAGE_LOADED,
  SESSION_MENU_CONTENT_BODY_SCROLLTOP,
  SessionStorage,
} from 'utils';
import { attrLang } from 'utils/attrLang';
import {
  CATEGORY_SEARCH_MENU_ID,
  GENRE_STICKY_NAME_HEIGHT_DEFAULT,
  GENRE_TOP_MENU_ID,
  SEARCH_MENU_MENUBOOKID,
} from '../../constants';
import './MenuSwipeList.scss';

export interface MenuSwipeListProps {
  menubookId?: string;
}

const MenuSwipeList = () => {
  useUpdateTimeFrame();

  const dispatch = useDispatch();
  const [mainSwiper, setMainSwiper] = useState<SwiperCore>();
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>();

  const currentGenreId = useAppSelector(selectCurrentGenreId);
  const currentMenubookId = useAppSelector(selectedMenubookId);
  const menuInfo = useAppSelector(selectMenuInfo);
  const menuData = useAppSelector(selectMenu);
  const tenpoInfo = useAppSelector(selectTenpoInfo);
  const genreData = useAppSelector(selectGenre);
  const faireCd = useAppSelector(selectFaireCd);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const timeFrameLunch = useAppSelector(selectTimeFrameLunch);
  const currentMenubook = menuInfo.menubook.find((item) => item.menubook_id === currentMenubookId);
  const [isSearchMode, setIsSearchMode] = useState(currentGenreId === CATEGORY_SEARCH_MENU_ID);
  const appendedGenreData = {
    ...genreData,
    ...topMenuGenre,
  };

  let currentGenreIds: string[] = [];
  if (currentGenreId !== SEARCH_MENU_MENUBOOKID && currentMenubook) {
    const genreGroup =
      currentMenubook?.course && currentMenubook.course != null
        ? getMenuAllYouCanEat(currentMenubook)
        : currentMenubook.genre;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const availableGenreId = genreGroup!.filter((genre) => {
      let isShow = true;
      isShow = checkGenreAvailable(currentMenubook, genre.genre_id, menuData, tenpoInfo, timeFrameLunch);

      return isShow;
    });

    currentGenreIds = [GENRE_TOP_MENU_ID].concat(
      availableGenreId.map((genre) => {
        return genre.genre_id;
      })
    );
  }
  function resetStatusTopCategoryBar(genreId: string) {
    const currentCategoryButton = document.querySelector(`#genre-btn-${genreId}`);
    if (!currentCategoryButton?.parentElement?.classList.contains('disabled')) {
      //set thumb active
      const categoryButtonActive = document.querySelector('#genre-button-block .genre-button.active');
      if (categoryButtonActive) {
        categoryButtonActive.classList.remove('active');
      }

      currentCategoryButton?.classList.add('active');
    }
  }
  const genreNotAvailable: string[] = [];
  currentGenreIds.forEach((genreId) => {
    if (currentMenubook?.course && currentMenubook.course != null) {
      if (
        checkAllYouCanEatMenuOver(
          getMenuAllYouCanEat(currentMenubook).find((item) => item.genre_id === genreId) ??
            getMenuAllYouCanEat(currentMenubook)[0],
          allYouCanEatState,
          menuData
        ) &&
        genreId !== GENRE_TOP_MENU_ID
      ) {
        genreNotAvailable.push(genreId);
      }
    }
  });
  let ListOfGenre: (JSX.Element | null)[] = [];
  ListOfGenre = currentGenreIds.map((genreId, index) => {
    const genreName = attrLang(appendedGenreData[genreId], 'lang');
    const categoryButton: JSX.Element | null =
      appendedGenreData[genreId] !== undefined ? (
        <SwiperSlide
          key={`CategoryButton-${currentMenubookId}-${genreId}-${index}`}
          style={{ width: 'auto' }}
          className={genreNotAvailable.includes(genreId) ? 'disabled' : ''}
          onClick={() => {
            (document.querySelector('.form-search-close-hide') as HTMLElement)?.click();
          }}
        >
          <GenreButton menubookId={currentMenubookId} genreId={genreId} genreName={genreName} />
        </SwiperSlide>
      ) : null;

    return categoryButton;
  });
  const currentGenreIdAvailables = currentGenreIds.filter((x) => !genreNotAvailable.includes(x));
  const mainInitialSlideIndex =
    currentGenreIdAvailables.indexOf(currentGenreId) >= 0 ? currentGenreIdAvailables.indexOf(currentGenreId) : 0;
  const menuListSwipeProps: SwiperProps = {
    initialSlide: mainInitialSlideIndex >= 0 ? mainInitialSlideIndex : 0,
    autoHeight: true,
    spaceBetween: 0,
    navigation: false,
    lazy: true,
    modules: [Lazy, Virtual],
    virtual: true,
    // thumbs: { swiper: thumbsSwiper },
    onSwiper: setMainSwiper,
    onUpdate: () => {
      setTimeout(() => {
        const modeThumbButtons = document.querySelectorAll('.menubook-thumb-button');
        if (modeThumbButtons) {
          modeThumbButtons.forEach((modeThumbButton) => {
            (modeThumbButton as HTMLButtonElement).disabled = false;
          });
        }
      }, 300);
    },
    onSlideChange: (swiper) => {
      setTimeout(() => {
        const categoryThumbnail = document.querySelector('.genre-thumbnail-' + swiper.activeIndex);
        if (categoryThumbnail) {
          (categoryThumbnail as HTMLButtonElement).disabled = false;
        }
      }, 300);

      const genreIdActive = currentGenreIdAvailables[swiper.activeIndex];
      const thumbIndex = currentGenreIds.findIndex((x) => x === genreIdActive);
      if (thumbIndex >= 0) {
        thumbsSwiper?.slideTo(thumbIndex);
        resetStatusTopCategoryBar(genreIdActive);

        // save categoryCode to SessionStorage
        dispatch(menuInfoActions.setCurrentGenreId({ selectedMenubookId: currentMenubookId, genreId: genreIdActive }));

        setIsSearchMode(false);

        // log({
        //   op_type: LOG_OP_TYPE_CATEGORY_SWITCHING,
        //   op_detail: {
        //     mode: currentModeCode,
        //     category: categoryCodeActive,
        //   },
        // });

        resetGenreNameSticky();
      }
    },
    onSlideChangeTransitionStart: () => {
      (document.querySelector('.page-wrapper') as HTMLElement).scrollTop = 0;
    },
  };

  // Keep position of .menu-content-body
  useEffect(() => {
    // keep scrollTop of div[.menu-content-body]
    const menuContentBodyElem = document.querySelector('.page-wrapper') as HTMLDivElement;

    function handleMenuContentBodyScroll() {
      SessionStorage.setItem(SESSION_MENU_CONTENT_BODY_SCROLLTOP, menuContentBodyElem.scrollTop.toString());
    }

    if (menuContentBodyElem) {
      menuContentBodyElem.addEventListener('scroll', handleMenuContentBodyScroll);
    }

    return () => {
      if (menuContentBodyElem) {
        menuContentBodyElem.removeEventListener('scroll', handleMenuContentBodyScroll);
      }
    };
  }, []);

  // Restore position of .menu-content-body
  useEffect(() => {
    dispatch(
      menuInfoActions.setFaireData({
        menuInfo: menuInfo,
        selectedMenubookId: currentMenubookId,
        genreId: currentGenreId,
      })
    );
    const mainPageLoaded = SessionStorage.getItem(SESSION_MAIN_PAGE_LOADED);
    if (mainPageLoaded === null) {
      const menuContentBodyElem = document.querySelector('.page-wrapper') as HTMLDivElement;
      if (menuContentBodyElem) {
        const menuContentBodyScrollTop = Number(SessionStorage.getItem(SESSION_MENU_CONTENT_BODY_SCROLLTOP)) || 0;
        scrollAnimationTo(
          document.querySelector('.page-wrapper') as HTMLElement,
          // menuContentBodyScrollTop > 0 ? menuContentBodyScrollTop + GENRE_STICKY_NAME_HEIGHT_DEFAULT : 0,
          menuContentBodyScrollTop > 0 ? menuContentBodyScrollTop : 0,
          600
        );
      }
    }
  }, []);

  useEffect(() => {
    if (currentGenreId !== CATEGORY_SEARCH_MENU_ID) {
      if (currentGenreId == GENRE_TOP_MENU_ID) {
        resetStatusTopCategoryBar(GENRE_TOP_MENU_ID);
        dispatch(
          menuInfoActions.setFaireData({
            menuInfo: menuInfo,
            selectedMenubookId: currentMenubookId,
            genreId: currentGenreId,
          })
        );
      }
      setIsSearchMode(false);
      if (faireCd === '') {
        thumbsSwiper?.slideTo(0);
        mainSwiper?.slideTo(0);
      } else {
        const slideNextIndex = currentGenreIdAvailables.indexOf(currentGenreId);
        mainSwiper?.slideTo(slideNextIndex < 0 ? 0 : slideNextIndex);

        setTimeout(() => {
          if (slideNextIndex > 0 && faireCd !== '') {
            const genreMenuSection = document.getElementById(`subgenre-menu-${faireCd}`) as HTMLDivElement;

            if (genreMenuSection) {
              const scrolltopElem = genreMenuSection.offsetTop - genreMenuSection.scrollTop;
              scrollAnimationTo(
                document.querySelector('.page-wrapper') as HTMLElement,
                scrolltopElem > 0 ? scrolltopElem + GENRE_STICKY_NAME_HEIGHT_DEFAULT : 0,
                600
              );
            }

            dispatch(menuInfoActions.updateCampaignGenrecd(''));
          }
        }, 300);

        // log({
        //   op_type: LOG_OP_TYPE_TOP_MENU_SCREEN_FAIR_DETAILS_TRANSITION,
        //   op_detail: {
        //     mode: currentModeCode,
        //     campaign: currentCategoryCode,
        //     genrecd: campaignGenrecd,
        //   },
        // });
      }
    }
  }, [currentMenubookId]);

  useEffect(() => {
    if (thumbsSwiper) {
      thumbsSwiper.update();
      mainSwiper?.virtual.update(true);
    }
  }, [i18n.language]);

  let thumbInitialSlideIndex = currentGenreIds.indexOf(currentGenreId);
  thumbInitialSlideIndex = thumbInitialSlideIndex >= 0 ? thumbInitialSlideIndex : 0;

  useEffect(() => {
    const mainPageLoaded = SessionStorage.getItem(SESSION_MAIN_PAGE_LOADED);
    if (mainPageLoaded === 'true' && mainSwiper) {
      mainSwiper.update();
      // find slide index
      SessionStorage.setItem(SESSION_MENU_CONTENT_BODY_SCROLLTOP, '0');
      const findSlideIndex = currentGenreIdAvailables.findIndex((genreId) => genreId === currentGenreId);
      if (findSlideIndex < 0) return;
      // scroll to slide
      mainSwiper.slideTo(findSlideIndex);
    }
    (document.querySelector('.page-wrapper') as HTMLElement).scrollTop = 0;
  }, [currentMenubookId, currentGenreId]);

  return (
    <>
      <div id="top"></div>
      <StickyContainer
        scrollElement=".page-wrapper"
        stickySelector=".menu-list.main .swiper-slide-active .subgenre-name"
      >
        <Swiper
          id="genre-button-block"
          className={`${isSearchMode ? 'search' : ''}`}
          modules={[Thumbs]}
          onSwiper={setThumbsSwiper}
          spaceBetween={0}
          watchSlidesProgress={true}
          slideToClickedSlide={false}
          slidesPerView={'auto'}
          centeredSlides={true}
          centeredSlidesBounds={true}
          initialSlide={thumbInitialSlideIndex}
          onInit={() => {
            if (!currentGenreIds.includes(currentGenreId)) {
              resetStatusTopCategoryBar(GENRE_TOP_MENU_ID);
            }
          }}
          onClick={(swiper) => {
            if (swiper.clickedIndex !== undefined) {
              const genreIdActive = currentGenreIds[swiper.clickedIndex];
              resetStatusTopCategoryBar(genreIdActive);

              const currentCategoryButton = document.querySelector(`#genre-btn-${genreIdActive}`);
              const isDisabledSlide = currentCategoryButton?.parentElement?.classList.contains('disabled');
              if (!isDisabledSlide) {
                playSound('page');

                const mainSlideIndex = currentGenreIdAvailables.findIndex((x) => x === genreIdActive);
                if (mainSlideIndex >= 0) {
                  mainSwiper?.slideTo(mainSlideIndex);

                  // save categoryCode to SessionStorage and main store
                  dispatch(
                    menuInfoActions.setCurrentGenreId({ selectedMenubookId: currentMenubookId, genreId: genreIdActive })
                  );
                  setIsSearchMode(false);

                  // log({
                  //   op_type: LOG_OP_TYPE_CATEGORY_SWITCHING,
                  //   op_detail: {
                  //     mode: currentModeCode,
                  //     category: categoryCodeActive,
                  //   },
                  // });

                  resetGenreNameSticky();
                }
              }
            }
          }}
        >
          {ListOfGenre}
        </Swiper>

        <div
          className="subgenre-name sticky d-none"
          data-height={GENRE_STICKY_NAME_HEIGHT_DEFAULT}
          id="subgenre-name-sticky"
        >
          .
        </div>

        <Swiper className="menu-list main" {...menuListSwipeProps} style={{ display: 'block' }}>
          {currentGenreIdAvailables.map((genreId, index) => {
            return genreId === '0' ? (
              <SwiperSlide key={`MenuList-${currentMenubookId}-0`} data-genreid={'0'} virtualIndex={0}>
                <Top currentGenreIds={currentGenreIds} mainSwiper={mainSwiper} />
                <Genre
                  mainSwiper={mainSwiper}
                  currentGenreIdAvailables={currentGenreIdAvailables}
                  currentGenreIds={currentGenreIds}
                />
              </SwiperSlide>
            ) : (
              <SwiperSlide
                className="menu-list-slide"
                key={`MenuList-${currentMenubookId}-${genreId}`}
                virtualIndex={index}
                data-genreid={genreId}
              >
                <MenuList currentMenubookId={currentMenubookId} genreId={genreId} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </StickyContainer>
      <HistoryButton />
      <CartButton />
      <MenubookBar mainSwiper={mainSwiper} />
    </>
  );
};

export default MenuSwipeList;
