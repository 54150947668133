import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { delayExecution } from 'utils';

export interface ConfirmModalProps {
  message: string;
  textOk?: string;
  textCancel?: string;
  onAgree?: () => void;
  onClose?: () => void;
  confirmSound?: string;
}

const ConfirmModal = ({ message, textOk, textCancel, onAgree, onClose, confirmSound = 'ok' }: ConfirmModalProps) => {
  const { t } = useTranslation();
  const { closeGlobalModal } = useGlobalModal();
  const buttonAgreeRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    return () => {
      onClose?.();
    };
  }, []);

  const handleCloseModal = () => {
    delayExecution(() => {
      playSound('cancel');
      onClose?.();
      closeGlobalModal();
    });
  };

  const handleAgree = () => {
    if (buttonAgreeRef.current) {
      if (buttonAgreeRef.current.disabled) {
        return;
      }

      buttonAgreeRef.current.disabled = true;
    }

    delayExecution(() => {
      playSound(confirmSound);
      onAgree?.();
      closeGlobalModal();
    });
  };

  return (
    <div className="modal alert-modal ara-dialog" data-bs-backdrop="static" tabIndex={-1} style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="modal-message" dangerouslySetInnerHTML={{ __html: message }}></div>
          </div>

          <div className="dialog-footer d-flex flex-row">
            <div className="col pe-1">
              <button className="ara-btn ara-btn-outline w-100" onClick={handleCloseModal}>
                <div>{textCancel ?? t('App.no')}</div>
              </button>
            </div>
            <div className="col ps-1">
              <button className="ara-btn ara-btn-primary w-100" onClick={handleAgree}>
                {textOk ?? t('App.agree')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
