import { getMenuInfo, getThInfo } from 'apis/Api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AppLoading from 'components/AppLoading';
import useCheckSlipStatus from 'hooks/useCheckSlipStatus';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { allYouCanEatActions } from 'store/slices/allYouCanEatSlice';
import { cartActions } from 'store/slices/cartSlice';
import {
  mainActions,
  selectEatingWaySelect,
  selectMainLoading,
  selectMenu,
  selectOrderList,
  selectSetupEatingWayLoading,
  selectSubMenuGroup,
  selectTenpoInfo,
} from 'store/slices/mainSlice';
import { menuInfoActions } from 'store/slices/menuInfoSlice';
import { selectTime, selectTimeLoading } from 'store/slices/timeSlice';
import {
  SESSION_CURRENT_COURSE_CD,
  SESSION_CURRENT_MENUBOOK_ID,
  SESSION_CURRENT_TIME,
  checkIsMember,
  convertOrderListToCartState,
  getTimeFrameId,
} from 'utils';
import { COURSECD_DEFAULT, RES_CODE_SUCCESS } from '../../constants';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isExistEatingWay, setIsExistEatingWay] = useState(true);

  const slipStatus = useCheckSlipStatus();
  const isLoading = useAppSelector(selectMainLoading);
  const isLoadingTime = useAppSelector(selectTimeLoading);
  const setupEatingWayLoading = useAppSelector(selectSetupEatingWayLoading);
  const menuData = useAppSelector(selectMenu);
  const eatingWaySelect = useAppSelector(selectEatingWaySelect);
  const tenpoInfo = useAppSelector(selectTenpoInfo);
  const timeServer = useAppSelector(selectTime);
  const orderList = useAppSelector(selectOrderList);
  const subMenuGroup = useAppSelector(selectSubMenuGroup);

  useEffect(() => {
    if (slipStatus === 0) {
      navigate('/thanks', { replace: true });
    }
  }, [slipStatus, navigate]);

  useEffect(() => {
    if (slipStatus === 1) {
      if (isLoading) {
        dispatch(mainActions.fetchStartData());
      } else {
        if (setupEatingWayLoading && !isLoadingTime) {
          const timeFrame = getTimeFrameId(
            sessionStorage.getItem(SESSION_CURRENT_TIME) ?? timeServer.tm_hh_mm,
            tenpoInfo?.time_frame
          );
          const eatingWayLayout = eatingWaySelect.layout[timeFrame.time_frame_id];
          if (eatingWayLayout) {
            const eatingWayId = eatingWayLayout.eating_way_setting.find(
              (item) => item.default_flg === '1'
            )?.eating_way_id;
            const eatingWayItem = Object.values(eatingWaySelect.eating_way).find(
              (item) => item.eating_way_id === eatingWayId
            );
            if (eatingWayItem) dispatch(mainActions.setEatingWayId(eatingWayItem));

            const handleBeginApp = async () => {
              // TODO: check if thInfo is available, go to T-003
              const responseThInfo = await getThInfo();

              sessionStorage.setItem(
                SESSION_CURRENT_COURSE_CD,
                responseThInfo.code === RES_CODE_SUCCESS ? responseThInfo.data.course_cd! : COURSECD_DEFAULT.toString()
              );

              if (responseThInfo.code === RES_CODE_SUCCESS) {
                dispatch(allYouCanEatActions.setThInfo(responseThInfo.data));
                const courseCode = responseThInfo.data?.course_cd;
                const eatingWay = eatingWaySelect.eating_way;
                let eatingWayIncludesCourseCode = null;

                if (courseCode) {
                  for (const key in eatingWay) {
                    const eatingWayItem = Object.values(eatingWay).find((item) => item.eating_way_id === key);
                    if (eatingWayItem) {
                      if (eatingWayItem.course_cd && eatingWayItem.course_cd.includes(courseCode)) {
                        eatingWayIncludesCourseCode = eatingWayItem.eating_way_id;
                        dispatch(mainActions.setEatingWayId(eatingWayItem));
                        break;
                      }
                    }
                  }
                }

                if (eatingWayIncludesCourseCode) {
                  // TODO: get th menu info
                  try {
                    const responseMenuInfo = await getMenuInfo(Number(eatingWayIncludesCourseCode), Number(courseCode));

                    dispatch(
                      menuInfoActions.initMenuInfoData({
                        data: responseMenuInfo,
                        menuData: menuData,
                        thInfo: responseThInfo,
                        menubookId: sessionStorage.getItem(SESSION_CURRENT_MENUBOOK_ID),
                      })
                    );

                    const initCartState = convertOrderListToCartState(
                      orderList,
                      menuData,
                      subMenuGroup,
                      responseMenuInfo.data.menu_info
                    );

                    dispatch(cartActions.initDataCart(initCartState));
                  } catch (error) {
                    dispatch(menuInfoActions.initMenuInfoDataFailed());
                  }
                }
              } else {
                getMenuInfo(Number(eatingWayId), COURSECD_DEFAULT).then((responseMenuInfo) => {
                  if (responseMenuInfo.code !== RES_CODE_SUCCESS) return;

                  dispatch(
                    menuInfoActions.initMenuInfoData({
                      data: responseMenuInfo,
                      menuData: menuData,
                      menubookId: sessionStorage.getItem(SESSION_CURRENT_MENUBOOK_ID),
                    })
                  );

                  const initCartState = convertOrderListToCartState(
                    orderList,
                    menuData,
                    subMenuGroup,
                    responseMenuInfo.data.menu_info
                  );

                  dispatch(cartActions.initDataCart(initCartState));
                });
              }
            };
            handleBeginApp();

            dispatch(mainActions.setSetupEatingWayDone());
          } else {
            setIsExistEatingWay(false);
          }
        }
      }
    }
  }, [slipStatus, isLoading, isLoadingTime]);

  if (isLoading) {
    return <AppLoading isLoadingApp />;
  }

  return (
    <>
      {isExistEatingWay ? (
        <div className="layout-page">
          {checkIsMember() && <div className="app-padding-bar" />}
          {children}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Layout;
