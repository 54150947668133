import { SESSION_CUSTOMER_TOKEN_KEY, SessionStorage } from 'utils/sessionStorage';

/**
 * Retrieves the customer token from the session storage.
 *
 * @returns The customer token stored in the session storage, or an empty string ("") if no token is found.
 */
export const getToken = (): string => {
  return SessionStorage.getItem(SESSION_CUSTOMER_TOKEN_KEY) || '';
};
