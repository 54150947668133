import { IMenuItemInfo, MenuInfoDataType, SubmenuGroupInfoType } from 'apis/types';
import { useAppSelector } from 'app/hooks';
import { Collapse } from 'bootstrap';
import { DishDispatchContext } from 'components/DishModal';
import OptionGroup from 'components/DishModal/components/OptionGroup';
import SelectedOption from 'components/DishModal/components/SelectedOption';
import { PortionState } from 'models';
import { RefObject, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectMenu } from 'store/slices/mainSlice';
import { selectMenuInfo } from 'store/slices/menuInfoSlice';
import { AraAngleDownIcon } from 'theme/icons';
import { getDishInfo, getSubmenuGroupFromMenubookId, mergeCompactStateEntries, summarizeOrderState } from 'utils';
import { attrLang } from 'utils/attrLang';

interface PortionProps {
  orderState: PortionState;
  dishCode: string;
  menubookId: string;
  orderIdx: number;
  portionQuantity: number;
}

const Portion = ({ orderState, dishCode, menubookId, orderIdx, portionQuantity }: PortionProps) => {
  const { t } = useTranslation();
  const dishDispatch = useContext(DishDispatchContext);

  const defaultRef = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collapseClass, setColapseClass] = useState<string>('');

  const menuList = useAppSelector(selectMenu);
  const dishInfo = getDishInfo(menuList, dishCode);
  const compactState = summarizeOrderState(orderState);
  const menuInfo = useAppSelector(selectMenuInfo);

  useEffect(() => {
    updateCollapseState(portionQuantity, collapseClass, selectedRef, defaultRef);
  }, [portionQuantity, collapseClass]);

  const compactStateEntries = Object.entries(compactState);
  const mergedCompactStateEntries = mergeCompactStateEntries(compactStateEntries);
  const ListOfSelectedOption: JSX.Element[] = [];

  mergedCompactStateEntries.forEach((optionValue, optionCodeVersion) => {
    const optionInfo = optionValue.optionInfo;
    const optionName = attrLang(optionInfo, 'lang');
    const optionQuantity = optionValue.quantity;
    ListOfSelectedOption.push(
      <SelectedOption key={optionCodeVersion} optionName={optionName} optionQuantity={optionQuantity} />
    );
    const childInfor = optionValue.childInfor;

    if (childInfor) {
      Object.entries(childInfor).forEach(([childGroupCodeVersion, childGroupState]) => {
        const childGroupInfo = childGroupState.optionInfo;
        const childGroupName = attrLang(childGroupInfo, 'lang');
        const childQuantity = childGroupState.quantity;

        ListOfSelectedOption.push(
          <SelectedOption
            key={childGroupCodeVersion}
            optionName={childGroupName}
            optionQuantity={childQuantity}
            isChild={true}
          />
        );
      });
    }
  });

  // const ListOfSelectedOption = Object.entries(compactState).map(([optionValue, optionCodeVersion]) => {
  //   const optionInfo = optionValue.optionInfo;
  //   const optionInfo = getDishInfo(menuList, optionCode);
  //   const optionName = attrLang(optionInfo, 'lang');
  //   return <SelectedOption key={optionCode} optionName={optionName} optionQuantity={optionQuantity} />;
  // });
  const ListOfGroups = createListOfGroups(dishInfo, orderState, menubookId, dishCode, orderIdx, menuInfo);

  const handleToggle = () => {
    toggleCollapse(defaultRef);
    toggleCollapse(selectedRef);
  };

  const handleDelete = () => {
    dishDispatch({ type: 'delete-order', payload: orderIdx });
  };

  const groupHasValue = ListOfGroups.some((group) => group !== null);

  return groupHasValue ? (
    <div>
      {portionQuantity > 1 && ListOfGroups.length > 0 && (
        <div className={`collapse-button ${collapseClass}`} onClick={handleToggle}>
          <div className="order-name">
            <span>{`${t('MenuItemDetail.portion_name')}-${orderIdx + 1}`}</span>
            <span className="order-name-nav">
              <AraAngleDownIcon />
            </span>
          </div>
        </div>
      )}
      <div className="collapse" ref={selectedRef}>
        <div className="list-group-wrapper">
          <div className="selected-options mb-3">
            <ul className="list-option-group">{ListOfSelectedOption}</ul>
          </div>
          <button className="ara-btn ara-btn-outline delete-list-option-group w-100" onClick={handleDelete}>
            <div>{`${t('MenuItemDetail.portion_name')}${orderIdx + 1}${t('MenuItemDetail.delete_portion')}`}</div>
          </button>
        </div>
      </div>
      <div className="collapse show" ref={defaultRef}>
        <div className="list-group-wrapper">{ListOfGroups}</div>
      </div>
    </div>
  ) : null;
};

const toggleCollapse = (ref: RefObject<HTMLDivElement>) => {
  if (ref.current) {
    const controller = Collapse.getOrCreateInstance(ref.current);
    controller.toggle();
  }
};

const updateCollapseState = (
  portionQuantity: number,
  collapseClass: string,
  selectedRef: RefObject<HTMLDivElement>,
  defaultRef: RefObject<HTMLDivElement>
) => {
  if (portionQuantity === 1) {
    collapseClass = '';

    selectedRef.current?.classList.remove('show');

    if (!defaultRef.current?.classList.contains('show')) {
      defaultRef.current?.classList.add('show');
    }
  } else {
    if (collapseClass === 'collapse') {
      if (!selectedRef.current?.classList.contains('show')) {
        selectedRef.current?.classList.add('show');
      }

      defaultRef.current?.classList.remove('show');
    } else {
      selectedRef.current?.classList.remove('show');

      if (!defaultRef.current?.classList.contains('show')) {
        defaultRef.current?.classList.add('show');
      }
    }
  }
};

const createListOfGroups = (
  dishInfo: IMenuItemInfo,
  orderState: PortionState,
  menubookId: string,
  dishCode: string,
  orderIdx: number,
  menuInfo: MenuInfoDataType
) => {
  const ListOfGroups: (JSX.Element | null)[] = [];

  if (dishInfo) {
    const submenuGroup = getSubmenuGroupFromMenubookId(menuInfo, menubookId, dishCode);
    submenuGroup?.forEach((submenuGroupInfo: SubmenuGroupInfoType) => {
      const dishGroupCode = submenuGroupInfo.submenugroup_cd;

      Object.entries(orderState).forEach(([groupCode, groupState]) => {
        if (dishGroupCode === groupCode.split('-')[0]) {
          if (groupState.basic.noselectflg === '1') {
            ListOfGroups.push(null);
          } else {
            ListOfGroups.push(
              <OptionGroup
                key={groupCode}
                groupCode={groupCode}
                dishCode={dishCode}
                orderIdx={orderIdx}
                groupState={groupState}
              />
            );
          }
        }
      });
    });
  }

  return ListOfGroups;
};

export default Portion;
