import { IOrderDetailMenuInfo } from 'apis/types';
import { useAppSelector } from 'app/hooks';
import { selectMenu } from 'store/slices/mainSlice';
import { attrLang, stripHtml } from 'utils';

interface DishOptionListProps {
  dishOrderState: IOrderDetailMenuInfo;
}

const DishOptionList = ({ dishOrderState }: DishOptionListProps) => {
  const menuList = useAppSelector(selectMenu);

  return (
    <ul className="option-list">
      {dishOrderState.MenuInfo?.map((optionItem, index) => {
        const optionItemCode = optionItem.MenuCode;
        const optionItemInfo = menuList[optionItemCode];
        const optionItemName = stripHtml(attrLang(optionItemInfo, 'lang'));
        const optionItemQuantity = parseInt(optionItem.CountInfo.Count);
        const childInfo = optionItem.MenuInfo;

        if (optionItemInfo) {
          return (
            <li key={optionItemCode + '-' + index}>
              {optionItemName} ({optionItemQuantity})
              <ul style={{ paddingLeft: '6px' }}>
                {childInfo &&
                  childInfo.map((childItem, index) => {
                    const childItemCode = childItem.MenuCode;
                    const childItemMenuInfo = menuList[childItemCode];
                    const childItemName = stripHtml(attrLang(childItemMenuInfo, 'lang'));
                    const childItemQuantity = parseInt(childItem.CountInfo.Count);
                    return (
                      <li key={childItem.MenuCode + '-' + index}>
                        {childItemName} ({childItemQuantity})
                      </li>
                    );
                  })}
              </ul>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default DishOptionList;
