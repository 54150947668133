export const updateMainPageScrollTop = () => {
  const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
  const genreNameSticky = document.querySelector('#genre-name-sticky') as HTMLElement;

  if (pageWrapper && genreNameSticky) {
    let scrollTop = pageWrapper.scrollTop;
    if (genreNameSticky.innerHTML.length > 0) {
      scrollTop -= genreNameSticky.clientHeight;
    }
    document.documentElement.style.setProperty('--main-page-scrolltop', `${scrollTop}`);
  }
};
