export const MathEaseInOutQuad = function (
  currentTime: number,
  startValue: number,
  changeInValue: number,
  duration: number
) {
  currentTime /= duration / 2;
  if (currentTime < 1) return (changeInValue / 2) * currentTime * currentTime + startValue;
  currentTime--;
  return (-changeInValue / 2) * (currentTime * (currentTime - 2) - 1) + startValue;
};

export function scrollAnimationTo(element: HTMLElement, to: number, duration: number) {
  const start = element.scrollTop,
    change = to - start,
    increment = 20;
  let currentTime = 0;

  const animateScroll = function () {
    currentTime += increment;
    const val = MathEaseInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}
