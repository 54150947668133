/* eslint-disable react/react-in-jsx-scope */
interface AraLogoIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
}
export const AraLogoIcon = ({ width = 88, height = 28, color = '#BC1224' }: AraLogoIconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 88 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M67.0372 22.0553H73.4482C73.4724 24.6117 73.6297 24.672 70.6419 24.5996C70.3879 24.5996 70.1218 24.5876 69.8436 24.5755V27.9881C70.1097 28.0002 70.3758 27.9881 70.6419 27.9881C76.6658 27.9881 77.3069 27.2405 77.319 22.0432H82.2301V19.0889H67.0252V22.0432L67.0372 22.0553Z"
        fill={color}
      />
      <path
        d="M84.105 6.64404H65.1865V13.3486H84.105V6.64404ZM80.3673 11.0816H68.9364V8.91105H80.3673V11.0816Z"
        fill={color}
      />
      <path d="M88 14.772H61.2673V22.7909H65.368V17.6178H83.8994V22.7909H88V14.772Z" fill={color} />
      <path d="M72.5894 0V2.09819H61.2794V5.2696H88V2.09819H76.69V0H72.5894Z" fill={color} />
      <path
        d="M0.907217 8.54953H4.92316V5.66753H21.87V8.54953H25.898V2.49612H15.4106V0H11.3825V2.49612H0.907217V8.54953Z"
        fill={color}
      />
      <path
        d="M14.1042 7.04199H9.66488C9.2899 7.99462 8.83024 9.00754 8.28591 10.0808H0V13.7104H6.22955C5.31024 15.1936 4.26997 16.7009 3.10873 18.1721C5.24976 18.992 8.16495 20.005 11.2979 21.1626C9.06007 22.1876 5.92715 22.7422 1.52412 23.104V27.2642C8.16495 26.6492 12.8462 25.1781 15.7372 22.8749C19.3902 24.346 22.9949 25.986 25.7166 27.7224V23.309C23.5271 22.1273 20.9748 21.0782 18.3621 20.0894C19.7894 18.2082 20.7087 16.2427 21.4708 13.7224H26.7931V10.0928H12.6889C13.2333 9.05577 13.7171 8.03079 14.1042 7.05405V7.04199ZM17.5516 13.7104C16.7896 15.688 15.846 17.2074 14.5397 18.6906C12.5075 17.9671 10.5237 17.2677 8.73347 16.5562C9.33828 15.6639 9.97938 14.6992 10.5963 13.7104H17.5516Z"
        fill={color}
      />
      <path
        d="M46.0503 15.857H51.0944V2.45995H45.9293L46.7761 0H42.6029L42.1432 2.45995H36.9781V15.845H42.0223V17.702H30.6397V21.0784H39.8208C37.5346 22.5495 34.0871 23.671 30.6397 23.8157V27.59C34.5226 27.0835 38.8289 26.143 42.0223 23.3695V27.9879H46.0503V22.7666C48.3244 24.8527 51.917 26.6253 57.4329 27.8915V24.0327C53.6225 23.3213 50.8283 22.2601 48.9413 21.0784H57.4329V17.702H46.0503V15.845V15.857ZM40.6917 5.42636H47.393V7.92248H40.6917V5.42636ZM40.6917 12.9027V10.4065H47.393V12.9027H40.6917Z"
        fill={color}
      />
      <path
        d="M35.5629 4.00365C33.9662 3.29219 32.6235 2.3878 30.6397 0.651367V5.35421C31.9945 6.64448 33.2041 7.44034 35.5629 8.36885V4.00365Z"
        fill={color}
      />
      <path
        d="M35.5629 9.20054C33.4581 9.97228 32.2122 10.5993 30.6397 11.7811V16.7492C32.309 15.2057 33.7605 14.3616 35.5629 13.4813V9.18848V9.20054Z"
        fill={color}
      />
      <path
        d="M52.4976 4.0035V8.3687C54.8564 7.44019 56.066 6.64433 57.4208 5.35406V0.63916C55.437 2.37559 54.0943 3.27999 52.4976 3.99144V4.0035Z"
        fill={color}
      />
      <path
        d="M52.4976 13.4937C54.2999 14.374 55.7515 15.2181 57.4208 16.7616V11.7934C55.8482 10.5996 54.5902 9.98464 52.4976 9.21289V13.5057V13.4937Z"
        fill={color}
      />
    </svg>
  );
};
