/* eslint-disable */
import { ICouponInfo, IMenuItemInfo } from 'apis/types';
import { useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { DishModalContext } from 'context/DishModalContext';
import { FormEvent, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { selectCartState } from 'store/slices/cartSlice';
import { selectCustomerQuantity } from 'store/slices/customerQuantitySlice';
import { selectCouponInfo, selectMenu, selectOrderHistoryData } from 'store/slices/mainSlice';
import { selectPoscdInMenuInfo } from 'store/slices/menuInfoSlice';
import { countCouponUsed } from 'utils';
import { TAP_ANIMATION_TIME } from '../../constants';
import './CouponFormModal.scss';

export type PosCdData = { meubookId: string; poscd: string[] }[];
interface CouponFormModalProps {
  open?: boolean;
  onClose: () => void;
}

const isDishInCouponList = (dishInfo: IMenuItemInfo, couponInfo: ICouponInfo): boolean => {
  if (!dishInfo) {
    return false;
  }
  const { poscd } = dishInfo;
  const couponMenuList = couponInfo.coupon_menu;

  return couponMenuList.includes(poscd);
};

function getMenuBookIdFromDishInfo(dishInfo: IMenuItemInfo | undefined, posCdData: PosCdData): string {
  if (dishInfo === null || dishInfo === undefined) {
    return '';
  }

  if (dishInfo.poscd === null || dishInfo.poscd.length === 0) {
    return '';
  }

  const menubook = posCdData.find((item) => item.poscd.includes(dishInfo.poscd));
  return menubook ? menubook.meubookId : posCdData[0].meubookId;
}

/**
 * A modal component for entering coupon codes.
 *
 * @param {CouponFormModalProps} props - The component props.
 * @param {function} props.onClose - A callback function to close the modal.
 * @return {JSX.Element} The coupon form modal component.
 */
const CouponFormModal = ({ open = false, onClose }: CouponFormModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { showGlobalModal } = useGlobalModal();
  const { showCouponDishModal } = useContext(DishModalContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonConfirmRef = useRef<HTMLButtonElement>(null);
  const buttonCancelRef = useRef<HTMLButtonElement>(null);

  const customerQuantity = useAppSelector(selectCustomerQuantity);
  const menuList = useAppSelector(selectMenu);
  const listCoupon = useAppSelector(selectCouponInfo);
  const cartState = useAppSelector(selectCartState);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const posCdData = useAppSelector(selectPoscdInMenuInfo);

  const totalCouponUsed = countCouponUsed(menuList, listCoupon, cartState, orderHistory);

  /**
   * Closes the modal after a short delay, resetting its state and triggering the onClose callback.
   *
   * @return {void}
   */
  useEffect(() => {
    if (open) {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = true;
    }
  }, [open]);
  const handleClose = () => {
    setTimeout(() => {
      playSound('cancel');

      const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `0px`);
        pageWrapper.removeAttribute('style');
      }

      if (inputRef.current) inputRef.current.value = '';
      onClose();
      if (buttonCancelRef.current) buttonCancelRef.current.disabled = false;
      if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = true;
    }, TAP_ANIMATION_TIME / 2);
  };

  /**
   * Handles changes to the input field, trimming and validating the input value, and updating the confirm button's disabled state accordingly.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event triggered by the input field.
   * @return {void}
   */
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.validity.valid ? e.target.value.trim().slice(0, 14) : e.target.value;
    if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = e.target.value.length === 0;
  };

  /**
   * Shows the coupon dish modal.
   *
   * @param dishCode - The code of the dish.
   * @param modeCode - The code of the mode.
   */

  /**
   * Handles the submission of the coupon form, validating the input value,
   * checking for a matching menu item, and displaying a modal or error message accordingly.
   *
   * @param {FormEvent<HTMLFormElement>} e - The form submission event.
   * @return {void}
   */
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    // check button has disabled status, prevent click
    if (buttonConfirmRef.current) {
      if (buttonConfirmRef.current.disabled) {
        return;
      }
    }
    // change status of button to disabled
    if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = true;
    e.preventDefault();
    setTimeout(() => {
      // Check if inputRef is not null and has a value
      if (inputRef.current && inputRef.current.value.length > 0) {
        playSound('ok'); // Play a sound indicating the input is valid

        // Find the menu item in menuList that matches the input coupon code
        const menu = Object.values(menuList).find((menu) => {
          const intPoscd = parseInt(menu.poscd); // Convert menu poscd to integer
          const intCouponCode = parseInt((inputRef.current && inputRef.current.value) || '0'); // Convert input value to integer

          // Check if the menu poscd matches the input coupon code and if the dish is in the coupon list
          return intPoscd === intCouponCode && isDishInCouponList(menu, listCoupon);
        });

        // If no matching menu item is found
        if (menu === undefined) {
          playSound('error'); // Play an error sound
          // Show a global modal with a warning message
          showGlobalModal(() => (
            <AlertModal
              message={t('CouponForm.warn_not_found_coupon_menu')}
              onClose={() => {
                if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = false; // Enable the confirm button
              }}
            />
          ));
        } else {
          // Get the mode code from the menu item
          const modeCode = getMenuBookIdFromDishInfo(menu as IMenuItemInfo, posCdData as unknown as PosCdData);
          // Clear the input value
          if (inputRef.current) inputRef.current.value = '';
          onClose(); // Close the modal
          if (buttonCancelRef.current) buttonCancelRef.current.disabled = false; // Enable the cancel button
          if (buttonConfirmRef.current) buttonConfirmRef.current.disabled = true; // Disable the confirm button

          // Show the coupon dish modal with the menu poscd and mode code
          showCouponDishModal && showCouponDishModal(menu.poscd, modeCode);
        }
      }
    }, TAP_ANIMATION_TIME / 2); // Delay the execution by half of TAP_ANIMATION_TIME
  };

  return (
    <div className="modal coupon-modal" tabIndex={-1} data-bs-backdrop="static" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered modal-sm coupon-form-modal">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
            <div className="modal-header border-bottom-0 px-0">
              <div className="coupon-form-modal__headtitle">{t('CouponForm.headtitle')}</div>
            </div>
            <div className="modal-body">
              <div className="coupon-form-modal__lables">{t('CouponForm.coupon_number')}</div>
              <div className="coupon-form-modal__input-wrapper mb-3">
                <input
                  id="coupon-code"
                  type="number"
                  ref={inputRef}
                  pattern="[0-9]*"
                  className="coupon-form-modal__input-field"
                  maxLength={6}
                  onInput={handleCodeChange}
                  placeholder={t('CouponForm.headtitle')}
                />
              </div>
              <div className="coupon-form-modal__content">
                <div className="text-center fw-bold">{t('CouponForm.title')}</div>
                <div className="coupon-form-modal__promovalue text-center">
                  {`${customerQuantity * 2 - totalCouponUsed}:${customerQuantity * 2}`}
                </div>
                <div className="coupon-form-modal__description">
                  <ul>
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: t('CouponForm.description1') }}></span>
                    </li>
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: t('CouponForm.description2') }}></span>
                    </li>
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: t('CouponForm.description3') }}></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="coupon-form-modal__footer group-button border-top-0">
              <div className="d-flex w-100">
                <button
                  type="button"
                  className="ara-btn ara-btn-outline w-100"
                  ref={buttonCancelRef}
                  onClick={handleClose}
                >
                  <div>{t('CouponForm.cancel')}</div>
                </button>
                <button type="submit" className="ara-btn ara-btn-primary w-100 p-10" ref={buttonConfirmRef}>
                  <div>{t('CouponForm.degree')}</div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CouponFormModal;
