import { MenuBookDataType, MenuInfoDataType } from 'apis/types';
import { ALL_YOU_CAN_EAT_PROPERTY, NO_SELECTION_PROPERTY, NO_SELECTION_PROPERTY_NUM } from '../constants';

export const getListCodeMenuBuffet = (menuInfo: MenuInfoDataType, menubookList: MenuBookDataType) => {
  const listCodeMenuBuffet: string[] = [];

  const menubookBuffetId = Object.values(menubookList).find((menubookItem) => {
    if (
      menubookItem.property &&
      menubookItem.property.toString() !== NO_SELECTION_PROPERTY &&
      menubookItem.property.toString() !== ALL_YOU_CAN_EAT_PROPERTY
    ) {
      return menubookItem.property !== NO_SELECTION_PROPERTY_NUM;
    } else {
      return menubookItem.property !== NO_SELECTION_PROPERTY;
    }
  })?.menubook_id;

  if (menubookBuffetId) {
    const menubookBuffetInfo = menuInfo.menubook.find((menubookItem) => {
      return menubookItem.menubook_id === menubookBuffetId;
    });
    const courseInfo = menubookBuffetInfo?.course?.course_info;

    if (courseInfo) {
      courseInfo.forEach((course) => {
        course.genre.forEach((genre) => {
          genre.subgenre.forEach((subgenre) => {
            subgenre.menu?.forEach((menu) => {
              listCodeMenuBuffet.push(menu.poscd);
            });
          });
        });
      });
    }
  }

  return listCodeMenuBuffet;
};
