import { AraLogoIcon } from 'theme/icons';

interface AppLoadingProps {
  isLoadingApp?: boolean;
}

const AppLoading = ({ isLoadingApp = false }: AppLoadingProps) => {
  return (
    <div
      className={`loading-app container-fluid d-flex align-items-center justify-content-center ${
        isLoadingApp ? 'main' : ''
      }`}
    >
      <div className="row text-center">
        <div className="col d-flex flex-column align-items-center">
          {isLoadingApp && (
            <div className="mb-3">
              <AraLogoIcon width={176} height={56} />
            </div>
          )}
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLoading;
