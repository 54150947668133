import { GenreGroupMenuBookInfoType, MenuBookGroupInfoType } from 'apis/types';

export function getMenuAllYouCanEat(menubook: MenuBookGroupInfoType): GenreGroupMenuBookInfoType[] {
  const menu: GenreGroupMenuBookInfoType[] = [];

  menubook.course?.course_info.forEach((course) => {
    course.genre.forEach((item) => menu.push(item));
  });

  return menu;
}
