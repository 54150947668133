import { IBarInfo, IBarMenu, OrderAPIType } from 'apis/types';

/**
 * Retrieves the bar code from the given order data and bar menu, based on the available bar information.
 *
 * @param dataCartOrder - The order data from the API, containing the order information.
 * @param barMenu - The bar menu data, containing the mapping of menu codes to their respective bar codes.
 * @param barInfo - The bar information data, containing the mapping of bar codes to their respective details.
 *
 * @returns The combined bar code from the order data, or an empty string if no valid bar codes are found.
 */
export const getBarInfoCodeFromOrderData = (dataCartOrder: OrderAPIType, barMenu: IBarMenu, barInfo: IBarInfo) => {
  const listBarCodes = new Set<number>();

  const addBarCodeIfExist = (menuCode: string) => {
    const barCodes = barMenu[menuCode]?.bar_code;

    if (barCodes) {
      barCodes.forEach((barCode) => {
        listBarCodes.add(Number(barCode));
      });
    }
  };

  dataCartOrder.order_info.forEach((order) => {
    addBarCodeIfExist(order.MenuCode);

    if (order.MenuInfo) {
      order.MenuInfo.forEach((subMenu) => {
        addBarCodeIfExist(subMenu.MenuCode);
      });
    }
  });

  if (listBarCodes.size === 0) {
    return '';
  }

  const combinedBarCode = Array.from(listBarCodes).reduce((a, b) => a | b, 0);
  return barInfo[combinedBarCode] ? combinedBarCode.toString() : '';
};
