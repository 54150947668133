import { ITimeFrame } from 'apis/types';

const MINUTES_IN_A_DAY = 29 * 60; // 6:00 - 5:59 (next day) = 29:59

const toMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

/**
 * Returns the time frame ID for the given currentTime based on the provided timeFrameList.
 *
 * @param currentTime - The current time in the format of 'HH:mm'.
 * @param timeFrameList - An array of time frame objects, each containing a 'start' and 'end' time in the format of 'HH:mm' or 'Open' or 'Close'.
 * @returns The time frame ID if the current time falls within a valid time frame, otherwise null.
 */
export const getTimeFrameId = (currentTime: string, timeFrameList: ITimeFrame[]): ITimeFrame => {
  const currentMinutes = toMinutes(currentTime);
  for (let i = 0; i < timeFrameList.length; i++) {
    const start = timeFrameList[i].start === 'Open' ? 0 : toMinutes(timeFrameList[i].start);
    const end = timeFrameList[i].end === 'Close' ? MINUTES_IN_A_DAY : toMinutes(timeFrameList[i].end);

    if (start <= currentMinutes && currentMinutes <= end) {
      return timeFrameList[i];
    }
  }

  return timeFrameList[0];
};
