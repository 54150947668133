import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { AraHistoryIcon } from 'theme/icons';
import { delayExecution } from 'utils';
import './HistoryButton.scss';

const HistoryButton = () => {
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    if (buttonRef.current) {
      if (buttonRef.current.disabled) {
        return;
      }

      buttonRef.current.disabled = true;
    }

    delayExecution(() => {
      playSound('page');
      //! updateMainPageScrollTop();
      navigate('/order-history', { replace: true });
    });
  };

  return (
    <div className="history-button">
      <button ref={buttonRef} onClick={handleClick}>
        <AraHistoryIcon />
      </button>
    </div>
  );
};

export default HistoryButton;
