// Utility for interacting with sessionStorage using simplified methods
export const SessionStorage = {
  /**
   * Sets a value in sessionStorage.
   * @param key The key under which to store the value.
   * @param value The value to store.
   */
  setItem: (key: string, value: string) => {
    window.sessionStorage.setItem(key, value);
  },

  /**
   * Retrieves a value from sessionStorage.
   * @param key The key of the value to retrieve.
   * @returns The retrieved value, or null if the key doesn't exist.
   */
  getItem: (key: string): string | null => {
    return window.sessionStorage.getItem(key);
  },

  /**
   * Removes a value from sessionStorage.
   * @param key The key of the value to remove.
   */
  removeItem: (key: string) => {
    window.sessionStorage.removeItem(key);
  },

  /**
   * Clears all values from sessionStorage.
   */
  clear: () => {
    window.sessionStorage.clear();
  },
};

export const SESSION_CURRENT_MENUBOOK_ID = 'currentMenubookId';
export const SESSION_SELECTED_MENUBOOK_ID = 'selectedMenubookId';
export const SESSION_CURRENT_GENRE_CODE = 'currentGenreId';
export const SESSION_CUSTOMER_ID_KEY = 'cid';
export const SESSION_CUSTOMER_TOKEN_KEY = 'token';
export const SESSION_MAIN_PAGE_LOADED = 'main_page_loaded';
export const SESSION_NOTIFY_AGE_LIMIT = 'notify_age_limit';
export const SESSION_SEARCH_VALUE = 'search_value';
export const SESSION_LANGUAGE_CODE = 'language_code';
export const SESSION_EATINGWAY_ID = 'current_eating_way_id';
export const SESSION_WARING_NIGHT_OT_TIME = 'night_ot_time';
export const SESSION_CURRENT_TIME = 'current_time';
export const SESSION_CURRENT_COURSE_CD = 'current_course_cd';
export const SESSION_TENPO_INFO = 'tenpo_info';
export const SESSION_MENU_CONTENT_BODY_SCROLLTOP = 'menu_content_body_scrolltop';
export const SESSION_MENUBOOK_BUTTON_CLICKED = 'menubook_button_clicked';
