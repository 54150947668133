import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllYouCanEatType, ThInfoDataType, ThInfoType } from 'apis/types';
import { RootState } from 'app/store';
import { SESSION_CURRENT_COURSE_CD } from 'utils';

export interface AllYouCanEatState {
  loading: boolean;
  thMenuInfo: ThInfoType;
  thMenuData: ThInfoDataType;
  thRemainValue: {
    thRemainTime: number;
    nhRemainTime: number;
  };
  allYouCanEatState: AllYouCanEatType;
}

const initialState: AllYouCanEatState = {
  loading: true,
  thMenuInfo: {
    code: '',
    msg: '',
    data: {},
  },
  thMenuData: {},
  thRemainValue: {
    thRemainTime: 0,
    nhRemainTime: 0,
  },
  allYouCanEatState: {
    isShowEatRemainTime: false,
    isShowDrinkRemainTime: false,
  },
};

const allYouCanEatSlice = createSlice({
  name: 'all_you_can_eat',
  initialState,
  reducers: {
    initData(state) {
      state.loading = true;
    },
    initDataSuccess(state, action: PayloadAction<ThInfoType>) {
      state.thMenuInfo = action.payload;
      state.thMenuData = action.payload.data;
      state.thRemainValue.thRemainTime = state.thMenuData?.th_limit || 0;
      state.thRemainValue.nhRemainTime = state.thMenuData?.nh_limit || 0;
      state.allYouCanEatState.isShowEatRemainTime =
        state.thMenuData?.th_flg === '1' && (state.thMenuData?.th_limit || 0) > 0;
      state.allYouCanEatState.isShowDrinkRemainTime =
        state.thMenuData?.nh_flg === '1' && (state.thMenuData?.nh_limit || 0) > 0;
      state.loading = false;
    },
    setThInfo(state, action: PayloadAction<ThInfoDataType>) {
      state.thMenuData = action.payload;
      state.thRemainValue.thRemainTime = state.thMenuData?.th_limit || 0;
      state.thRemainValue.nhRemainTime = state.thMenuData?.nh_limit || 0;
      state.allYouCanEatState.isShowEatRemainTime =
        state.thMenuData?.th_flg === '1' && (state.thMenuData?.th_limit || 0) > 0;
      state.allYouCanEatState.isShowDrinkRemainTime =
        state.thMenuData?.nh_flg === '1' && (state.thMenuData?.nh_limit || 0) > 0;
      sessionStorage.setItem(SESSION_CURRENT_COURSE_CD, state.thMenuData.course_cd ?? '');
      state.loading = false;
    },
    updateStatus(state, action: PayloadAction<{ isShowEatRemainTime: boolean; isShowDrinkRemainTime: boolean }>) {
      state.allYouCanEatState.isShowEatRemainTime = action.payload.isShowEatRemainTime;
      state.allYouCanEatState.isShowDrinkRemainTime = action.payload.isShowDrinkRemainTime;
    },
    updateRemainTime(state, action: PayloadAction<{ thRemainTime: number; nhRemainTime: number }>) {
      state.thRemainValue.thRemainTime = action.payload.thRemainTime;
      state.thRemainValue.nhRemainTime = action.payload.nhRemainTime;
    },
  },
});

// Actions
export const allYouCanEatActions = allYouCanEatSlice.actions;

// Selectors
export const selectAllYouCanEatLoading = (state: RootState) => state.allYouCanEat.loading;
export const selectThMenuInfo = (state: RootState) => state.allYouCanEat.thMenuInfo;
export const selectThMenuData = (state: RootState) => state.allYouCanEat.thMenuData;
export const selectThRemainValue = (state: RootState) => state.allYouCanEat.thRemainValue;
export const selectIsShowRemainAllYouCanEat = (state: RootState) =>
  state.allYouCanEat.thMenuData !== null &&
  (state.allYouCanEat.allYouCanEatState.isShowEatRemainTime ||
    state.allYouCanEat.allYouCanEatState.isShowDrinkRemainTime);
export const selectAllYouCanEatState = (state: RootState) => state.allYouCanEat.allYouCanEatState;

// Reducer
export const allYouCanEatReducer = allYouCanEatSlice.reducer;
