import { getServerTime } from 'apis/Api';
import { IServerTime } from 'apis/types';
import { handleHttpError, handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { timeActions } from 'store/slices/timeSlice';
import { RES_CODE_SUCCESS } from '../../constants';

function* fetchTimeData() {
  try {
    const response: IServerTime = yield call(getServerTime);
    if (response.code !== RES_CODE_SUCCESS) {
      yield handleResponseError(response);
    } else {
      yield put(timeActions.fetchTimeDataSuccess(response));
    }
  } catch (error) {
    yield put(timeActions.fetchTimeDataFailed());
    yield handleHttpError(error as AxiosError);
  }
}

export default function* timeSaga() {
  yield takeLatest(timeActions.fetchTimeData.type, fetchTimeData);
}
