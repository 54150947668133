import { IMenu, IOrderDetailMenuInfo } from 'apis/types';

/**
 * Calculates the total cost of a dish and its options from the order history.
 *
 * @param dishOrderState - The state of the dish order, containing the dish code, its price, and the count of the dish.
 * @param menuList - The list of all available menu items, containing their codes and prices.
 * @returns The total cost of the dish and its options.
 */
export const getDishCostFromOrderHistory = (dishOrderState: IOrderDetailMenuInfo, menuList: IMenu): number => {
  const dishCode = dishOrderState.MenuCode;
  const dishInfo = menuList[dishCode];
  const dishPrice = (dishInfo && parseInt(dishInfo.price)) || 0;

  let totalCost = dishPrice * parseInt(dishOrderState.CountInfo.Count);

  const options = dishOrderState.MenuInfo;

  options?.forEach((option) => {
    const optionCode = option.MenuCode;
    const optionInfo = menuList[optionCode];
    const optionPrice = (optionInfo && parseInt(optionInfo.price)) || 0;

    totalCost += optionPrice * parseInt(option.CountInfo.Count);

    const childMenuInfo = option.MenuInfo;

    if (childMenuInfo) {
      childMenuInfo.forEach((childOption) => {
        const childOptionCode = childOption.MenuCode;
        const childOptionInfo = menuList[childOptionCode];
        const childOptionPrice = (childOptionInfo && parseInt(childOptionInfo.price)) || 0;

        totalCost += childOptionPrice * parseInt(childOption.CountInfo.Count);
      });
    }
  });

  return totalCost;
};
