import AnimatedRoutes from 'components/AnimatedRoutes';
import { useEffect, useLayoutEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import {
  animationRippleClickEvent,
  checkIsMember,
  decrypt,
  getQueryParam,
  isDeviceIOS,
  SESSION_CURRENT_GENRE_CODE,
  SESSION_CURRENT_MENUBOOK_ID,
  SESSION_CUSTOMER_ID_KEY,
  SESSION_CUSTOMER_TOKEN_KEY,
  SessionStorage,
} from 'utils';
import './App.scss';
import { GENRE_SEARCH_MENU_ID } from './constants';

function appStart(customer_id: number, token: string): number {
  SessionStorage.setItem(SESSION_CUSTOMER_ID_KEY, customer_id.toString());
  SessionStorage.setItem(SESSION_CUSTOMER_TOKEN_KEY, token);
  return 1;
}

const _global = (window || global) as any;
_global.appStart = appStart;

function initializeSession() {
  const customerId = getQueryParam(SESSION_CUSTOMER_ID_KEY) || '';
  const customerToken = getQueryParam(SESSION_CUSTOMER_TOKEN_KEY) || '';

  if (!SessionStorage.getItem(SESSION_CUSTOMER_ID_KEY)) {
    SessionStorage.setItem(SESSION_CUSTOMER_ID_KEY, decrypt(customerId));
  }

  if (!SessionStorage.getItem(SESSION_CUSTOMER_TOKEN_KEY)) {
    SessionStorage.setItem(SESSION_CUSTOMER_TOKEN_KEY, customerToken);
  }
}

function handlePageVisibility() {
  if (isDeviceIOS() && document.visibilityState === 'visible') {
    location.reload();
  }
}

function setupIOSFixes() {
  const updateAppHeight = () => {
    const doc = document.documentElement;

    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    doc.style.setProperty('--position-modal-top', `0px`);
    doc.style.setProperty('--main-page-scrolltop', `0p`);
  };

  const disableZoom = (event: any) => {
    if (isDeviceIOS() && event.scale !== 1) {
      event.preventDefault();
    }
  };

  window.addEventListener('resize', updateAppHeight);
  document.addEventListener('dblclick', disableZoom, { passive: false });
  document.addEventListener('touchmove', disableZoom, { passive: false });

  updateAppHeight();
  smoothscroll.polyfill();

  if (isDeviceIOS()) {
    window.addEventListener('gesturestart', (e) => e.preventDefault());
    window.addEventListener('gesturechange', (e) => e.preventDefault());
    window.addEventListener('gestureend', (e) => e.preventDefault());
  }

  return () => {
    window.removeEventListener('resize', updateAppHeight);
    document.removeEventListener('dblclick', disableZoom);
    document.removeEventListener('touchmove', disableZoom);
  };
}

const App = () => {
  initializeSession();

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', handlePageVisibility);
    return () => document.removeEventListener('visibilitychange', handlePageVisibility);
  }, []);

  useEffect(() => {
    const cleanupIOSFixes = setupIOSFixes();

    document.addEventListener('click', animationRippleClickEvent);

    return () => {
      cleanupIOSFixes();
      document.removeEventListener('click', animationRippleClickEvent);

      if (SessionStorage.getItem(SESSION_CURRENT_GENRE_CODE) === GENRE_SEARCH_MENU_ID) {
        SessionStorage.removeItem(SESSION_CURRENT_MENUBOOK_ID);
        SessionStorage.removeItem(SESSION_CURRENT_GENRE_CODE);
      }
    };
  }, []);

  const classMember = checkIsMember() ? 'member' : 'non-member';

  return (
    <div id="smart-order" className={classMember}>
      <AnimatedRoutes />
    </div>
  );
};

export default App;
