import { createContext, ElementType, ReactNode, useCallback, useState } from 'react';

export interface GlobalModalComponent {
  component: ElementType;
}

interface GlobalModalContextProps {
  showGlobalModal: (component: ElementType) => void;
  closeGlobalModal: () => void;
  closeAllGlobalModals: () => void;
}

const GlobalModalContext = createContext<GlobalModalContextProps | null>(null);

const GlobalModalProvider = ({ children }: { children: ReactNode }) => {
  const [modals, setModals] = useState<GlobalModalComponent[]>([]);

  const showGlobalModal = useCallback((component: ElementType) => {
    setModals((prevModals) => [...prevModals, { component }]);
  }, []);

  const closeGlobalModal = useCallback(() => {
    setModals((prevModals) => prevModals.slice(0, prevModals.length - 1));
  }, []);

  const closeAllGlobalModals = useCallback(() => {
    setModals([]);
  }, []);

  return (
    <GlobalModalContext.Provider value={{ showGlobalModal, closeGlobalModal, closeAllGlobalModals }}>
      {children}
      {modals.map((modal, index) => {
        const Modal = modal.component;
        return <Modal key={index} />;
      })}
    </GlobalModalContext.Provider>
  );
};

export { GlobalModalProvider };
export default GlobalModalContext;
