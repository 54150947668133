import { IMenu, IMenuItemInfo } from 'apis/types';

/**
 * Retrieves the dish information from the menuInfo object based on the provided menuItemCode.
 * @param menuInfo - The menu information object containing dish details.
 * @param menuItemCode - The code of the dish to retrieve its information.
 * @returns The dish information (IMenuItemInfo) if found, otherwise undefined.
 */
export const getDishInfo = (menuInfo: IMenu, menuItemCode: string): IMenuItemInfo => {
  const dishInfo = menuInfo[menuItemCode];

  return dishInfo;
};
