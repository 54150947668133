import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { delayExecution, imgFullPath } from 'utils';
import { attrLang } from 'utils/attrLang';
import { IBarInfoItem } from '../../apis/types';
import './MenuBarOrderSuccessModal.scss';

export interface MenuBarOrderSuccessModalProps {
  barInfoItem: IBarInfoItem;
  onClose?: () => void;
}

const MenuBarOrderSuccessModal = ({ barInfoItem, onClose }: MenuBarOrderSuccessModalProps) => {
  const { t } = useTranslation();
  const { closeGlobalModal } = useGlobalModal();

  const imgPath = imgFullPath(barInfoItem.img);
  const msg = attrLang(barInfoItem, 'msg');
  const title = attrLang(barInfoItem, 'title');

  useEffect(() => {
    return () => {
      onClose?.();
    };
  }, []);

  const handleCloseModal = () => {
    delayExecution(() => {
      playSound('close');
      onClose?.();
      closeGlobalModal();
    });
  };

  return (
    <div
      className="modal alert-modal menu-bar-order-success-modal"
      data-bs-backdrop="static"
      tabIndex={-1}
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '330px', margin: '0 auto' }}>
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center fw-bold mb-1">{t('Cart.order_success')}</div>
            <div className="menu-bar-order-success-title text-center" dangerouslySetInnerHTML={{ __html: title }}></div>
            <div className="text-center my-1">
              <img width={300} height={200} src={imgPath} />
            </div>
            <div className="fw-bold text-center small" dangerouslySetInnerHTML={{ __html: msg }}></div>
          </div>
          <div className="dialog-footer d-flex justify-content-center">
            <button className="ara-btn ara-btn-outline single" onClick={handleCloseModal}>
              <div>{t('App.close_modal')}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MenuBarOrderSuccessModal);
