/* eslint-disable */
import { getServerTime } from 'apis/Api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { selectAllYouCanEatState } from 'store/slices/allYouCanEatSlice';
import { selectEatingWayId, selectMenu, selectTenpoInfo, selectTimeFrameLunch } from 'store/slices/mainSlice';
import { menuInfoActions, selectCurrentGenreId, selectedMenubookId, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { selectTime, timeActions } from 'store/slices/timeSlice';
import {
  checkGenreAvailable,
  composeMenubookList,
  getInitMenubookId,
  getTimeFrameId,
  handleErrorResponse,
  SESSION_CURRENT_TIME,
  SESSION_MENU_CONTENT_BODY_SCROLLTOP,
  SESSION_WARING_NIGHT_OT_TIME,
  SessionStorage,
} from 'utils';

import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { CHECK_TIME_INTERVAL, RES_CODE_SUCCESS } from '../constants';
import useInterval from './useInterval';

const useUpdateTimeFrame = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showGlobalModal } = useGlobalModal();
  const navigate = useNavigate();
  const location = useLocation();
  const tenpoInfo = useAppSelector(selectTenpoInfo);
  const timeData = useAppSelector(selectTime);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const timeFrame = getTimeFrameId(
    SessionStorage.getItem(SESSION_CURRENT_TIME) ?? timeData.tm_hh_mm,
    tenpoInfo?.time_frame
  );
  const menuData = useAppSelector(selectMenu);

  const eatingWayId = useAppSelector(selectEatingWayId);
  const menuInfo = useAppSelector(selectMenuInfo);
  const currentGenreId = useAppSelector(selectCurrentGenreId);
  const currentMenubookId = useAppSelector(selectedMenubookId);
  const timeFrameLunch = useAppSelector(selectTimeFrameLunch);
  const currentMenubook = menuInfo.menubook.find((item) => item.menubook_id === currentMenubookId);
  const nightOTWaring = sessionStorage.getItem(SESSION_WARING_NIGHT_OT_TIME);

  const handleUpdateTime = async () => {
    try {
      const timeFrameRes = await getServerTime();
      if (timeFrameRes.code !== RES_CODE_SUCCESS) {
        handleErrorResponse(showGlobalModal, timeFrameRes);
      } else {
        const newTimeFrame = getTimeFrameId(timeFrameRes.data.tm_hh_mm, tenpoInfo?.time_frame).time_frame_id;
        // if (parseTime(timeFrameRes.data.tm_hh_mm) > parseTime('22:05')) {
        //   if (Boolean(nightOTWaring) === false) {
        //     playSound('confirm');
        //     showGlobalModal(() => (
        //       <AlertModal
        //         title={`${t('App.title_night_OT_waring')}`}
        //         message={`${t('App.message_night_OT_waring')}`}
        //         maxWidth="40rem"
        //       />
        //     ));
        //     sessionStorage.setItem(SESSION_WARING_NIGHT_OT_TIME, 'true');
        //   }
        // } else {
        //   sessionStorage.removeItem(SESSION_WARING_NIGHT_OT_TIME);
        // }

        if (newTimeFrame != timeFrame.time_frame_id) {
          dispatch(timeActions.fetchTimeDataSuccess(timeFrameRes));
          const newListMenubook = composeMenubookList(newTimeFrame, menuInfo.menubook);
          const currentMenubooksList = composeMenubookList(timeFrame.time_frame_id, menuInfo.menubook);
          if (
            newListMenubook.map((item) => item.menubook_id).toString() !=
              currentMenubooksList.map((item) => item.menubook_id).toString() &&
            currentMenubook &&
            !currentMenubook.time_frame_id?.includes(newTimeFrame)
          ) {
            const firstMenubookId = getInitMenubookId(newListMenubook, allYouCanEatState);
            // save modeCode and GenreId to SessionStorage and main store
            dispatch(
              menuInfoActions.setCurrentGenreId({
                selectedMenubookId: firstMenubookId,
                genreId: '0',
              })
            );
            playSound('confirm');
            showGlobalModal(() => (
              <AlertModal message={t('MenuList.end_of_time_frame', { timeframeName: timeFrame.name })} />
            ));
            if (location.pathname.includes('/menu-detail')) {
              SessionStorage.setItem(SESSION_MENU_CONTENT_BODY_SCROLLTOP, '0');
              navigate('/menu');
            }
          } else {
            const availableGenreId = checkGenreAvailable(
              menuInfo.menubook.filter((item) => item.menubook_id === currentMenubookId)[0],
              currentGenreId,
              menuData,
              tenpoInfo,
              timeFrameLunch
            )
              ? currentGenreId
              : '0';
            dispatch(
              menuInfoActions.setCurrentGenreId({
                selectedMenubookId:
                  availableGenreId === currentGenreId ? currentMenubookId : newListMenubook[0]?.menubook_id,
                genreId: availableGenreId,
              })
            );
          }
        }
      }
    } catch (error) {
      console.error('useUpdateTime', error);
    }
  };

  useInterval(() => {
    handleUpdateTime();
  }, CHECK_TIME_INTERVAL);
};

export default useUpdateTimeFrame;
