import { MenuInfoDataType } from 'apis/types';

/**
 * Retrieves the submenu group associated with a specific dish code within a given menubook.
 *
 * @param menuInfo - The complete menu information containing the menubook list.
 * @param menubookId - The unique identifier of the menubook to search within.
 * @param dishCode - The code of the dish for which the submenu group is sought.
 *
 * @returns The submenu group or an empty array if not found.
 */
export const getSubmenuGroupFromMenubookId = (menuInfo: MenuInfoDataType, menubookId: string, dishCode: string) => {
  const menubookList = menuInfo.menubook;

  const menubook = menubookList.find((item) => item.menubook_id === menubookId);

  if (menubook && menubook.genre) {
    for (const genre of menubook.genre) {
      for (const subgenre of genre.subgenre) {
        if (subgenre.menu) {
          for (const menu of subgenre.menu) {
            if (menu.poscd === dishCode) {
              return menu.submenugroup;
            }
          }
        }
      }
    }
  }

  return [];
};
