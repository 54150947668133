import DishModal from 'components/DishModal';
import { CartDishModalContext } from 'context/CartDishModalContext';
import { CartStateValue, PortionState } from 'models';
import Dish from 'pages/CartPage/components/Dish';
import { useCallback, useMemo, useState } from 'react';
import { groupOrderBySimilarity } from 'utils';

interface DishGroupProps {
  dishKey: string;
  dishValue: CartStateValue;
}

const DishGroup = ({ dishKey, dishValue }: DishGroupProps) => {
  const { init: dishInitialState, state: dishCurrentState } = dishValue;
  const [menubookId, dishCode] = JSON.parse(dishKey);

  const [selectedDishState, setSelectedDishState] = useState<PortionState>(dishInitialState);

  const [openDishModal, setOpenDishModal] = useState<boolean>(false);

  const showDishModal = useCallback(() => {
    const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
    if (pageWrapper) {
      document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
      pageWrapper.style.overflowY = 'hidden';
    }

    setOpenDishModal(true);
  }, []);

  const uniquePortionQuantityPairs = groupOrderBySimilarity(dishCurrentState);

  const updateSelectedDishState = (newPortionState: PortionState) => {
    setSelectedDishState(newPortionState);
  };

  const ListOfOrder: JSX.Element[] = Object.entries(uniquePortionQuantityPairs).map(
    ([portionStateStringify, quantity], orderIdx) => {
      const portionGroupState = JSON.parse(portionStateStringify);

      const hasEditValue = Object.values(dishInitialState).some((item) => {
        return item.basic.noselectflg !== '1';
      });

      const editTable = Object.keys(dishInitialState).length > 0 && hasEditValue;

      return (
        <Dish
          key={orderIdx}
          editTable={editTable}
          dishKey={dishKey}
          state={portionGroupState}
          quantity={quantity}
          updateSelectedDishState={updateSelectedDishState}
        />
      );
    }
  );

  const closeDishModal = useCallback(() => {
    setOpenDishModal(false);
  }, []);

  const contextValue = useMemo(() => ({ showDishModal }), [showDishModal]);

  return (
    <CartDishModalContext.Provider value={contextValue}>
      {ListOfOrder}
      {openDishModal && (
        <DishModal
          type="replace"
          menubookId={menubookId}
          dishCode={dishCode}
          initState={dishCurrentState}
          addedState={selectedDishState}
          onClose={closeDishModal}
        />
      )}
    </CartDishModalContext.Provider>
  );
};

export default DishGroup;
