import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Payload } from 'apis/types';
import { RootState } from 'app/store';
import { AxiosError } from 'axios';
import { ERROR_NOTIFICATION_CODES, RES_CODE_UNAUTHORIZED } from '../../constants';

export interface ErrorNotificationState {
  isOpen: boolean;
  error: AxiosError | null;
  response: Payload | null;
}

const initialState: ErrorNotificationState = {
  isOpen: false,
  error: null,
  response: null,
};

const errorNotificationSlice = createSlice({
  name: 'error_notification',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<AxiosError>) {
      if (ERROR_NOTIFICATION_CODES.includes(Number(action.payload.response?.status))) {
        state.isOpen = true;
        state.error = action.payload;
      }
    },
    setResponse(state, action: PayloadAction<Payload>) {
      const response = action.payload;

      if (response.code === RES_CODE_UNAUTHORIZED) {
        return;
      }

      state.isOpen = true;
      state.response = action.payload;
    },
    hideError(state) {
      state.isOpen = false;
      state.error = null;
      state.response = null;
    },
  },
});

// Actions
export const errorNotificationActions = errorNotificationSlice.actions;

// Selectors
export const selectErrorNotificationIsOpen = (state: RootState) => state.errorNotification.isOpen;
export const selectErrorNotificationError = (state: RootState) => state.errorNotification.error;
export const selectErrorNotificationResponse = (state: RootState) => state.errorNotification.response;

// Reducer
export const errorNotificationReducer = errorNotificationSlice.reducer;
