import { useAppSelector } from 'app/hooks';
import CourseIntroductionModal from 'components/CourseIntroductionModal/CourseIntroductionModal';
import { useRef, useState } from 'react';
import { playSound } from 'sound/Sound';
import { selectedMenubookId, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { Lazy, Swiper as SwiperCore } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SESSION_MENUBOOK_BUTTON_CLICKED } from 'utils/sessionStorage';
import MenubookThumbnail from '../MenubookThumbnail/MenubookThumbnail';
import './scss/MenubookBar.scss';

export interface ModeBarProps {
  mainSwiper?: SwiperCore;
}

function MenubookBar({ mainSwiper }: ModeBarProps) {
  const currentMenubookId = useAppSelector(selectedMenubookId);
  const menuInfo = useAppSelector(selectMenuInfo);

  const [menubookBarSwiper, setMenubookBarSwiper] = useState<SwiperCore>();
  const modeBarRef = useRef(null);

  //state
  const [courseIntroModalState, setCourseIntroModalState] = useState<{
    showCourseIntro: boolean;
    imgList: string[];
  }>({
    showCourseIntro: false,
    imgList: [],
  });
  const { showCourseIntro, imgList } = courseIntroModalState;
  const handleShowCourseIntroduce = (show: boolean, imgList: string[]) => {
    setCourseIntroModalState({ showCourseIntro: show, imgList: imgList });

    const pageWrapper = document.querySelector('.page-wrapper') as HTMLElement;
    if (show) {
      playSound('confirm');

      // detect position of modal to show
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `${pageWrapper.scrollTop}px`);
        pageWrapper.style.overflowY = 'hidden';
      }

      if (modeBarRef.current) {
        (modeBarRef.current as HTMLDivElement).style.zIndex = '1000';
      }
    } else {
      if (pageWrapper) {
        document.documentElement.style.setProperty('--position-modal-top', `0px`);
        pageWrapper.removeAttribute('style');
      }

      if (modeBarRef.current) {
        (modeBarRef.current as HTMLDivElement).style.zIndex = '999';
      }
    }
  };

  const ListOfModes = menuInfo.menubook.map((menubook) => {
    const active = currentMenubookId === menubook.menubook_id;
    return menuInfo.menubook.length >= 4 ? (
      <SwiperSlide key={menubook.menubook_id} style={{ width: 'auto' }}>
        <MenubookThumbnail
          mainSwiper={mainSwiper}
          menubookId={menubook.menubook_id}
          active={active}
          menubookBarSwiper={menubookBarSwiper}
          showCourseIntroduce={handleShowCourseIntroduce}
        />
      </SwiperSlide>
    ) : (
      <MenubookThumbnail
        key={menubook.menubook_id}
        mainSwiper={mainSwiper}
        menubookId={menubook.menubook_id}
        active={active}
        showCourseIntroduce={handleShowCourseIntroduce}
      />
    );
  });

  const findSlideIndex = menuInfo.menubook.findIndex((item) => item.menubook_id === currentMenubookId);

  return (
    <div className="menubook-bar" ref={modeBarRef}>
      {menuInfo.menubook.length >= 4 ? (
        <Swiper
          id="menubook-thumbnail-block"
          style={{ display: 'block', maxWidth: '100%' }}
          autoHeight={false}
          lazy={false}
          watchSlidesProgress={true}
          slideToClickedSlide={false}
          slidesPerView={'auto'}
          centeredSlides={true}
          centeredSlidesBounds={true}
          onSwiper={setMenubookBarSwiper}
          modules={[Lazy]}
          className="menubook-bar-swiper"
          nested
          initialSlide={findSlideIndex > 0 ? findSlideIndex : 0}
          onSlideChange={() => {
            setTimeout(() => {
              const isGenreButtonClicked = Boolean(sessionStorage.getItem(SESSION_MENUBOOK_BUTTON_CLICKED));
              if (isGenreButtonClicked) {
                const indexOfMenubook = menuInfo.menubook.findIndex((item) => item.menubook_id === currentMenubookId);
                menubookBarSwiper?.slideTo(indexOfMenubook);
              }
              sessionStorage.removeItem(SESSION_MENUBOOK_BUTTON_CLICKED);
            }, 300);
          }}
        >
          {ListOfModes}
        </Swiper>
      ) : (
        <div id="menubook-thumbnail-block" className="menubook-bar-wrapper">
          {ListOfModes}
        </div>
      )}
      <CourseIntroductionModal
        show={showCourseIntro}
        imgList={imgList}
        onClose={handleShowCourseIntroduce.bind(null, false, [])}
      />
    </div>
  );
}

export default MenubookBar;
