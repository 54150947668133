import React, { useEffect, useState } from 'react';
import { GENRE_STICKY_NAME_HEIGHT_DEFAULT } from '../../constants';

export interface StickyContainerProps {
  scrollElement: string;
  stickySelector: string;
  children: React.ReactNode;
}

function StickyContainer({ scrollElement, stickySelector, children }: StickyContainerProps) {
  const scrollElementRef = document.querySelector(scrollElement);
  const genreStickyElemenRef = document.getElementById('subgenre-name-sticky');

  const [y, setY] = useState(0);

  const scrollHandler = () => {
    const stickyElements = document.querySelectorAll(stickySelector);

    let scrollType = '';
    const parentScrollTop = scrollElementRef?.scrollTop || 0;
    if (y > parentScrollTop) {
      scrollType = 'up';
    } else if (y <= parentScrollTop) {
      scrollType = 'down';
    }

    setY(parentScrollTop);

    stickyElements.forEach((stickyElement, index) => {
      const offsetTop = (stickyElement as HTMLDivElement).parentElement?.offsetTop || 0;

      if (scrollType === 'down') {
        if (y > GENRE_STICKY_NAME_HEIGHT_DEFAULT && parentScrollTop > offsetTop) {
          if (!stickyElement.classList.contains('entered')) {
            (stickyElement as HTMLDivElement).classList.add('entered');

            const stickyGenreName = stickyElement.innerHTML;
            if (index > 0) {
              (stickyElement as HTMLDivElement).innerHTML =
                (stickyElements[index - 1] as HTMLDivElement).dataset.subgenrename || '';
            } else {
              (stickyElement as HTMLDivElement).innerHTML =
                (stickyElements[0] as HTMLDivElement).dataset.subgenrename || '';
            }

            if (genreStickyElemenRef) {
              genreStickyElemenRef.innerHTML = stickyGenreName;
              (genreStickyElemenRef as HTMLDivElement)?.classList.remove('d-none');
              genreStickyElemenRef.dataset.height = genreStickyElemenRef.clientHeight.toString();
            }
          }
        }
      } else if (scrollType === 'up') {
        if (
          parentScrollTop - Number(genreStickyElemenRef?.dataset.height || GENRE_STICKY_NAME_HEIGHT_DEFAULT) <=
          offsetTop
        ) {
          if (stickyElement.classList.contains('entered')) {
            if (genreStickyElemenRef) {
              if (index > 0) {
                genreStickyElemenRef.innerHTML =
                  (stickyElements[index - 1] as HTMLDivElement).dataset.subgenrename || '';
                genreStickyElemenRef.dataset.height = genreStickyElemenRef.clientHeight.toString();
              } else {
                (genreStickyElemenRef as HTMLDivElement)?.classList.add('d-none');
                genreStickyElemenRef.dataset.height = GENRE_STICKY_NAME_HEIGHT_DEFAULT.toString();
              }
            }

            stickyElement.classList.remove('entered');
            (stickyElement as HTMLDivElement).innerHTML = (stickyElement as HTMLDivElement).dataset.subgenrename || '';
          }
        }
      }
    });
  };

  useEffect(() => {
    setY(scrollElementRef?.scrollTop || 0);
    scrollElementRef?.addEventListener('scroll', scrollHandler);

    return () => {
      scrollElementRef?.removeEventListener('scroll', scrollHandler);
    };
  });

  return <div className="sticky-container">{children}</div>;
}

export default StickyContainer;
