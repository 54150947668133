import {
  AllYouCanEatType,
  HistoryFlagType,
  IDispCondGroup,
  IDispCondMenu,
  IMenuItemInfo,
  IOrderDetail,
  MenuBookGroupInfoType,
} from 'apis/types';
import i18n from 'translation/i18n';
import { attrLang } from './attrLang';

export function getErrorMessage(
  modeCode: string,
  isLunchTime: boolean,
  historyFlag: HistoryFlagType,
  allYouCanEatState: AllYouCanEatType,
  menuData: IMenuItemInfo,
  currentMenubook: MenuBookGroupInfoType,
  outOfStocked: boolean,
  haveAdult: number,
  dispcondMenu?: IDispCondMenu,
  dispcondgroup?: IDispCondGroup,
  orderHistory?: IOrderDetail[]
): string {
  if (!menuData) return '';
  const { t } = i18n;
  let message = '';

  if (outOfStocked) {
    message = t('MenuList.out_of_stock');
  } else if (menuData.callstaff_flg === '1' || (menuData.agelimit_flg === '1' && haveAdult === 0)) {
    message = t('MenuList.call_staff');
  } else {
    const displayCond = menuData.dispcond;
    switch (displayCond) {
      case '1': {
        if (historyFlag.lunch_flg === '0') message = t('MenuList.lunch_dish_needed');
        break;
      }
      case '2': {
        if (historyFlag.setmenu_flg === '0') message = t('MenuList.set_dish_needed');
        break;
      }
      case '3': {
        if (!isLunchTime) message = t('MenuList.lunch_time_only');
        break;
      }
      case '4': {
        if (isLunchTime) message = t('MenuList.lunch_time_excluded');
        break;
      }
      case '5': {
        const groupNames: string[] = [];
        if (dispcondMenu && orderHistory && dispcondgroup) {
          for (const dispcondGroupCode of menuData.dispcond_group) {
            if (checkIsDisplayWithDispcond5(dispcondGroupCode, dispcondMenu, orderHistory) == false) {
              groupNames.push(`「${attrLang(dispcondgroup[dispcondGroupCode], 'lang')}」`);
            }
          }
        }
        const messageTmp: string = t('MenuItemDetail.not_met_dispcond_5', { groupName: groupNames.join('<br/>') });
        if (groupNames.length > 0) message = messageTmp;
        break;
      }
      default: {
        break;
      }
    }
  }

  //check if menu in all-you-can-eat
  if (currentMenubook.course) {
    if (!allYouCanEatState.isShowEatRemainTime) {
      menuData.th_bunruicd === '01' ? (message = t('AllYouCanEat.menu_not_available')) : (message = '');
    } else if (!allYouCanEatState.isShowDrinkRemainTime) {
      menuData.th_bunruicd === '02' ? (message = t('AllYouCanEat.menu_not_available')) : (message = '');
    }
  }

  return message;
}

export function checkIsDisplayWithDispcond5(
  dispcondGroupCode: string,
  dispcondMenu: IDispCondMenu,
  orderHistory: IOrderDetail[]
): boolean {
  for (const menuCode of dispcondMenu[dispcondGroupCode]) {
    const isExisted = checkExistMenuCodeInOrderHistory(menuCode, orderHistory);
    if (isExisted) {
      return true;
    }
  }
  return false;
}

export function checkExistMenuCodeInOrderHistory(menuCode: string, orderHistory: IOrderDetail[]): boolean {
  for (const historyOrder of orderHistory) {
    const orderStates = historyOrder.OrderDetailMenuInfo;
    for (const orderState of orderStates) {
      if (orderState.MenuCode == menuCode) {
        return true;
      } else if (orderState.MenuInfo) {
        const res = orderState.MenuInfo.some((info) => info.MenuCode == menuCode);
        if (res) {
          return true;
        }
      }
    }
  }
  return false;
}
