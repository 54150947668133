import { useAppSelector } from 'app/hooks';
import DishModal from 'components/DishModal';
import { selectMenu, selectSubMenuGroup } from 'store/slices/mainSlice';
import { selectedMenubookId, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { getSubmenuGroupFromMenubookId, initDishState } from 'utils';

interface MenuDetailModalProps {
  menubookId?: string;
  dishCode: string;
  onClose: () => void;
}

const MenuDetailModal = ({ menubookId = undefined, dishCode, onClose }: MenuDetailModalProps) => {
  const currentMenubookId = menubookId ?? useAppSelector(selectedMenubookId);
  const menuList = useAppSelector(selectMenu);
  const subMenuGroupData = useAppSelector(selectSubMenuGroup);
  const menuInfo = useAppSelector(selectMenuInfo);
  const submenuGroupInfo = getSubmenuGroupFromMenubookId(menuInfo, currentMenubookId, dishCode);

  const initState = initDishState(menuList, subMenuGroupData, submenuGroupInfo);

  return (
    <DishModal
      type="add"
      menubookId={currentMenubookId}
      dishCode={dishCode}
      initState={initState}
      addedState={initState[0]}
      onClose={onClose}
    />
  );
};

export default MenuDetailModal;
