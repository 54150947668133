import { getMenuInfo, getServerTime, getThInfo, getThRemainTm } from 'apis/Api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { allYouCanEatActions, selectThMenuData } from 'store/slices/allYouCanEatSlice';
import { selectEatingWayId, selectEatingWaySelect, selectMenu, selectTenpoInfo } from 'store/slices/mainSlice';
import { menuInfoActions, selectMenuBook, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { selectTime, timeActions } from 'store/slices/timeSlice';
import { Swiper as SwiperCore } from 'swiper';
import {
  getAllImagesIntroduction,
  getTimeFrameId,
  handleErrorResponse,
  scrollAnimationTo,
  SESSION_CURRENT_COURSE_CD,
  SESSION_CURRENT_TIME,
  SESSION_MENUBOOK_BUTTON_CLICKED,
} from 'utils';
import { attrLang } from 'utils/attrLang';
import {
  ALL_YOU_CAN_EAT_PROPERTY,
  NO_SELECTION_PROPERTY,
  NO_SELECTION_PROPERTY_NUM,
  RES_CODE_SUCCESS,
  TH_INFO_EMPTY_INFO_CODE,
} from '../../constants';
import './scss/MenubookThumbnail.scss';

export interface MenubookThumbnailProps {
  mainSwiper?: SwiperCore;
  menubookBarSwiper?: SwiperCore;
  menubookId: string;
  active: boolean;
  showCourseIntroduce: (show: boolean, imgList: string[]) => void;
}

function MenubookThumbnail({
  mainSwiper,
  menubookId,
  active,
  menubookBarSwiper,
  showCourseIntroduce,
}: MenubookThumbnailProps) {
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const { showGlobalModal } = useGlobalModal();

  const btnRef = useRef<HTMLButtonElement>(null);

  const menuData = useAppSelector(selectMenu);
  const menuInfo = useAppSelector(selectMenuInfo);
  const menubookInfo = useAppSelector(selectMenuBook);
  const eatingWaySelect = useAppSelector(selectEatingWaySelect);
  const eatingWayDefault = useAppSelector(selectEatingWayId);
  const timeServer = useAppSelector(selectTime);
  const tenpoInfo = useAppSelector(selectTenpoInfo);
  const menubookCourseIntroduction = eatingWaySelect.menubook_course_introduction;
  const timeFrame = getTimeFrameId(
    sessionStorage.getItem(SESSION_CURRENT_TIME) ?? timeServer.tm_hh_mm,
    tenpoInfo?.time_frame
  );
  const thMenuInfo = useAppSelector(selectThMenuData);
  const currrentMenubookInfo = Object.values(menubookInfo).find((item) => item.menubook_id === menubookId);
  const name = attrLang(currrentMenubookInfo, 'lang');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (btnRef.current) btnRef.current.disabled = false;
  }, []);

  const handleClick = async () => {
    sessionStorage.setItem(SESSION_MENUBOOK_BUTTON_CLICKED, 'true');

    if (!currrentMenubookInfo) return;
    let isAllYouCanEat = false;
    if (
      currrentMenubookInfo &&
      currrentMenubookInfo.property !== NO_SELECTION_PROPERTY &&
      currrentMenubookInfo.property !== ALL_YOU_CAN_EAT_PROPERTY
    ) {
      isAllYouCanEat = currrentMenubookInfo.property !== NO_SELECTION_PROPERTY_NUM;
    } else {
      isAllYouCanEat = currrentMenubookInfo.property !== NO_SELECTION_PROPERTY;
    }
    if (isAllYouCanEat) {
      setIsLoading(true);
      const newResponseThInfo = await getThInfo();
      setIsLoading(false);
      if (newResponseThInfo.code !== RES_CODE_SUCCESS) {
        if (newResponseThInfo.code !== TH_INFO_EMPTY_INFO_CODE) {
          handleErrorResponse(showGlobalModal, newResponseThInfo, undefined, () => {
            sessionStorage.removeItem(SESSION_MENUBOOK_BUTTON_CLICKED);
          });
        }
      }
      const responseNewThCourseMenu = newResponseThInfo.data;

      const newThCourseMenu =
        responseNewThCourseMenu === undefined || Object.keys(responseNewThCourseMenu).length === 0
          ? null
          : responseNewThCourseMenu;

      const isShowEatRemainTime =
        newThCourseMenu?.th_flg === '1' && newThCourseMenu?.th_limit !== undefined && newThCourseMenu?.th_limit > 0;
      const isShowDrinkRemainTime =
        newThCourseMenu?.nh_flg === '1' && newThCourseMenu?.nh_limit !== undefined && newThCourseMenu?.nh_limit > 0;

      appDispatch(
        allYouCanEatActions.updateStatus({
          isShowEatRemainTime,
          isShowDrinkRemainTime,
        })
      );
      if (newThCourseMenu === null) {
        //show dialog to introduce course
        const eatingWayInfo = Object.values(eatingWaySelect.eating_way).find(
          (item) => item.eating_way_id === eatingWayDefault.eating_way_id
        );
        if (eatingWayInfo) {
          const menubookCourseAllYouCanEat = eatingWayInfo.menubook_course.filter((course) => course.course_id);
          const imgList = getAllImagesIntroduction(
            timeFrame.time_frame_id,
            menubookCourseIntroduction,
            menubookCourseAllYouCanEat
          );
          imgList.length > 0 && showCourseIntroduce(true, imgList);
          return undefined;
        }
      }
      if (
        newResponseThInfo.data.course_cd !== sessionStorage.getItem(SESSION_CURRENT_COURSE_CD) ??
        thMenuInfo.course_cd
      ) {
        const eatingWayAllYouCanEat = Object.values(eatingWaySelect.eating_way).find((item) =>
          item.course_cd?.includes(newResponseThInfo.data.course_cd!)
        );
        const responseGetMenuInfo = await getMenuInfo(
          Number(eatingWayAllYouCanEat?.eating_way_id),
          Number(newResponseThInfo.data.course_cd)
        );
        appDispatch(allYouCanEatActions.setThInfo(newResponseThInfo.data));

        appDispatch(
          menuInfoActions.initMenuInfoData({ data: responseGetMenuInfo, menuData: menuData, menubookId: menubookId })
        );
        appDispatch(menuInfoActions.setCurrentGenreId({ selectedMenubookId: menubookId, genreId: '0' }));
      } else {
        appDispatch(allYouCanEatActions.setThInfo(newResponseThInfo.data));
        const responseRemainTime = await getThRemainTm();
        const newThCourseMenu =
          responseRemainTime.data === undefined || Object.keys(responseRemainTime).length === 0
            ? null
            : responseRemainTime.data;

        const isShowEatRemainTime =
          newThCourseMenu?.th_sts === '1' &&
          newThCourseMenu?.th_remain_tm !== undefined &&
          newThCourseMenu?.th_remain_tm > 0;
        const isShowDrinkRemainTime =
          newThCourseMenu?.nh_sts === '1' &&
          newThCourseMenu?.nh_remain_tm !== undefined &&
          newThCourseMenu?.nh_remain_tm > 0;

        appDispatch(
          allYouCanEatActions.updateStatus({
            isShowEatRemainTime,
            isShowDrinkRemainTime,
          })
        );
        if (isShowEatRemainTime || isShowDrinkRemainTime) {
          appDispatch(menuInfoActions.setCurrentGenreId({ selectedMenubookId: menubookId, genreId: '0' }));

          const indexOfMenubook = menuInfo.menubook.findIndex((item) => item.menubook_id === menubookId);
          menubookBarSwiper?.slideTo(indexOfMenubook);
          (document.querySelector('.form-search-close-hide') as HTMLElement)?.click();
          scrollAnimationTo(document.querySelector('.page-wrapper') as HTMLElement, 0, 300);
          if (active) {
            mainSwiper?.slideTo(0);
            setTimeout(() => {
              const modeThumbButtons = document.querySelectorAll('.menubook-thumb-button');
              if (modeThumbButtons) {
                modeThumbButtons.forEach((modeThumbButton) => {
                  (modeThumbButton as HTMLButtonElement).disabled = false;
                });
              }
            }, 300);
          }
        }
      }
    } else {
      setIsLoading(true);
      const responseTime = await getServerTime();
      setIsLoading(false);
      appDispatch(timeActions.fetchTimeDataSuccess(responseTime));

      const newTimeFrame = getTimeFrameId(responseTime.data.tm_hh_mm, tenpoInfo?.time_frame);
      const currentMenubook = menuInfo.menubook.find((item) => item.menubook_id === menubookId);
      if (currentMenubook) {
        if (!currentMenubook.time_frame_id?.includes(newTimeFrame.time_frame_id)) {
          if (newTimeFrame.property !== '') {
            playSound('confirm');
            showGlobalModal(() => <AlertModal message={t('App.lunch_menu_unavailable')} />);
          } else {
            playSound('confirm');
            showGlobalModal(() => <AlertModal message={t('MenuList.end_of_time_frame', { timeframeName: name })} />);
          }
          return;
        }
      }
      appDispatch(menuInfoActions.setCurrentGenreId({ selectedMenubookId: menubookId, genreId: '0' }));

      const indexOfMenubook = menuInfo.menubook.findIndex((item) => item.menubook_id === menubookId);
      menubookBarSwiper?.slideTo(indexOfMenubook);
      (document.querySelector('.form-search-close-hide') as HTMLElement)?.click();
      scrollAnimationTo(document.querySelector('.page-wrapper') as HTMLElement, 0, 300);
      if (active) {
        mainSwiper?.slideTo(0);
        setTimeout(() => {
          const modeThumbButtons = document.querySelectorAll('.menubook-thumb-button');
          if (modeThumbButtons) {
            modeThumbButtons.forEach((modeThumbButton) => {
              (modeThumbButton as HTMLButtonElement).disabled = false;
            });
          }
        }, 300);
      }
    }
    // save modeCode and categoryCode to SessionStorage and main store

    // log({
    //   op_type: LOG_OP_TYPE_MODE_SWITCHING,
    //   op_detail: {
    //     mode: code,
    //   },
    // });
    sessionStorage.removeItem(SESSION_MENUBOOK_BUTTON_CLICKED);
    playSound('mode');
  };
  return (
    <button
      ref={btnRef}
      className={`menubook-thumb-button ${active ? 'active' : ''}`}
      onClick={!isLoading ? handleClick : undefined}
    >
      {isLoading ? (
        <div className="spinner-border menubook-thumb-button-loading" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        name
      )}
    </button>
  );
}

export default MenubookThumbnail;
