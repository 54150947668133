import { IMenuItemInfo } from 'apis/types';
import { PortionState } from 'models';

/**
 * Calculates the cost of a single order based on the provided order state and dish information.
 *
 * @param orderState - An object representing the state of a dish order.
 * @param dishInfo - An IMenuItemInfo object containing information about the dish, including its base price and option information.
 *
 * @returns The total cost of the order based on the provided order state and dish information.
 */
export const getOrderCost = (orderState: PortionState, dishInfo: IMenuItemInfo): number => {
  const basePrice = (dishInfo && parseInt(dishInfo.price)) || 0;

  let totalCost = basePrice;

  Object.values(orderState).forEach((group) => {
    const detailList = group.detail;

    Object.values(detailList).forEach((detail) => {
      const optionInfo = detail.optionInfo;
      const quantity = detail.quantity;
      const optionChecked = detail.checked;

      const optionPrice = optionChecked ? parseInt(optionInfo.price) || 0 : 0;
      totalCost += optionPrice * quantity;

      const childInfor = detail.childInfor;

      if (childInfor && detail.checked) {
        Object.values(childInfor).forEach((childGroup) => {
          const childDetailList = childGroup.detail;

          Object.values(childDetailList).forEach((childDetail) => {
            const childOptionInfo = childDetail.optionInfo;
            const childQuantity = childDetail.quantity;
            const childOptionChecked = childDetail.checked;

            const childOptionPrice = childOptionChecked ? parseInt(childOptionInfo.price) || 0 : 0;
            totalCost += childOptionPrice * childQuantity;
          });
        });
      }
    });
  });

  return totalCost;
};
