import GlobalModalContext from 'components/GlobalModal/GlobalModalContext';
import { useContext } from 'react';

const useGlobalModal = () => {
  const context = useContext(GlobalModalContext);

  if (!context) {
    throw new Error('useGlobalModal must be used within a GlobalModalProvider');
  }

  return context;
};

export default useGlobalModal;
