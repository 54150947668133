/* eslint-disable */
import React from 'react';

import { GenreGroupMenuBookInfoType, SubgenreGroupInfoType } from 'apis/types';
import { useAppSelector } from 'app/hooks';
import SubGenreMenu from 'components/SubGenreMenu/SubGenreMenu';
import { selectMenuInfo } from 'store/slices/menuInfoSlice';
import { getMenuAllYouCanEat } from 'utils';
import './scss/MenuList.scss';

export interface MenuListProps {
  currentMenubookId: string;
  genreId: string;
  // searchPOSCD: string[] | null;
}

function MenuList({ currentMenubookId, genreId }: MenuListProps) {
  const menuInfo = useAppSelector(selectMenuInfo);
  const currentMenubook = menuInfo.menubook.find((item) => item.menubook_id === currentMenubookId);
  let genre: GenreGroupMenuBookInfoType[] = [];
  if (currentMenubook) {
    genre =
      currentMenubook?.course && currentMenubook !== null
        ? getMenuAllYouCanEat(currentMenubook)
        : currentMenubook.genre ?? [];
  }
  const currentGenreMenu = genre!.find((item) => item.genre_id === genreId);
  // const couponCategory = menuInfo.category[categoryCode]?.categorynm === 'クーポン';

  // useEffect(() => {
  //   if (couponCategory) {
  //     appDispatch(customerQuantityActions.fetchCustomerQuantityData());
  //   }
  // }, [couponCategory]);

  const ListOfGenres =
    currentGenreMenu &&
    currentGenreMenu.subgenre.map((subGenreInfo: SubgenreGroupInfoType) => {
      return (
        <SubGenreMenu
          key={subGenreInfo.subgenre_id}
          menubookId={currentMenubookId}
          currentMenubook={currentMenubook}
          genreId={genreId}
          subgenreId={subGenreInfo.subgenre_id}
          genreSub={subGenreInfo.menu}
        />
      );
    });

  return <div className="menu-list-item">{ListOfGenres}</div>;
}

export default React.memo(MenuList);
