import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AraArrowLeftIcon, AraLogoIcon } from 'theme/icons';
import './PageHeader.scss';

interface PageHeaderProps {
  handleGoBack: () => void;
}

const PageHeader = ({ handleGoBack }: PageHeaderProps) => {
  const { t } = useTranslation();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleBack = () => {
    if (buttonRef.current) {
      if (buttonRef.current.disabled) {
        return;
      }

      buttonRef.current.disabled = true;
    }

    handleGoBack();
  };

  return (
    <div className="cart-header">
      <button className="area-turn-back" ref={buttonRef} onClick={handleBack}>
        <AraArrowLeftIcon />
        <span className="text">{t('Cart.turn_back')}</span>
      </button>
      <div className="logo">
        <AraLogoIcon />
      </div>
    </div>
  );
};

export default PageHeader;
