import { getOrderHistory, getStartData } from 'apis/Api';
import { IOrderHistory, IStartData } from 'apis/types';
import { handleHttpError, handleResponseError } from 'app/rootSaga';
import { AxiosError } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { customerQuantityActions } from 'store/slices/customerQuantitySlice';
import { mainActions } from 'store/slices/mainSlice';
import { timeActions } from 'store/slices/timeSlice';
import { RES_CODE_SUCCESS } from '../../constants';

function* fetchStartData() {
  try {
    const response: IStartData = yield call(getStartData);

    if (response.code !== RES_CODE_SUCCESS) {
      yield handleResponseError(response);
    } else {
      // init customer quantity data
      yield put(customerQuantityActions.fetchCustomerQuantityData());
      // init main data
      yield put(mainActions.fetchStartDataSuccess(response));
      yield put(timeActions.fetchTimeData());
      // init order history data
      yield put(mainActions.fetchOrderHistoryData());
    }
  } catch (error) {
    console.log('error', error);
    yield put(mainActions.fetchStartDataFailure());
    yield handleHttpError(error as AxiosError);
  }
}

function* fetchOrderHistoryData() {
  try {
    const response: IOrderHistory = yield call(getOrderHistory);

    if (response.code !== RES_CODE_SUCCESS) {
      yield handleResponseError(response);
    } else {
      yield put(mainActions.fetchOrderHistoryDataSuccess(response));
    }
  } catch (error) {
    console.log('error', error);
    yield put(mainActions.fetchOrderHistoryDataFailed());
    yield handleHttpError(error as AxiosError);
  }
}

export default function* mainSaga() {
  yield takeLatest(mainActions.fetchStartData.type, fetchStartData);
  yield takeLatest(mainActions.fetchOrderHistoryData.type, fetchOrderHistoryData);
}
