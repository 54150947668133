import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { useEffect } from 'react';
import { playSound } from 'sound/Sound';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AraTimesIcon } from 'theme/icons';
import { CAMPAIGN_BANNER_TRANSITION_TIME, TAP_ANIMATION_TIME } from '../../constants';
import './scss/CourseIntroductionModal.scss';
interface CourseIntroduceModalProps {
  show?: boolean;
  imgList: string[];
  onClose?: () => void;
}

const CourseIntroduceModal = ({ show, imgList, onClose }: CourseIntroduceModalProps) => {
  const { closeGlobalModal } = useGlobalModal();

  useEffect(() => {
    // action on remove from DOM
    return () => {
      onClose?.();
    };
  }, []);

  const handleClose = () => {
    setTimeout(() => {
      playSound('close');
      onClose?.();
      closeGlobalModal();
    }, TAP_ANIMATION_TIME / 2);
  };

  const listOfCourseIntroduceThumbnails: JSX.Element[] = [];
  imgList.forEach((img, index) => {
    listOfCourseIntroduceThumbnails.push(
      <SwiperSlide key={`introduce-image-${index}`} className="introduce-thumb">
        <img src={img} width={644} height={458} alt="" className="swiper-lazy1 introduce-thumb-image" />
      </SwiperSlide>
    );
  });

  return show ? (
    <div className=" course-intro-modal modal" data-bs-backdrop="static" tabIndex={-1} style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '644px' }}>
        <div className="modal-content border-0">
          <a className="modal-btn-close-wrapper" aria-label="Close" onClick={handleClose}>
            <span className="modal-btn-close">
              <AraTimesIcon />
            </span>
          </a>
          <div className="course-intro-modal-body">
            <div className="intro-image text-center">
              {listOfCourseIntroduceThumbnails.length > 0 && (
                <Swiper
                  style={{ display: 'block', maxWidth: '100%' }}
                  autoHeight={true}
                  preloadImages={true}
                  loop={listOfCourseIntroduceThumbnails.length > 1}
                  lazy={false}
                  pagination={{
                    clickable: false,
                  }}
                  slidesPerView="auto"
                  centeredSlides={true}
                  spaceBetween={0}
                  autoplay={{ delay: CAMPAIGN_BANNER_TRANSITION_TIME }}
                  modules={[Pagination]}
                >
                  {show && listOfCourseIntroduceThumbnails}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default CourseIntroduceModal;
