import ErrorPage from 'pages/ErrorPage';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCustomerId, getToken } from 'utils';

interface ProtectedPageProps {
  children: ReactNode;
}

const ProtectedPage = ({ children }: ProtectedPageProps) => {
  const [active, setActive] = useState(true);
  const { t } = useTranslation();

  const checkAppActive = useCallback(() => {
    const customerId = getCustomerId();
    const token = getToken();

    if (customerId === -1 || !token) {
      setActive(false);
    }
  }, []);

  useEffect(() => {
    checkAppActive();

    window.addEventListener('storage', checkAppActive);

    return () => {
      window.removeEventListener('storage', checkAppActive);
    };
  }, []);

  return active ? <>{children}</> : <ErrorPage message={t('App.app_not_launch')} />;
};

export default ProtectedPage;
