import { useAppDispatch, useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import ConfirmModal from 'components/ConfirmModal';
import Portion from 'components/DishModal/components/Portion';
import dishReducer, { ActionType } from 'components/DishModal/reducer/dishReducer';
import DishPrice from 'components/DishPrice';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import PortionQuantitySelector from 'components/PortionQuantitySelector';
import { CartStateData, PortionState, PortionStateItem } from 'models';
import { createContext, Dispatch, useReducer, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { selectIsShowRemainAllYouCanEat } from 'store/slices/allYouCanEatSlice';
import { cartActions, selectCartState } from 'store/slices/cartSlice';
import { selectCustomerQuantity } from 'store/slices/customerQuantitySlice';
import { selectCouponInfo, selectMenu, selectOrderHistoryData } from 'store/slices/mainSlice';
import { selectMenuBook, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { AraTimesIcon } from 'theme/icons';
import {
  checkIsExceedAllowQuantityOrder,
  countCouponUsed,
  delayExecution,
  getDishCost,
  getDishInfo,
  getListCodeMenuBuffet,
  getListMenubookIdIsNotBuffet,
  getSubmenuGroupFromMenubookId,
  imgFullPath,
  isDishInCouponListFullCode,
  scrollAnimationTo,
  stripHtml,
  updateMainPageScrollTop,
} from 'utils';
import { attrLang } from 'utils/attrLang';
import { MAX_COUPON_PER_PERSON_PER_ITEM, REQUIRED_SELECT_SUBMENUGROUP } from '../../constants';
import './DishModal.scss';

interface DishModalProps {
  type: 'add' | 'replace';
  menubookId: string;
  dishCode: string;
  initState: PortionState[];
  addedState: PortionState;
  onClose: () => void;
}

export const DishDispatchContext = createContext<Dispatch<ActionType>>(() => undefined);

const DishModal = ({ type, menubookId, dishCode, initState, addedState, onClose }: DishModalProps) => {
  const { t } = useTranslation();
  const { showGlobalModal } = useGlobalModal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dishState, dishDispatch] = useReducer(dishReducer, initState);
  const buttonAddToCartRef = useRef<HTMLButtonElement>(null);

  const menuList = useAppSelector(selectMenu);
  const menubookList = useAppSelector(selectMenuBook);
  const cartState = useAppSelector(selectCartState);
  const customerQuantity = useAppSelector(selectCustomerQuantity);
  const couponInfoList = useAppSelector(selectCouponInfo);
  const orderHistoryData = useAppSelector(selectOrderHistoryData);
  const menuInfo = useAppSelector(selectMenuInfo);
  const isShowRemainAllYouCanEat = useAppSelector(selectIsShowRemainAllYouCanEat);

  const submenuGroupInfo = getSubmenuGroupFromMenubookId(menuInfo, menubookId, dishCode);
  const subMenuGroupCodes = submenuGroupInfo?.map((group) => group.submenugroup_cd);
  const dishInfo = getDishInfo(menuList, dishCode);
  const imgSrc = imgFullPath(dishInfo?.img);
  const dishName = attrLang(dishInfo, 'lang');
  const dishDesc = attrLang(dishInfo, 'desp');
  const dishPrice = parseInt(dishInfo?.price);
  const totalCost = getDishCost(dishState, dishInfo);
  const itemQuantity = dishState.length;

  const listMenuBookIdIsNotBuffet = getListMenubookIdIsNotBuffet(menubookList);

  function getTop(el: any): number {
    if (el.className.includes('modal-body')) {
      return el.offsetTop;
    }
    return el.offsetTop + (el.offsetParent ? getTop(el.offsetParent) : 0);
  }
  function getParentCollasp(el: any): HTMLElement {
    if (el.className.includes('collapse')) {
      return el;
    }
    return getParentCollasp(el.parentElement);
  }

  const handleWarningModal = (warningMessage: Set<string>, warningGroupId: string) => {
    showGlobalModal(() => (
      <AlertModal
        message={Array.from(warningMessage).join('')}
        onClose={() => {
          if (buttonAddToCartRef.current) {
            buttonAddToCartRef.current.disabled = false;
          }
        }}
      />
    ));

    if (warningGroupId !== '') {
      const elementId = warningGroupId
        .split('-')
        .map((item, idx) => {
          if (idx < 5) {
            return item;
          }
        })
        .filter(Boolean)
        .join('-');
      const optionGroupEl = document.getElementById(elementId);
      if (optionGroupEl) {
        const groupParent = getParentCollasp(optionGroupEl);
        let timeDelay = 0;
        if (!groupParent?.classList.contains('show')) {
          const orderNameEl = groupParent?.parentElement?.querySelector('.order-name')?.parentElement;
          if (orderNameEl) {
            (orderNameEl as HTMLElement).click();
            timeDelay = 400;
          }
        }
        if (optionGroupEl.className === 'child-option-group') {
          const orderNameEl = optionGroupEl.querySelector('.collapse-child-button');
          if (orderNameEl) {
            const defaultChildList = orderNameEl.parentElement?.querySelector('.default-list');
            if (!defaultChildList?.classList.contains('show')) {
              (orderNameEl as HTMLElement).click();
              timeDelay = 400;
            }
          }
        }
        setTimeout(() => {
          const scrolltopElem =
            (optionGroupEl.className === 'child-option-group' ? getTop(optionGroupEl) - 2 : optionGroupEl.offsetTop) -
            optionGroupEl.scrollTop;
          scrollAnimationTo(document.querySelector('.dish-modal .modal-body') as HTMLElement, scrolltopElem, 300);
        }, timeDelay);
      }
    }
  };

  const handleAddToCart = () => {
    if (buttonAddToCartRef.current) {
      if (buttonAddToCartRef.current.disabled) {
        return;
      }

      buttonAddToCartRef.current.disabled = true;
    }

    delayExecution(() => {
      playSound('add');

      // todo: check if exceed allow quantity order
      const isCheckExceedAllowQuantityOrder = checkIsExceedAllowQuantityOrder(
        menubookId,
        menubookList,
        cartState,
        1,
        customerQuantity
      );

      if (isCheckExceedAllowQuantityOrder) {
        playSound('error');
        showGlobalModal(() => (
          <AlertModal
            message={t('MenuItemDetail.exceed_quantity_warning')}
            onClose={() => {
              if (buttonAddToCartRef.current) {
                buttonAddToCartRef.current.disabled = false;
              }
            }}
          />
        ));

        return;
      }

      // todo: check exceed coupon
      if (isDishInCouponListFullCode(dishInfo, couponInfoList)) {
        const maxCouponQuantity = customerQuantity * MAX_COUPON_PER_PERSON_PER_ITEM;
        const couponUsed = countCouponUsed(menuList, couponInfoList, cartState, orderHistoryData);

        if (couponUsed + 1 > maxCouponQuantity) {
          playSound('error');

          showGlobalModal(() => (
            <AlertModal
              message={t('App.max_coupon_num_exceeded')}
              onClose={() => {
                if (buttonAddToCartRef.current) {
                  buttonAddToCartRef.current.disabled = false;
                }
              }}
            />
          ));

          return;
        }
      }

      // todo: check option menu required
      const warningMessage = new Set<string>();
      let warningGroupId = '';

      Object.entries(dishState).forEach(([orderIdx, orderItem]) => {
        subMenuGroupCodes?.forEach((subMenuGroupCode: string) => {
          Object.entries(orderItem).forEach(([itemGroupCode, itemGroup]) => {
            const parentKousenum = parseInt(itemGroup.basic.kousenum);
            if (
              subMenuGroupCode === itemGroup.basic.cd &&
              itemGroup.basic.selection_condition === REQUIRED_SELECT_SUBMENUGROUP
            ) {
              const warningGroup = validateGroupSelection(
                t,
                itemGroup,
                parentKousenum,
                warningMessage,
                orderIdx,
                itemGroupCode,
                dishCode,
                false
              );

              if (warningGroup && !warningGroupId) {
                warningGroupId = warningGroup;
              }
            }
            Object.values(itemGroup.detail).forEach((item) => {
              if (item.checked && item.childInfor) {
                Object.values(item.childInfor).forEach((child) => {
                  if (child.basic.selection_condition === REQUIRED_SELECT_SUBMENUGROUP) {
                    const childKousenum = parseInt(child.basic.kousenum);
                    const warningChildGroup = validateGroupSelection(
                      t,
                      child,
                      childKousenum,
                      warningMessage,
                      orderIdx,
                      child.basic.cd,
                      dishCode,
                      true,
                      itemGroup
                    );

                    if (warningChildGroup && !warningGroupId) {
                      warningGroupId = warningChildGroup;
                    }
                  }
                });
              }
            });
          });
        });
      });

      if (warningMessage.size > 0) {
        handleWarningModal(warningMessage, warningGroupId);
        return;
      }

      // todo: handle when remain buffet available
      if (isShowRemainAllYouCanEat) {
        const listCodeMenuBuffet = getListCodeMenuBuffet(menuInfo, menubookList);

        if (!listCodeMenuBuffet.includes(dishCode) && parseInt(dishInfo.price) !== 0) {
          showConfirmationDialog(t('MenuItemDetail.pay_menu_in_you_can_eat_time_confirm_message'), addToCartAction);
          return;
        } else {
          if (parseInt(dishInfo.price) > 0) {
            showConfirmationDialog(t('AllYouCanEat.warning_paid_menu'), addToCartAction);
            return;
          }
        }
      }

      // todo: add to cart action
      addToCartAction();
    });
  };

  const showConfirmationDialog = (message: string, onAgree: () => void) => {
    playSound('confirm');

    showGlobalModal(() => (
      <ConfirmModal
        message={message}
        onAgree={onAgree}
        onClose={() => {
          if (buttonAddToCartRef.current) {
            buttonAddToCartRef.current.disabled = false;
          }
        }}
      />
    ));

    return;
  };

  const addToCartAction = () => {
    const menubookIdAndDishCode = JSON.stringify([menubookId, dishCode]);

    const dishData: CartStateData = {
      [menubookIdAndDishCode]: {
        menuItemInfo: dishInfo,
        init: addedState,
        state: dishState,
      },
    };

    if (type === 'add') {
      dispatch(cartActions.addItemToCart(dishData));
    } else {
      dispatch(cartActions.replaceItemInCart(dishData));
    }

    onClose?.();

    if (type === 'add') {
      updateMainPageScrollTop();

      navigate('/cart', { replace: true });

      if (buttonAddToCartRef.current) {
        buttonAddToCartRef.current.disabled = false;
      }
    }
  };

  const closeModal = () => {
    delayExecution(() => {
      playSound('close');

      const pageWrapperElem = document.querySelector('.page-wrapper') as HTMLDivElement;

      if (pageWrapperElem) {
        pageWrapperElem.removeAttribute('style');
        document.documentElement.style.setProperty('--position-modal-top', '0px');
      }

      onClose?.();
    });
  };

  return (
    <DishDispatchContext.Provider value={dishDispatch}>
      <div className="modal dish-modal" tabIndex={-1} data-bs-backdrop="static" style={{ display: 'block' }}>
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <>
              <a className="modal-btn-close-wrapper" onClick={closeModal}>
                <span className="modal-btn-close">
                  <AraTimesIcon />
                </span>
              </a>
              <div className="modal-body">
                <LazyLoadImage
                  wrapperClassName="d-block"
                  className="img-fluid rounded-start w-100"
                  alt="item_image"
                  effect="opacity"
                  src={imgSrc}
                />

                <div className="dish-name">{stripHtml(dishName)}</div>
                {dishDesc && dishDesc.length > 0 && (
                  <div className="dish-description" dangerouslySetInnerHTML={{ __html: dishDesc }} />
                )}
                {listMenuBookIdIsNotBuffet.includes(menubookId) && (
                  <div className="dish-price-section">
                    <DishPrice price={dishPrice} />
                  </div>
                )}
                {dishState.map((orderState, idx) => {
                  return (
                    <Portion
                      key={idx}
                      orderState={orderState}
                      dishCode={dishCode}
                      menubookId={menubookId}
                      orderIdx={idx}
                      portionQuantity={dishState.length}
                    />
                  );
                })}
              </div>
              <div className="dish-footer">
                <div className="dish-footer-detail">
                  <PortionQuantitySelector
                    menubookId={menubookId}
                    dishCode={dishCode}
                    addedState={addedState}
                    initialQuantity={initState.length}
                    quantity={itemQuantity}
                    type={type}
                  />
                  <DishPrice price={totalCost} />
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="ara-btn ara-btn-dark add-cart-btn"
                    ref={buttonAddToCartRef}
                    onClick={handleAddToCart}
                  >
                    {t('MenuItemDetail.add_to_cart')}
                  </button>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </DishDispatchContext.Provider>
  );
};

export default DishModal;

const validateGroupSelection = (
  t: TFunction<'translation', undefined>,
  itemGroup: PortionStateItem,
  kousenum: number,
  warningMessage: Set<string>,
  orderIdx: string,
  itemGroupCode: string,
  dishCode: string,
  isChild: boolean,
  parentItemGroup?: PortionStateItem
): string => {
  if (kousenum === 1) {
    if (itemGroup.selected.length !== kousenum) {
      warningMessage.add(
        t('MenuItemDetail.select_more_items_message', { '0': attrLang(itemGroup.basic, 'lang'), '1': '' })
      );

      return `option-group-${dishCode}-${orderIdx}-${itemGroupCode}`;
    }
  } else if (kousenum > 1) {
    let totalItem = 0;

    Object.entries(itemGroup.detail).forEach(([, optionDetail]) => {
      if (optionDetail.checked) {
        totalItem += optionDetail.quantity;
      }
    });
    if (isChild && parentItemGroup) {
      let parentItemToal = 0;
      Object.entries(parentItemGroup.detail).forEach(([, optionDetail]) => {
        if (optionDetail.checked) {
          parentItemToal += optionDetail.quantity;
        }
      });
      if (totalItem / parentItemToal !== kousenum) {
        warningMessage.add(
          t('MenuItemDetail.select_more_items_message', {
            '0': attrLang(itemGroup.basic, 'lang'),
            '1': `${kousenum} ${t('MenuItemDetail.kind')}`,
          })
        );

        return `option-group-${dishCode}-${orderIdx}-${itemGroupCode}`;
      }
    } else {
      if (totalItem !== kousenum) {
        warningMessage.add(
          t('MenuItemDetail.select_more_items_message', {
            '0': attrLang(itemGroup.basic, 'lang'),
            '1': `${kousenum} ${t('MenuItemDetail.kind')}`,
          })
        );

        return `option-group-${dishCode}-${orderIdx}-${itemGroupCode}`;
      }
    }
  }

  return '';
};
