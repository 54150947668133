import { GenreGroupMenuBookInfoType, IMenu, ITenpoInfo, MenuBookGroupInfoType } from 'apis/types';
import { getMenuAllYouCanEat, getTimeFrameId, SESSION_CURRENT_TIME, SessionStorage } from 'utils';

export function checkGenreAvailable(
  menuInfo: MenuBookGroupInfoType,
  genreId: string,
  menuData?: IMenu,
  tenpoInfo?: ITenpoInfo,
  timeFrameLunch?: string[]
): boolean {
  let genreMenu: GenreGroupMenuBookInfoType[] = [];
  let totalSubMenu = 0;
  if (menuInfo?.course && menuInfo.course !== null) {
    genreMenu = getMenuAllYouCanEat(menuInfo);
  } else {
    genreMenu = menuInfo.genre ?? [];
  }

  if (genreMenu.some((item) => item.genre_id === genreId)) {
    const genreMenuItem = genreMenu.find((item) => item.genre_id === genreId);
    if (genreMenuItem && genreMenuItem.subgenre.length > 0) {
      for (const genre of genreMenuItem.subgenre) {
        if (genre.menu && genre.menu?.length > 0) {
          if (menuData && tenpoInfo) {
            const currentTimeFrame = getTimeFrameId(
              SessionStorage.getItem(SESSION_CURRENT_TIME) ?? '',
              tenpoInfo.time_frame
            );
            for (const menu of genre.menu) {
              if (
                (menuData[menu.poscd]?.dispcond === '3' && timeFrameLunch?.includes(currentTimeFrame.time_frame_id)) ||
                (menuData[menu.poscd]?.dispcond === '4' && !timeFrameLunch?.includes(currentTimeFrame.time_frame_id))
              ) {
                totalSubMenu += 1;
              } else if (menuData[menu.poscd]?.dispcond != '3' && menuData[menu.poscd]?.dispcond != '4') {
                totalSubMenu += 1;
              }
            }
          } else {
            totalSubMenu += genre.menu.length;
          }
        }
      }
    }
  }
  return totalSubMenu > 0;
}
