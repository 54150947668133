import { useTranslation } from 'react-i18next';
import { AraLogoIcon } from 'theme/icons';
import './ThankPage.scss';

const ThankPage = () => {
  const { t } = useTranslation();

  return (
    <div className="thanks-page">
      <div className="logo">
        <AraLogoIcon width={220} height={70} color={'#fff'} />
        <div className="content">
          <strong>{t('ThankPage.thank_you_very_much')}</strong>
          <br />
          <strong>{t('ThankPage.we_look_forward_to_seeing_you_again')}</strong>
        </div>
      </div>
    </div>
  );
};

export default ThankPage;
