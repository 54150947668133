import { IMenuItemInfo } from 'apis/types';
import { DishDispatchContext } from 'components/DishModal';
import QuantityButtonGroup from 'components/QuantityButtonGroup';
import { BasicPortion, DishMenuGroupDetailValue } from 'models';
import { ChangeEvent, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { playSound } from 'sound/Sound';
import { attrLang, convertPriceInTaxToNoTax } from 'utils';
import { REQUIRED_SELECT_SUBMENUGROUP } from '../../../../constants';
import { OptionChildMenuGroup } from '../OptionChildMenuGroup';

interface OptionChildMenuItemProps {
  dishCode: string;
  orderIdx: number;
  groupCode: string;
  groupInfo: BasicPortion;
  optionInfo: IMenuItemInfo;
  itemState: DishMenuGroupDetailValue;
  isChild: boolean;
  parentCode: string;
  parentQuantity: number;
  childGroupCodeAndIndex: string;
}

const OptionChildMenuItem = ({
  dishCode,
  orderIdx,
  groupCode,
  groupInfo,
  optionInfo,
  itemState,
  isChild,
  parentCode,
  parentQuantity,
  childGroupCodeAndIndex,
}: OptionChildMenuItemProps) => {
  const { t } = useTranslation();
  const dishDishpatch = useContext(DishDispatchContext);

  const optionCode = optionInfo.poscd;

  const type = groupInfo.selection_condition === REQUIRED_SELECT_SUBMENUGROUP ? 'radio' : 'checkbox';
  const checked = itemState.checked;
  const optionName = attrLang(optionInfo, 'lang');
  const optionPrice = parseInt(optionInfo.price);
  const freeClass = optionPrice === 0 ? 'free' : '';
  const childQuantity = itemState.quantity / parentQuantity;

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    playSound('sub');
    if (isChild) {
      if (parentCode && childGroupCodeAndIndex) {
        dishDishpatch({
          type: 'check',
          payload: {
            orderIdx,
            groupCode,
            optionCode,
            checked: event.target.checked,
            isChild,
            parentCode,
            childGroupCodeAndIndex,
          },
        });
      }
    }
  };

  const handleQuantityChange = (actionType: 'increase' | 'decrease') => {
    if (isChild) {
      if (parentCode && childGroupCodeAndIndex) {
        dishDishpatch({
          type: 'change-quantity',
          payload: {
            orderIdx,
            groupCode,
            optionCode,
            actionType,
            isChild,
            parentCode,
            childGroupCodeAndIndex,
            parentQuantity,
          },
        });
      }
    }
  };

  const ListOfGroups: (JSX.Element | null)[] = [];

  if (itemState.checked && itemState && itemState.childInfor) {
    const childInfor = itemState.childInfor;
    Object.entries(childInfor).forEach(([childGroupCodeAndIndex, childGroupState]) => {
      if (childGroupState.basic.noselectflg === '0') {
        ListOfGroups.push(
          <OptionChildMenuGroup
            key={`${groupCode}-${childGroupCodeAndIndex}`}
            groupCode={groupCode}
            orderIdx={orderIdx}
            dishCode={dishCode}
            groupState={childGroupState}
            isChild={true}
            parentCode={parentCode}
            parentQuantity={parentQuantity}
            childGroupCodeAndIndex={childGroupCodeAndIndex}
          />
        );
      }
    });
  }

  return (
    <li className="child-menu-option mb-1">
      <div className="d-flex align-items-start justify-content-between">
        <div className={Number(groupInfo.kousenum) <= 1 && freeClass === 'free' ? 'w-100' : 'w-50'}>
          <label className={`ara-${type}`}>
            <span className={`ara-${type}-text`}>{optionName}</span>
            <input
              id={`option-${dishCode}-${orderIdx}-${groupCode}-${optionCode}`}
              type={type}
              value={optionCode}
              onChange={handleCheck}
              checked={checked}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div
          className="w-50"
          style={{ display: `${Number(groupInfo.kousenum) <= 1 && freeClass === 'free' ? 'none' : 'block'}` }}
        >
          {Number(groupInfo.kousenum) > 1 && checked && (
            <div className="text-end mb-2">
              <QuantityButtonGroup
                onDecrease={() => handleQuantityChange('decrease')}
                onIncrease={() => handleQuantityChange('increase')}
                quantity={childQuantity}
                min={1}
                disableMin
              />
            </div>
          )}
          <div
            className={`option-price ${freeClass} ${optionPrice < 0 ? 'text-danger' : ''} ${
              Number(groupInfo.kousenum) > 1 && checked ? 'kousenum' : ''
            }`}
            style={{ display: `${freeClass === 'free' ? 'none' : 'block'}` }}
          >
            <span className="tax-excluded">
              {optionPrice > 0 && !freeClass && <span>+</span>}
              {t('OrderHistory.currency')}
              <NumberFormat
                value={convertPriceInTaxToNoTax(optionPrice)}
                displayType={'text'}
                thousandSeparator={true}
              />
            </span>
            <span className="tax-included">
              ({t('OrderHistory.tax_included')}&nbsp;
              {t('OrderHistory.currency')}
              <NumberFormat value={optionPrice} displayType={'text'} thousandSeparator={true} />)
            </span>
          </div>
        </div>
      </div>
      {ListOfGroups}
    </li>
  );
};

export default memo(OptionChildMenuItem);
