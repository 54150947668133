import { IMenuItemInfo } from 'apis/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AlertModal from 'components/AlertModal';
import ConfirmModal from 'components/ConfirmModal';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import QuantityButtonGroup from 'components/QuantityButtonGroup';
import { PortionState } from 'models';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { cartActions, selectCartState } from 'store/slices/cartSlice';
import { selectCustomerQuantity } from 'store/slices/customerQuantitySlice';
import { selectCouponInfo, selectMenu, selectOrderHistoryData } from 'store/slices/mainSlice';
import { selectMenuBook } from 'store/slices/menuInfoSlice';
import {
  attrLang,
  checkIsExceedAllowQuantityOrder,
  countCouponUsed,
  imgFullPath,
  isDishInCouponListFullCode,
  stripHtml,
} from 'utils';
import { MAX_COUPON_PER_PERSON_PER_ITEM } from '../../../../constants';

interface DishQuantitySelectorProps {
  dishInfo: IMenuItemInfo;
  dishKey: string;
  addedState: PortionState;
  quantity: number;
}

const DishQuantitySelector = ({ dishInfo, dishKey, addedState, quantity }: DishQuantitySelectorProps) => {
  const { t } = useTranslation();
  const { showGlobalModal } = useGlobalModal();

  const dispatch = useAppDispatch();

  const cartState = useAppSelector(selectCartState);
  const orderHistoryData = useAppSelector(selectOrderHistoryData);
  const menuList = useAppSelector(selectMenu);
  const customerQuantity = useAppSelector(selectCustomerQuantity);
  const menubookList = useAppSelector(selectMenuBook);
  const couponInfoList = useAppSelector(selectCouponInfo);

  const [menubookId] = JSON.parse(dishKey);

  const dishName = stripHtml(attrLang(dishInfo, 'lang'));
  const dishImgSrc = imgFullPath(dishInfo?.img);

  const handleAgree = () => {
    dispatch(cartActions.decrease({ dishKey, dishState: addedState }));
  };

  const handleDecrease = () => {
    if (quantity === 1) {
      const html = `
        <div class="mb-3 w-100">
          <div class="mb-3">
            <div class="d-flex">
              <div class="col-5"><img src="${dishImgSrc}" /></div>
              <div class="col-7 ps-2 text-start">${dishName}</div>
            </div>
          </div>
          <div>${t('App.confirm_delete_item')}</div>
        </div>`;

      showGlobalModal(() => <ConfirmModal message={html} onAgree={handleAgree} confirmSound="delete" />);
    } else {
      dispatch(cartActions.decrease({ dishKey, dishState: addedState }));
    }
  };

  const showAlertModal = (message: string) => {
    playSound('error');
    showGlobalModal(() => <AlertModal message={message} />);
  };

  const handleIncrease = () => {
    const isCheckExceedAllowQuantityOrder = checkIsExceedAllowQuantityOrder(
      menubookId,
      menubookList,
      cartState,
      1,
      customerQuantity
    );

    if (isCheckExceedAllowQuantityOrder) {
      showAlertModal(t('MenuItemDetail.exceed_quantity_warning'));

      return;
    }

    if (isDishInCouponListFullCode(dishInfo, couponInfoList)) {
      const maxCouponQuantity = customerQuantity * MAX_COUPON_PER_PERSON_PER_ITEM;
      const couponUsed = countCouponUsed(menuList, couponInfoList, cartState, orderHistoryData);

      if (couponUsed + 1 > maxCouponQuantity) {
        showAlertModal(t('App.max_coupon_num_exceeded'));

        return;
      }
    }

    dispatch(cartActions.increase({ dishKey, dishState: addedState }));
  };

  return <QuantityButtonGroup onDecrease={handleDecrease} onIncrease={handleIncrease} quantity={quantity} />;
};

export default DishQuantitySelector;
