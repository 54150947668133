import { imgDB } from 'apis/Api';
import { NullableLocalized } from 'apis/types';
import i18n from 'i18next';
import { DEFAULT_LANGUAGE } from '../constants';

type LanguageKeys = keyof NullableLocalized;

/**
 * Function to get the full path of an image from the img object.
 * @param img - An object containing image paths for different languages.
 * @returns A string containing the full path of the image, or an empty string if the image object is undefined.
 */
export const imgFullPath = (img: NullableLocalized | undefined): string => {
  if (!img) return '';

  const language = i18n.language as LanguageKeys;
  const path = img[language] ?? img[DEFAULT_LANGUAGE];

  return path ? `${imgDB}${path}` : '';
};
