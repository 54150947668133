import { TAP_ANIMATION_TIME } from '../../../constants';

import { GenreInfoDataType } from 'apis/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { playSound } from 'sound/Sound';
import { selectAllYouCanEatState } from 'store/slices/allYouCanEatSlice';
import { selectMenu, selectTenpoInfo, selectTimeFrameLunch } from 'store/slices/mainSlice';
import { menuInfoActions, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { Swiper as SwiperCore } from 'swiper';
import { checkAllYouCanEatMenuOver, checkGenreAvailable, getMenuAllYouCanEat, imgFullPath } from 'utils';
import { attrLang } from 'utils/attrLang';

export interface CategoryThumbnailProps {
  mainSwiper?: SwiperCore;
  slideIndex: number;
  menubookId: string;
  genreId: string;
  genreInfo?: GenreInfoDataType;
}

function GenreThumbnail({ mainSwiper, slideIndex, menubookId, genreId, genreInfo }: CategoryThumbnailProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { i18n } = useTranslation();
  const appDispatch = useAppDispatch();
  const menuInfo = useAppSelector(selectMenuInfo);
  const tenpoInfo = useAppSelector(selectTenpoInfo);
  const menuData = useAppSelector(selectMenu);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const timeFrameLunch = useAppSelector(selectTimeFrameLunch);
  const currentMenubook = menuInfo.menubook.find((item) => item.menubook_id === menubookId);
  const name = attrLang(genreInfo, 'lang');
  const imgSrc = imgFullPath(genreInfo?.img);
  let genreData;
  let isDisabled = false;
  let isShow = true;

  if (currentMenubook) {
    genreData =
      currentMenubook?.course && currentMenubook.course !== null
        ? getMenuAllYouCanEat(currentMenubook)
        : currentMenubook.genre;
    genreData = genreData?.find((item) => item.genre_id === genreId);
    isDisabled =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentMenubook.course && genreData && checkAllYouCanEatMenuOver(genreData, allYouCanEatState, menuData)
        ? true
        : false;
    isShow = menuInfo && checkGenreAvailable(currentMenubook, genreId, menuData, tenpoInfo, timeFrameLunch);
  }

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!isDisabled && !(e.currentTarget as HTMLButtonElement).disabled) {
        (e.currentTarget as HTMLButtonElement).disabled = true;
        setTimeout(() => {
          // log({
          //   op_type: LOG_OP_TYPE_CATEGORY_SWITCHING,
          //   op_detail: {
          //     mode: modeCode,
          //     category: categoryCode,
          //   },
          // });
          playSound('page');
          mainSwiper && mainSwiper.slideTo(slideIndex);

          // save categoryCode to SessionStorage and main store
          appDispatch(menuInfoActions.setCurrentGenreId({ selectedMenubookId: menubookId, genreId: genreId }));
        }, TAP_ANIMATION_TIME / 2);
      }
    },
    [mainSwiper]
  );

  return isShow ? (
    <Thumbnail
      currentMenuBook={currentMenubook}
      thumbType="category"
      categorySlideIndex={slideIndex}
      name={name}
      imgSrc={imgSrc}
      handleClick={handleClick}
      disable={isDisabled}
      lazy={true}
    />
  ) : null;
}

export default React.memo(GenreThumbnail);
