import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SESSION_LANGUAGE_CODE, SessionStorage } from 'utils/sessionStorage';
import { DEFAULT_LANGUAGE } from '../constants';
import { translationCN, translationJP, translationKR, translationTW, translationUS, translationVN } from './locales';

const resources = {
  us: { translation: translationUS },
  jp: { translation: translationJP },
  cn: { translation: translationCN },
  tw: { translation: translationTW },
  kr: { translation: translationKR },
  vn: { translation: translationVN },
};

const langSession = SessionStorage.getItem(SESSION_LANGUAGE_CODE) ?? DEFAULT_LANGUAGE;

i18n.use(initReactI18next).init({
  debug: false,
  lng: langSession,
  fallbackLng: 'us',
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;
