/* eslint-disable */
import { IMenuItemInfo, MenuBookGroupInfoType } from 'apis/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Thumbnail from 'components/Thumbnail/Thumbnail';

import { getServerTime } from 'apis/Api';
import AlertModal from 'components/AlertModal';
import useGlobalModal from 'components/GlobalModal/useGlobalModal';
import { DishModalContext } from 'context/DishModalContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { playSound } from 'sound/Sound';
import { selectAllYouCanEatState, selectThMenuInfo } from 'store/slices/allYouCanEatSlice';
import { selectCartState } from 'store/slices/cartSlice';
import { selectCustomerQuantity } from 'store/slices/customerQuantitySlice';
import {
  selectCouponInfo,
  selectDispcondGroup,
  selectDispcondMenu,
  selectHaveAdult,
  selectHistoryFlag,
  selectMenu,
  selectOrderHistoryData,
  selectTenpoInfo,
  selectTimeFrameLunch,
} from 'store/slices/mainSlice';
import { menuInfoActions, selectMenuInfo } from 'store/slices/menuInfoSlice';
import { selectTime, timeActions } from 'store/slices/timeSlice';
import {
  countCouponUsed,
  getErrorMessage,
  getFirstGenreIdByMenubookId,
  getInitMenubookId,
  getTimeFrameId,
  imgFullPath,
  SESSION_CURRENT_TIME,
  SESSION_MENUBOOK_BUTTON_CLICKED,
  SESSION_NOTIFY_AGE_LIMIT,
} from 'utils';
import { attrLang } from 'utils/attrLang';
import './scss/MenuItemThumbnail.scss';

export interface MenuItemThumbnailProps {
  itemInfo: IMenuItemInfo;
  menubookId: string;
  genreId: string;
  messageOverlay: string;
  outOfStocked: boolean;
  currentMenuBook: MenuBookGroupInfoType;
}

function MenuItemThumbnail({
  itemInfo,
  menubookId,
  messageOverlay,
  outOfStocked,
  currentMenuBook,
}: MenuItemThumbnailProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showGlobalModal } = useGlobalModal();
  const { showDishModal } = useContext(DishModalContext);

  // selector
  const customerQuantity = useAppSelector(selectCustomerQuantity);
  const cartState = useAppSelector(selectCartState);
  const menuData = useAppSelector(selectMenu);
  const orderHistory = useAppSelector(selectOrderHistoryData);
  const haveAdult = useAppSelector(selectHaveAdult);
  const historyFlag = useAppSelector(selectHistoryFlag);
  const timeServer = useAppSelector(selectTime);
  const tenpoInfo = useAppSelector(selectTenpoInfo);
  const timeFrame = getTimeFrameId(
    sessionStorage.getItem(SESSION_CURRENT_TIME) ?? timeServer.tm_hh_mm,
    tenpoInfo?.time_frame
  );
  const thMenuInfo = useAppSelector(selectThMenuInfo);
  const allYouCanEatState = useAppSelector(selectAllYouCanEatState);
  const listCoupon = useAppSelector(selectCouponInfo);
  const menuInfo = useAppSelector(selectMenuInfo);
  const dispcondMenu = useAppSelector(selectDispcondMenu);
  const dispcondGroup = useAppSelector(selectDispcondGroup);
  const timeFrameLunch = useAppSelector(selectTimeFrameLunch);
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [messageOverlayText, setMessageOverlayText] = useState(messageOverlay);
  // compose data
  const itemName = attrLang(itemInfo, 'lang');
  const price = parseInt(itemInfo.price);
  const imgSrc = imgFullPath(itemInfo.img);
  const addedClass = messageOverlay !== '' ? 'call-staff' : '';

  useEffect(() => {
    setMessageOverlayText(messageOverlay);
  }, [messageOverlay]);

  async function handleClick() {
    if (messageOverlay !== '') return;

    // check exceed coupon
    if (itemInfo.coupon_flg === '1') {
      const couponUsed = countCouponUsed(menuData, listCoupon, cartState, orderHistory);
      const maxCouponQuantity = customerQuantity * 2;
      if (couponUsed >= maxCouponQuantity) {
        playSound('confirm');
        showGlobalModal(() => <AlertModal message={t('App.max_coupon_num_exceeded')} />);
      }
    }
    let currentTimeFrame = timeFrame.property;

    if (timeFrameLunch.includes(timeFrame.time_frame_id)) {
      // only check timeframe when LUNCH TIME
      setIsLoading(true);
      const timeRes = await getServerTime();
      setIsLoading(false);
      dispatch(timeActions.fetchTimeDataSuccess(timeRes));
      const newTimeFrame = getTimeFrameId(
        sessionStorage.getItem(SESSION_CURRENT_TIME) ?? timeRes.data.tm_hh_mm,
        tenpoInfo?.time_frame
      );
      currentTimeFrame = newTimeFrame.property;
    }

    // check menu in timeframe lunch
    if (timeFrameLunch.includes(timeFrame.time_frame_id)) {
      if (currentTimeFrame === '') {
        try {
          // Change to mode GM
          // save modeCode and categoryCode to SessionStorage and main store
          sessionStorage.setItem(SESSION_MENUBOOK_BUTTON_CLICKED, 'true');
          const menubookAvailable = getInitMenubookId(menuInfo.menubook, allYouCanEatState);
          const initMenubook = menuInfo.menubook.find((item) => item.menubook_id === menubookAvailable);
          if (initMenubook) {
            playSound('confirm');
            showGlobalModal(() => <AlertModal message={t('App.lunch_menu_unavailable')} />);
            const firstGenreId = getFirstGenreIdByMenubookId(
              menuInfo.menubook.find((item) => item.menubook_id === menubookAvailable)!,
              menuData,
              allYouCanEatState,
              tenpoInfo,
              timeFrameLunch
            );
            dispatch(
              menuInfoActions.setCurrentMenubookId({
                menubookId: menubookAvailable,
                genreId: firstGenreId,
              })
            );
          }
        } catch (error) {
          playSound('error');
          navigate('/mode');
        }

        // log switch mode
        // log({
        //   op_type: LOG_OP_TYPE_MODE_SWITCHING,
        //   op_detail: {
        //     mode: GRAND_MENU_MODEID,
        //   },
        // });
        return;
      }
    }
    // check menu agelimit_flg=1
    if (itemInfo.agelimit_flg === '1') {
      if (sessionStorage.getItem(SESSION_NOTIFY_AGE_LIMIT) === null) {
        playSound('confirm');
        showGlobalModal(() => <AlertModal message={t('App.warning_age_limit')} onClose={openMenuDetail} />);

        sessionStorage.setItem(SESSION_NOTIFY_AGE_LIMIT, 'true');
        return;
      }
    }

    // recheck and update messageOverlay
    const updatedMessageOverlay = getErrorMessage(
      thMenuInfo.code,
      timeFrameLunch.includes(timeFrame.time_frame_id),
      historyFlag,
      allYouCanEatState,
      itemInfo,
      currentMenuBook,
      outOfStocked,
      parseInt(haveAdult),
      dispcondMenu,
      dispcondGroup,
      orderHistory.OrderDetail
    );
    if (updatedMessageOverlay !== '') {
      setMessageOverlayText(updatedMessageOverlay);
      return;
    }

    playSound('page');
    openMenuDetail();
  }

  const openMenuDetail = () => {
    showDishModal && showDishModal(itemInfo.poscd);
  };

  return (
    <>
      <div className={`menu-detail-thumbnail ${addedClass}`}>
        <Thumbnail
          currentMenuBook={currentMenuBook}
          name={itemName}
          imgSrc={imgSrc}
          handleClick={!isLoading ? handleClick : undefined}
          price={price}
          imageHeight={'26vw'}
          // lazy={![CATEGORY_FREQUENTLY_ORDERED_MENU_ID, CATEGORY_SEARCH_MENU_ID].includes(categoryCode)}
          lazy={true}
        />

        {isLoading && (
          <div className="message">
            <div className="message-content">
              <div className="spinner-border menu-detail-thumbnail-loading" role="status">
                <span className="visually-hidden">checking...</span>
              </div>
            </div>
          </div>
        )}

        {/* {isMember && (
          <div className={`fav-icon-wrapper ${favoredClass}`} onClick={handleFavor}>
            <i className="bi bi-suit-heart"></i>
          </div>
        )} */}

        {messageOverlay !== '' && (
          <div className="message">
            <div className="message-content" dangerouslySetInnerHTML={{ __html: messageOverlayText ?? '' }}></div>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(MenuItemThumbnail);
