import { ClientInfoType, IOrderDetailMenuInfo } from 'apis/types';
import { useAppSelector } from 'app/hooks';
import DishPrice from 'components/DishPrice';
import DishOptionList from 'pages/OrderHistoryPage/components/DishOptionList';
import DishStatus from 'pages/OrderHistoryPage/components/DishStatus';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { selectMenu } from 'store/slices/mainSlice';
import { attrLang, imgFullPath, stripHtml } from 'utils';

interface HistoryMenuItemProps {
  orderType: ClientInfoType;
  dishOrderState: IOrderDetailMenuInfo;
  orderCost: number;
}

const HistoryMenuItem = ({ orderType, dishOrderState, orderCost }: HistoryMenuItemProps) => {
  const { t } = useTranslation();

  const menuList = useAppSelector(selectMenu);
  const dishCode = dishOrderState.MenuCode;
  const dishInfo = menuList[dishCode];
  const dishQuantity = parseInt(dishOrderState.CountInfo.Count);
  const dishImgPath = imgFullPath(dishInfo?.img);
  const dishName = stripHtml(attrLang(dishInfo, 'lang'));

  return (
    <div className="card dish-card">
      <div className="d-none">{orderType}</div>
      <div className="row g-0">
        <div className="col-6">
          <div className="img-cover">
            <LazyLoadImage
              wrapperClassName="d-block"
              className="img-fluid rounded-start"
              alt="..."
              effect="opacity"
              src={dishImgPath}
            />
          </div>
        </div>
        <div className="col-6 d-flex flex-column justify-content-between">
          <div className="card-content">
            <DishStatus dishOrderState={dishOrderState} />
            <h3>{dishName}</h3>
            <DishOptionList dishOrderState={dishOrderState} />
            <DishPrice price={orderCost} />
          </div>
          <div className="card-quantity d-flex">
            <div className="align-self-center text-quantity">{t('Cart.quantity')}</div>
            <div className="align-self-center value-quantity">{dishQuantity}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryMenuItem;
