import { CartInfoGroupType, CartInfoMenuType, CartInfoType } from 'apis/types';
import { DishMenuGroupDetailValue, PortionState, PortionStateItem } from 'models';

/**
 * Converts the given portion state and quantity into a CartInfoType object.
 * @param dishKey - A string containing the menu book ID and dish code separated by a hyphen.
 * @param portionState - An object containing the portion state for each group.
 * @param portionQuantity - The total quantity of the dish.
 * @returns A CartInfoType object containing the portion group info.
 */
export const convertPortionStateToPortionInfo = (
  dishKey: string,
  portionState: PortionState,
  portionQuantity: number
): CartInfoType => {
  const [menuBookId, dishCode] = JSON.parse(dishKey);

  const portionGroupInfo = createPortionGroupInfo(menuBookId, dishCode, portionQuantity, portionState);

  return portionGroupInfo;
};

const createPortionGroupInfo = (menuBookId: string, dishCode: string, quantity: number, portionState: PortionState) => {
  const selectInfo = Object.entries(portionState).map(([groupCodeVersion, groupState]) =>
    createGroupInfo(groupCodeVersion, groupState, quantity)
  );

  return {
    modecd: menuBookId,
    poscd: dishCode,
    num: quantity.toString(),
    provision_timing_settingflg: '0',
    select: selectInfo.length ? [{ group: selectInfo }] : [],
  };
};

const createGroupInfo = (groupCodeVersion: string, groupState: PortionStateItem, quantity: number) => {
  const [groupCode] = groupCodeVersion.split('-');
  const groupMenu = Object.entries(groupState.detail)
    .map(([optionCode, optionState]) => createOptionInfo(optionCode, optionState, quantity))
    .filter((optionInfo) => optionInfo !== null);

  const childGroups = createChildGroups(groupState, quantity);

  return {
    gno: groupCode.toString(),
    menu: groupMenu as CartInfoMenuType[],
    ...(childGroups.length > 0 ? { group: childGroups } : {}),
  };
};

const createOptionInfo = (optionCode: string, optionState: DishMenuGroupDetailValue, quantity: number) => {
  if (!optionState.checked) return null;

  return {
    poscd: optionCode,
    provision_timing_settingflg: '0',
    num: (optionState.quantity * quantity).toString(),
  };
};

const createChildGroups = (groupState: PortionStateItem, quantity: number): CartInfoGroupType[] => {
  const childGroupInfo: CartInfoGroupType[] = [];

  Object.values(groupState.detail).forEach((optionState) => {
    if (optionState.childInfor) {
      Object.values(optionState.childInfor).forEach((childItem) => {
        const childGroupMenu: CartInfoMenuType[] = [];

        Object.entries(childItem.detail).forEach(([childOptionCode, childOptionState]) => {
          if (!childOptionState.checked) return;

          const childOptionInfo: CartInfoMenuType = {
            poscd: childOptionCode,
            provision_timing_settingflg: '0',
            num: (childOptionState.quantity * quantity).toString(),
          };

          childGroupMenu.push(childOptionInfo);
        });

        if (childGroupMenu.length > 0) {
          const childGroup: CartInfoGroupType = {
            gno: childItem.basic.cd.toString(),
            menu: childGroupMenu,
          };

          childGroupInfo.push(childGroup);
        }
      });
    }
  });

  return childGroupInfo;
};
