import { useAppSelector } from 'app/hooks';
import Layout from 'components/Layout';
import ProtectedPage from 'components/ProtectedPage';
import CartPage from 'pages/CartPage';
import MainPage from 'pages/MainPage';
import OrderHistoryPage from 'pages/OrderHistoryPage';
import ThankPage from 'pages/ThankPage';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { selectIsShowRemainAllYouCanEat } from 'store/slices/allYouCanEatSlice';
import './AnimatedRoutes.scss';

const AnimatedRoutes = () => {
  const location = useLocation();
  const isShowRemainAllYouCanEat = useAppSelector(selectIsShowRemainAllYouCanEat);

  const handleExit = (node: HTMLElement) => {
    node.style.position = 'fixed';
    node.style.overflow = 'hidden';
    node.style.top = `${-1 * window.scrollY}px`;
  };

  const routes = [
    {
      path: '/cart',
      element: (
        <ProtectedPage>
          <Layout>
            <CartPage />
          </Layout>
        </ProtectedPage>
      ),
      className: 'cart',
    },
    {
      path: '/order-history',
      element: (
        <ProtectedPage>
          <Layout>
            <OrderHistoryPage />
          </Layout>
        </ProtectedPage>
      ),
      className: 'history',
    },
    {
      path: '/thanks',
      element: <ThankPage />,
      className: '',
    },
    {
      path: '/',
      element: (
        <ProtectedPage>
          <Layout>
            <MainPage />
          </Layout>
        </ProtectedPage>
      ),
      className: isShowRemainAllYouCanEat ? 'all-you-can-eat' : '',
    },
  ];

  return (
    <TransitionGroup component={null} enter={location.pathname !== '/'}>
      <CSSTransition
        key={location.key}
        classNames={location.pathname !== '/' ? 'page-effect' : 'page-home-effect'}
        timeout={600}
        appear
        unmountOnExit
        onExit={handleExit}
      >
        <Routes location={location}>
          {routes.map(({ path, element, className }) => (
            <Route key={path} path={path} element={<div className={`page-wrapper ${className}`}>{element}</div>} />
          ))}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default AnimatedRoutes;
