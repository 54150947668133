import AlertModal from 'components/AlertModal';
import { playSound } from 'sound/Sound';
import i18n from 'translation/i18n';
import {
  RES_CODE_CREDENTIAL_ERROR,
  RES_CODE_DATA_READ_ERROR,
  RES_CODE_DATA_WRITE_ERROR,
  RES_CODE_DB_CONNECTION_ERROR,
  RES_CODE_PARAMETER_ERROR,
  RES_CODE_SERVER_CONNECTION_ERROR,
  RES_CODE_UNAUTHORIZED,
} from '../constants';

export const handleErrorResponse = (
  showGlobalModal: (componentFactory: () => React.ReactElement) => void,
  response: {
    msg: string;
    code: string;
    [key: string]: any;
  },
  defaultMessage?: string,
  onClose?: () => void
) => {
  if (response.code === RES_CODE_UNAUTHORIZED) {
    return;
  }

  const { t } = i18n;

  const errorMessage = t('MessageList.credential_error', { error_code: response.code });

  playSound('error');

  const errorCode = response.code.slice(2, 4);

  switch (errorCode) {
    case RES_CODE_CREDENTIAL_ERROR:
    case RES_CODE_PARAMETER_ERROR:
    case RES_CODE_DB_CONNECTION_ERROR:
    case RES_CODE_DATA_READ_ERROR:
    case RES_CODE_DATA_WRITE_ERROR:
    case RES_CODE_SERVER_CONNECTION_ERROR:
      showGlobalModal(() => <AlertModal message={errorMessage} onClose={onClose} />);
      break;
    default:
      showGlobalModal(() => <AlertModal message={defaultMessage || errorMessage} onClose={onClose} />);
      break;
  }
};
