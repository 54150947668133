import { useAppSelector } from 'app/hooks';
import AllYouCanEatBoard from 'components/AllYouCanEatBoard/AllYouCanEatBoard';
import CouponIcon from 'components/Header/components/CouponIcon';
import LanguageBox from 'components/Header/components/LanguageBox';
import { selectIsShowRemainAllYouCanEat } from 'store/slices/allYouCanEatSlice';
import { AraLogoIcon } from 'theme/icons';
import './Header.scss';

const Header = () => {
  const isShowRemainAllYouCanEat = useAppSelector(selectIsShowRemainAllYouCanEat);

  return (
    <header className={`header-wrapper ${isShowRemainAllYouCanEat ? 'all-you-can-eat' : ''}`}>
      <div id="top-section">
        <div className="header">
          <div className="d-flex align-items-center justify-content-between">
            <div className="col">
              <LanguageBox />
            </div>
            <div className="col text-center">
              <AraLogoIcon />
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              <CouponIcon />
            </div>
          </div>
        </div>
      </div>
      {isShowRemainAllYouCanEat && <AllYouCanEatBoard />}
    </header>
  );
};

export default Header;
