import { ICompactOrderStateValue } from 'models';
import { deepEqualObject } from './deepEqualObject';

export const mergeCompactStateEntries = (compactStateEntries: [string, ICompactOrderStateValue][]) => {
  const mergedEntries = new Map<string, ICompactOrderStateValue>();

  compactStateEntries.forEach(([groupCodeVersion_optionCode, optionValue]) => {
    const groupCodeVersion = groupCodeVersion_optionCode.split('_')[0];
    const groupCode = groupCodeVersion.split('-')[0];

    const existingGroupCode = Array.from(mergedEntries.keys()).find((key) => key.startsWith(groupCode));

    if (existingGroupCode) {
      const existingGroupValue = mergedEntries.get(existingGroupCode);

      if (existingGroupValue && deepEqualObject(existingGroupValue, optionValue)) {
        existingGroupValue.quantity += optionValue.quantity;
      } else {
        mergedEntries.set(groupCodeVersion, {
          ...optionValue,
        });
      }
    } else {
      mergedEntries.set(groupCodeVersion, {
        ...optionValue,
      });
    }
  });

  return mergedEntries;
};
